import React, { useMemo, useState, useRef, useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
  Autocomplete,
  useLoadScript,
} from "@react-google-maps/api";
import { useLocation } from "react-router-dom";
import { setKey, geocode, RequestType, fromAddress } from "react-geocode";
import "../EventDetails/EventDetails.css";
import ButtonPrimary from "../../Common/Buttons/ButtonPrimary/ButtonPrimary";
import TooltipPopup from "../../../Component/Common/TooltipPopup/TooltipPopup";
import { ReactComponent as ErrorFilled } from "../../../assets/svgs/ErrorFilled.svg";
import { ReactComponent as CloseFilledWhite } from "../../../assets/svgs/CloseFilledWhite.svg";
import Text from "../../Common/Text";
import "../../../index.js";
import { GOOGLE_API_KEY } from "../../../constants/env";

export default function VenueMap({
  shouldShowSubmitButton,
  toHaveAccessibleErrorMessage,
  onSaveClicked,
  onDiscardClicked,
  currentLatLng,
  onCurrentLatLngChange,
  onLocationDetailsChange = () => {},
  searchAddress = "",
  setSearchAddress = () => {},
  city,
}) {
  const { pathname } = useLocation();
  // const [searchAddress, setSearchAddress] = useState(venueSearchAddress);
  const [searchResult, setSearchResult] = useState("");
  const [formattedAddress, setFormattedAddress] = useState("");
  const [currentPosition, setCurrentPosition] = useState(currentLatLng);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: ["places"],
  });

  const getCurrentPosition = async (city) => {
    fromAddress(city)
      .then(({ results }) => {
        const { lat, lng } = results[0].geometry.location;
        setCurrentPosition({ lat, lng });
        onCurrentLatLngChange({
          lat: lat,
          lng: lng,
        });
        geocode(RequestType.LATLNG, `${lat}` + "," + `${lng}`)
          .then(({ results }) => {
            const address = results[0].formatted_address;
            setFormattedAddress(address);
            setSearchAddress(address);
          })
          .catch(console.error);
      })
      .catch(console.error);
  };

  useEffect(() => {
    setCurrentPosition(currentLatLng);
  }, [currentLatLng]);

  useEffect(() => {
    city && getCurrentPosition(city);
  }, [city]);

  useEffect(() => {
    console.log("pathname venue map", pathname);
    setKey(GOOGLE_API_KEY);
    geocode(
      RequestType.LATLNG,
      `${currentPosition?.lat}` + "," + `${currentPosition?.lng}`
    )
      .then(({ results }) => {
        const address = results[0].formatted_address;
        setFormattedAddress(address);
        setSearchAddress(address);
      })
      .catch(console.error);
  }, []);

  function onLoad(autocomplete) {
    setSearchResult(autocomplete);
  }

  function onPlaceChanged() {
    if (searchResult != null) {
      console.log("searchResult", searchResult.getPlace());

      setSearchAddress(searchResult.getPlace().formatted_address);
      const place = searchResult.getPlace();
      const name = place.name;
      const formattedAddress = place.formatted_address;
      setFormattedAddress(formattedAddress);
      fetchLocationDetails(place);
      setCurrentPosition({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
      onCurrentLatLngChange({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      });
    } else {
    }
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const fetchLocationDetails = (place) => {
    let address1 = "";
    let city = "";
    let country = "";
    let postalCode = "";

    place.address_components.forEach((component) => {
      const types = component.types;

      if (types.includes("street_number")) {
        address1 = `${component.long_name} ${address1}`;
      }

      if (types.includes("route")) {
        address1 += component.long_name;
      }

      if (types.includes("locality")) {
        city = component.long_name;
      }

      if (types.includes("country")) {
        country = component.long_name;
      }

      if (types.includes("postal_code")) {
        postalCode = component.long_name;
      }
    });

    onLocationDetailsChange({
      address1: address1,
      city: city,
      country: country,
      postalCode: postalCode,
    });

    console.log("address1", address1);
    console.log("city", city);
    console.log("country", country);
    console.log("postalCode", postalCode);
  };

  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setCurrentPosition({ lat, lng });
    onCurrentLatLngChange({
      lat: lat,
      lng: lng,
    });

    geocode(RequestType.LATLNG, `${lat}` + "," + `${lng}`)
      .then(({ results }) => {
        const address = results[0].formatted_address;
        fetchLocationDetails(results[0]);
        setFormattedAddress(address);
        setSearchAddress(address);
      })
      .catch(console.error);
  };

  return (
    <div className="event-about-div">
      <div className="generic-common-row-div">
        <Text variant={"m500"}>Map</Text>
        <span className="MapInfoIconCont">
          <TooltipPopup />
        </span>
      </div>

      <div
        className="AddressWithMapCont"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          className="AddressLatLongCont"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 40,
            flex: 1 / 2,
            paddingRight: 32,
          }}
        >
          <Text variant="m300" marginBottom={8}>
            Address*
          </Text>

          <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoad}>
            <div style={{ position: "relative" }}>
              <input
                value={searchAddress}
                onChange={(e) => {
                  setSearchAddress(e.target.value);
                }}
                type="text"
                placeholder="Search for full address"
                style={{
                  boxSizing: `border-box`,
                  width: `100%`,
                  height: `48px`,
                  paddingRight: `45px`,
                  paddingLeft: `12px`,
                  borderRadius: `4px`,
                  fontSize: `14px`,
                  outline: `none`,
                  textOverflow: `ellipses`,
                  border: "1px solid #EBEBEB",
                }}
              />
              {searchAddress && (
                <button
                  onClick={() => setSearchAddress("")}
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "12px",
                    transform: "translateY(-50%)",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "16px",
                    color: "#000",
                  }}
                >
                  <CloseFilledWhite width={24} height={24} />
                </button>
              )}
              {toHaveAccessibleErrorMessage?.includes("map") && (
                <div
                  style={{ position: "absolute", top: 2, right: 0, padding: 8 }}
                >
                  <ErrorFilled />
                </div>
              )}
            </div>
          </Autocomplete>

          <Text
            marginTop={8}
            variant={"label"}
            alignSelf={"flex-start"}
            color={"var(--red)"}
          >
            {toHaveAccessibleErrorMessage?.includes("map")
              ? "*Map address is required"
              : ""}
          </Text>

          {currentPosition && (
            <div className="LatCont" style={{ marginTop: 10 }}>
              <Text variant="m300" marginTop={16}>
                {"Latitude: " +
                  (currentPosition?.lat ? currentPosition?.lat : "")}
              </Text>
            </div>
          )}

          {currentPosition && (
            <div className="LongCont">
              <Text variant="m300" marginTop={8}>
                {"Longitude: " +
                  (currentPosition?.lng ? currentPosition?.lng : "")}
              </Text>
            </div>
          )}
        </div>
        {currentPosition && (
          <div
            className="CreateEventMapCont"
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 70,
              flex: 1 / 2,
              height: 282,
              paddingLeft: 32,
            }}
          >
            <GoogleMap
              onClick={(ev) => {}}
              mapContainerStyle={{
                borderRadius: 4,
              }}
              mapContainerClassName="map-container"
              center={currentPosition}
              zoom={15}
              defaultCenter={currentPosition}
            >
              <Marker
                position={currentPosition}
                onDragEnd={(e) => onMarkerDragEnd(e)}
                draggable={true}
              >
                {currentPosition && (
                  <InfoWindow
                    position={currentPosition}
                    options={{
                      pixelOffset: new window.google.maps.Size(
                        0,
                        pathname?.includes("/EventDetails") ? -44 : -44
                      ),
                    }}
                  >
                    <h1 style={{ padding: 8, overflow: "hidden" }}>
                      {formattedAddress}
                    </h1>
                  </InfoWindow>
                )}
              </Marker>
            </GoogleMap>
          </div>
        )}
      </div>

      {shouldShowSubmitButton && (
        <div
          className="bottom-buttons-container-edit-view"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 40,
            justifyContent: "center",
          }}
        >
          <ButtonPrimary
            isSecondary={true}
            label={"Cancel"}
            onPress={() => onDiscardClicked()}
            width={328}
            style={{ marginRight: 24 }}
          />
          <ButtonPrimary
            label={"Save changes"}
            onPress={() => onSaveClicked()}
            width={328}
          />
        </div>
      )}
    </div>
  );
}
