import React, { useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Text from "../Text";
import SearchInputField from "../../../Component/Common/InputFields/SearchInputField/SearchInputField";
import { ReactComponent as ChevronDown } from "../../../assets/svgs/ChevronDown.svg";
import { ReactComponent as CloseFilled } from "../../../assets/svgs/CloseFilled.svg";
import { ReactComponent as CalendarFilled } from "../../../assets/svgs/CalendarFilled.svg";
import "./DropdownInput.css";

export default function DropdownInput({
  onSelectedItem,
  data,
  editable,
  eventCategory,
  width,
  style,
  type,
  assistive,
  customRender,
  showDropdown,
  onToggleDropdown,
  resetRequired,
  resetBtnClicked,
  isMultiSelect,
  hideDownArrow,
  searchPlaceHolder,
  onChangeSearchValue = () => {},
}) {
  const [selected, setSelected] = useState(eventCategory || "");
  const [selectedItems, setSelectedItems] = useState([]);
  const [autoFocus, setAutoFocus] = useState(false);

  useEffect(() => {
    setSelected(eventCategory);
  }, [eventCategory]);

  const handleChange = (item, index, objectItem) => {
    onSelectedItem(item, index, objectItem);
    setSelected(
      type === "currency" ? `${item} (${objectItem?.symbol || ""})` : item
    );
  };

  const handleSelect = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((i) => i !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  return (
    <div>
      <Dropdown
        onToggle={(value) => {
          if (onToggleDropdown) {
            onToggleDropdown(value);
          }          
          setAutoFocus(value);
        }}
        show={showDropdown}
        style={{ width: width ? width : "100%", height: 48, ...style }}
        aria-disabled={!editable}
        onChange={(item, index) => handleChange(item, index)}
      >
        <Dropdown.Toggle
          disabled={!editable}
          split
          style={{
            backgroundColor: editable ? "var(--white)" : "var(--veryLightGrey)",
            color:
              selected === "Select age" ||
              selected === "-" ||
              selected === "Select an option" ||
              selected === "DD/MM/YYYY" ||
              selected === "00:00 " ||
              selected === "" ||
              selected === "e.g. 18" ||
              selected === "All statuses" ||
              selected === "All dates" ||
              selected === "All ticket types" ||
              selected === "e.g. United Kingdom" ||
              selected === "Select City" ||
              !editable
                ? getComputedStyle(document.documentElement).getPropertyValue(
                    "--grey"
                  )
                : "var(--midnightBlue)",
            width: width ? width : "100%",
            height: 48,
            display: "flex",
            flexDirection: "row",
            justifyContent:
              eventCategory === "" || eventCategory === undefined
                ? "flex-end"
                : "space-between",
            alignItems: "center",
            border: "1px solid #EBEBEB",
            ...style,
          }}
        >
          {selected}
          {selected !== "" &&
          selected !== "All Entrances" &&
          selected !== "All dates" &&
          selected !== "DD/MM/YYYY" &&
          resetRequired ? (
            <div
              style={{ zIndex: 1 }}
              onClick={(e) => {
                e.stopPropagation();
                resetBtnClicked();
              }}
            >
              <CloseFilled width={24} height={24} />
            </div>
          ) : customRender ? (
            <CalendarFilled />
          ) : hideDownArrow ? null : (
            <ChevronDown
              style={{ marginRight: 7 }}
              stroke={editable ? "var(--midBlue)" : "var(--grey)"}
            />
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{
            width: width ? width : customRender ? "auto" : "100%",
            padding: 0,
            paddingTop: customRender || searchPlaceHolder ? 0 : "4px",
            maxHeight: 300, // Set a max height for the dropdown menu
            overflow: "hidden", // Hide overflow for the entire menu
          }}
        >
          {searchPlaceHolder && (
            <div
              style={{
                position: "sticky",
                top: 0,
                zIndex: 1,
                backgroundColor: "white",
                padding: "0px",
                // boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <SearchInputField
                autoFocused={autoFocus}
                needTextDescription={true}
                style={{ border: "none", boxShadow: "none" }}
                editable={true}
                placeholder={searchPlaceHolder}
                onChangeSearchValue={(event) => {
                  if (event && event !== "clear") {
                    event.stopPropagation();
                  }
                  onChangeSearchValue(
                    event === "clear" ? "clear" : event?.target?.value
                  );
                }}
              />
            </div>
          )}
          <div
            style={{
              maxHeight: searchPlaceHolder ? 260 : 300, // Adjust height based on search bar
              overflowY: customRender ? "hidden" : "auto", // Enable scrolling for the dropdown items
            }}
          >
            {customRender
              ? customRender()
              : data?.length > 0 &&
                data?.map((item, index) => (
                  <Dropdown.Item disabled={item?.code === "BPM" ? true : false}
                    style={{ color: item?.code === "BPM" ? "var(--grey)" : "var(--midnightBlue)", padding: 8, paddingBottom: index === data.length - 1 ? 16 : 8 }}
                    active={selectedItems.includes(item)}
                    onClick={() =>
                      isMultiSelect
                        ? handleSelect(item?.name)
                        : handleChange(
                            type && type == "entranceUserName"
                              ? item
                              : type === "currency" ? item?.code : item?.name,
                            index,
                            item
                          )
                    }
                    key={index}
                  >
                    {type && type == "entranceUserName"
                      ? item
                      : type === "currency"
                      ? (item?.symbol
                        ? `${item?.code} (${item?.symbol || ""})`
                        : item?.code === "BPM" ? "BPM (crypto payments coming soon)" : item?.code)
                      : item?.name}
                  </Dropdown.Item>
                ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
      {assistive && (
        <Text marginTop={8} variant={"label"} color={"red"}>
          {assistive}
        </Text>
      )}
    </div>

    // <Form.Select
    //   style={{ width: width ? width : "100%", height: 48, ...style }}
    //   disabled={!editable}
    //   value={selected}
    //   onChange={handleChange}
    // >
    //   {(eventCategory === "" || eventCategory === "-") && (
    //     <option key="blankChoice" hidden value>
    //       {eventCategory === "-" && "-"}
    //     </option>
    //   )}
    //   {data.map((item, index) => {
    //     return <option key={index}>{type && type == 'currency' ? item.abbreviation : item.name}</option>;
    //   })}
    // </Form.Select>
  );
}
