import { BANNER_CONTENT } from '../actions';

export default function bannerContent(state = null, action = {}) {
  switch (action.type) {
    case BANNER_CONTENT: {
      return {
        ...state,
        bannerContent: action.payload,
      };
    }

    default:
      return state;
  }
}
