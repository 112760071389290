import React, { useState, useEffect } from "react";
import "./Timing.css";
import "../Ticket/Ticket.css";
import { useLocation } from "react-router-dom";
import ButtonPill from "../../Common/Buttons/ButtonPill";
import DatePicker from "react-datepicker";
import TooltipPopup from "../../../Component/Common/TooltipPopup/TooltipPopup";
import DropdownInput from "../../../Component/Common/DropdownInput/DropdownInput";
import ButtonPrimary from "../../../Component/Common/Buttons/ButtonPrimary/ButtonPrimary";
import Text from "../../../Component/Common/Text";

export default function Timing({
  selectedStartDateString,
  selectedEndDateString,
  selectedStartTimeString,
  selectedEndTimeString,
  setSelectedStartDateString,
  setSelectedEndDateString,
  setSelectedStartTimeString,
  setSelectedEndTimeString,
  shouldShowSubmitButton,
  onSaveTimingClicked,
  onDiscardTimingClicked,
  toHaveAccessibleErrorMessage,
  shouldShowWarning = false,
}) {
  const { pathname } = useLocation();
  const [selectedStartDateValue, setSelectedStartDateValue] = useState(
    selectedStartDateString
  );
  const [selectedEndDateValue, setSelectedEndDateValue] = useState(
    selectedEndDateString
  );
  const [selectedStartTimeValue, setSelectedStartTimeValue] = useState(
    selectedStartTimeString
  );
  const [selectedEndTimeValue, setSelectedEndTimeValue] = useState(
    selectedEndTimeString
  );
  const [isDatePickerVisibleStartDate, setIsDatePickerVisibleStartDate] =
    useState(false);
  const [isDatePickerVisibleEndDate, setIsDatePickerVisibleEndDate] =
    useState(false);

  useEffect(() => {
    setSelectedStartDateValue(
      selectedStartDateString ? selectedStartDateString : "DD/MM/YYYY"
    );
    setSelectedEndDateValue(
      selectedEndDateString ? selectedEndDateString : "DD/MM/YYYY"
    );
    setSelectedStartTimeValue(
      selectedStartTimeString ? selectedStartTimeString : "00:00 "
    );
    setSelectedEndTimeValue(
      selectedEndTimeString ? selectedEndTimeString : "00:00 "
    );

    console.log("selectedStartDateString", selectedStartDateString);
  }, [
    selectedStartDateString,
    selectedEndDateString,
    selectedStartTimeString,
    selectedEndTimeString,
  ]);

  const generateTimeArray = () => {
    const timeArray = [];
    for (let hours = 0; hours < 24; hours++) {
      for (let minutes = 0; minutes < 60; minutes += 15) {
        const formattedTime = `${String(hours).padStart(2, "0")}:${String(
          minutes
        ).padStart(2, "0")}`;
        timeArray.push({ name: formattedTime });
      }
    }
    return timeArray;
  };

  const generateCustomTimeArray = (startTime) => {
    const [startHour, startMinute] = startTime.split(":").map(Number);
    const timeArray = [];

    // Generate times from start time to the end of the day
    for (let hours = startHour; hours < 24; hours++) {
      for (
        let minutes = hours === startHour ? startMinute : 0;
        minutes < 60;
        minutes += 15
      ) {
        const formattedTime = `${String(hours).padStart(2, "0")}:${String(
          minutes
        ).padStart(2, "0")}`;
        timeArray.push({ name: formattedTime });
      }
    }

    return timeArray;
  };

  const getCurrentTimeRoundedTo15Minutes = () => {
    const date = new Date();
    const minutes = date.getMinutes();
    const roundedMinutes = Math.ceil(minutes / 15) * 15;
    date.setMinutes(roundedMinutes);
    const hours = date.getHours();
    const formattedTime = `${String(hours).padStart(2, "0")}:${String(
      date.getMinutes()
    ).padStart(2, "0")}`;
    return formattedTime;
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const currentTimeRounded = getCurrentTimeRoundedTo15Minutes();

  return (
    <div className="timing-about-div SummaryLabeledDivEdit">
      <div className="generic-common-row-div">
        <Text variant={"m500"}>Timings</Text>
        <span className="TimingsInfoIconCont">
          <TooltipPopup />
        </span>
      </div>

      {/* <div
        className="TimingBodyCont"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      > */}
      <div
        className="date-parent-box"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 40,
        }}
      >
        {/* <div
          className="TimingStartEndDate"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 40,
            flex: 1,
          }}
        > */}
          <div style={{paddingRight: '64px'}} className="date-child-box">
            <div className="BoxLabel">
              <Text variant="m300" marginBottom={8}>
                Start date*
              </Text>
            </div>
            {/* <DOBInput
                inputValueDay={startDate}
                inputValueMonth={startMonth}
                inputValueYear={startYear}
                editable={true}
                assistive={dOBErrorStartDate}
                onBlur={() => {}}
                dOBTextInputChange={(value, flag) =>
                  onDOBInputChange(value, flag, "start")
                }
              /> */}

            <DropdownInput
              resetRequired={true}
              showDropdown={isDatePickerVisibleStartDate}
              onToggleDropdown={() =>
                setIsDatePickerVisibleStartDate(!isDatePickerVisibleStartDate)
              }
              eventCategory={selectedStartDateValue}
              data={[]}
              editable={true}
              resetBtnClicked={() => {
                setSelectedStartDateValue("DD/MM/YYYY");
                setSelectedStartDateString("DD/MM/YYYY");
              }}
              customRender={() => {
                return (
                  <div style={{ marginBottom: -10 }}>
                    <DatePicker
                      shouldCloseOnSelect={true}
                      onChange={(update) => {
                        setSelectedStartDateString(
                          new Date(update).toLocaleDateString("en-GB")
                        );
                        setSelectedStartDateValue(
                          new Date(update).toLocaleDateString("en-GB")
                        );
                        setIsDatePickerVisibleStartDate(
                          !isDatePickerVisibleStartDate
                        );
                      }}
                      startDate={new Date()}
                      inline
                      minDate={new Date()}
                      maxDate={
                        new Date(
                          selectedEndDateValue.split("/")[2],
                          selectedEndDateValue.split("/")[1] - 1,
                          selectedEndDateValue.split("/")[0]
                        ) || null
                      }
                    />
                  </div>
                );
              }}
            />
            <Text
              marginTop={8}
              variant={"label"}
              alignSelf={"flex-start"}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--red")}
            >
              {toHaveAccessibleErrorMessage?.includes("startDate")
                ? "*Start date is required"
                : ""}
            </Text>
          </div>

          <div className="date-child-box">
            <div className="BoxLabel">
              <Text variant="m300" marginBottom={8}>
                Start time*
              </Text>
            </div>
            <DropdownInput
              eventCategory={selectedStartTimeValue}
              data={
                selectedStartDateValue === formatDate(new Date())
                  ? generateCustomTimeArray(currentTimeRounded)
                  : generateTimeArray()
              }
              onSelectedItem={(value) => {
                setSelectedStartTimeValue(value);
                setSelectedStartTimeString(value);
              }}
              editable={selectedStartDateValue === "DD/MM/YYYY" ? false : true}
            />
            <Text
              marginTop={8}
              variant={"label"}
              alignSelf={"flex-start"}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--red")}
            >
              {toHaveAccessibleErrorMessage?.includes("startTime")
                ? "*Time is required"
                : ""}
            </Text>
          </div>
        </div>


        <div
        className="date-parent-box"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 24,
        }}
      >

<div style={{paddingRight: '64px'}} className="date-child-box">
            <div className="BoxLabel">
              <Text marginTop={40} variant="m300" marginBottom={8}>
                End date*
              </Text>
            </div>

            <DropdownInput
              resetRequired={true}
              showDropdown={isDatePickerVisibleEndDate}
              onToggleDropdown={() =>
                setIsDatePickerVisibleEndDate(!isDatePickerVisibleEndDate)
              }
              eventCategory={selectedEndDateValue}
              data={[]}
              editable={
                selectedStartDateValue === "DD/MM/YYYY" ||
                selectedStartTimeValue === "00:00 "
                  ? false
                  : true
              }
              resetBtnClicked={() => {
                setSelectedEndDateValue("DD/MM/YYYY");
                setSelectedEndDateString("DD/MM/YYYY");
              }}
              customRender={() => {
                return (
                  <div style={{ marginBottom: -10 }}>
                    <DatePicker
                      shouldCloseOnSelect={true}
                      onChange={(update) => {
                        setSelectedEndDateValue(
                          new Date(update).toLocaleDateString("en-GB")
                        );
                        setSelectedEndDateString(
                          new Date(update).toLocaleDateString("en-GB")
                        );
                        setIsDatePickerVisibleEndDate(
                          !isDatePickerVisibleEndDate
                        );
                      }}
                      minDate={
                        new Date(
                          selectedStartDateValue.split("/")[2],
                          selectedStartDateValue.split("/")[1] - 1,
                          selectedStartDateValue.split("/")[0]
                        )
                      }
                      inline
                    />
                  </div>
                );
              }}
            />
            <Text
              marginTop={8}
              variant={"label"}
              alignSelf={"flex-start"}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--red")}
            >
              {toHaveAccessibleErrorMessage?.includes("endDate")
                ? "*End date is required"
                : ""}
            </Text>
          </div>

          <div className="date-child-box">
            <div className="BoxLabel">
              <Text variant="m300" marginTop={43} marginBottom={8}>
                End time*
              </Text>
            </div>

            <DropdownInput
              eventCategory={selectedEndTimeValue}
              data={
                selectedStartDateValue === selectedEndDateValue
                  ? generateCustomTimeArray(selectedStartTimeValue)
                  : generateTimeArray()
              }
              onSelectedItem={(value) => {
                setSelectedEndTimeValue(value);
                setSelectedEndTimeString(value);
              }}
              editable={selectedEndDateValue === "DD/MM/YYYY" ? false : true}
            />
            <Text
              marginTop={8}
              variant={"label"}
              alignSelf={"flex-start"}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--red")}
            >
              {toHaveAccessibleErrorMessage?.includes("endTime")
                ? "*Time is required"
                : ""}
            </Text>
          </div>
        </div>
      {/* </div> */}

      {pathname?.includes("/EventDetails") && shouldShowWarning && (
        <div
          style={{ marginTop: 32 }}
          className="generic-common-row-centerd-div"
        >
          <ButtonPill
            type={"partiallycompleted"}
            varient={"small"}
            label={
              "This event has been published with the current selections. Any updates you make will apply to the entire event."
            }
          />
        </div>
      )}

      {shouldShowSubmitButton && (
        <div
          className="bottom-buttons-container-edit-view"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 32,
          }}
        >
          <ButtonPrimary
            isSecondary={true}
            label={"Cancel"}
            onPress={() => onDiscardTimingClicked()}
            width={328}
            style={{ marginRight: 24 }}
          />
          <ButtonPrimary
            label={"Save changes"}
            onPress={() => onSaveTimingClicked()}
            width={328}
          />
        </div>
      )}
    </div>
  );
}
