import React, { useState } from "react";
import Text from "../../Common/Text";
import Popup from "reactjs-popup";
import { ReactComponent as MoreFilled } from "../../../assets/svgs/MoreFilled.svg";
import { ReactComponent as CardTick } from "../../../assets/svgs/CardTick.svg";
import { ReactComponent as BinFilled } from "../../../assets/svgs/BinFilled.svg";

export default function TooltipPopup({ onOptionClicked, selectedIndex }) {
  const isMobile = window.innerWidth <= 768;
  const dataOptions = [
    { label: "Set as default card", icon: <CardTick /> },
    {
      label: "Remove card",
      icon: <BinFilled fill={"var(--red)"} width={20} height={20} />,
    },
  ];
  const [isPopUpClose, setIsPopUpClose] = useState(false);
  return (
    <Popup
      className={`tooltip-parent-container ${
        isPopUpClose ? "close1" : "show1"
      }`}
      // modal={true}
      repositionOnResize={true}
      //   offsetY={13}
      offsetY={25}
      offsetX={-25}
      arrow={false}
      contentStyle={{
        width: "235px",
        borderRadius: "8px",
        padding: "8px 12px",
        boxShadow: "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
        border: "none",
        backgroundColor: "var(--veryLightGrey)",
      }}
      trigger={(open) => (
        setIsPopUpClose(false),
        (
          <div style={{ cursor: "pointer", marginLeft: 16 }}>
            <MoreFilled width={24} height={24} />
          </div>
        )
      )}
      position={selectedIndex % 2 !== 0 || isMobile ? "left top" : "right top"}
      closeOnDocumentClick
      closeOnEscape={true}
    >
      {(close) => (
        <div className="generic-common-column-div">
          {dataOptions.map((item, index) => (
            <div
              key={index}
              onClick={() => {
                onOptionClicked(index);
                close();
              }}
              style={{ width: "100%" }}
              className="generic-common-column-div"
            >
              <div
                className="generic-common-row-div"
                style={{
                  padding: "8px 0",
                  cursor: "pointer",
                }}
              >
                <div style={{ marginRight: 8 }}>{item.icon}</div>
                <Text cursor={"pointer"} variant={"m200"}>
                  {item.label}
                </Text>
              </div>
              {index !== dataOptions.length - 1 && (
                <div className="generic-common-divider" />
              )}
            </div>
          ))}
          <div
            onClick={() => {
              close();
              setIsPopUpClose(true);
            }}
            className="close-custom-tooltip desktop-hide-close-custom-tooltip"
          >
            <Text
              alignSelf={"center"}
              marginTop={24}
              cursor={"pointer"}
              variant={"m200"}
            >
              Close
            </Text>
          </div>
        </div>
      )}
    </Popup>
  );
}
