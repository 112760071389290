import Text from "../Common/Text";
import MenuLink from "../Common/MenuLink/MenuLink";
import Tags from "../Common/Tags/Tags";
import "./HeaderNew.css";
import { ReactComponent as SuccessFilled } from "../../assets/svgs/SuccessFilled.svg";

export default function HeaderNew({
  btn2Title,
  btn1Title,
  btn3Title,
  mennLabelArray,
  onMenuLinkPress,
  headerTitle,
  eventSectionOptions,
  selectedEventOptionIndex,
  segmentBtnPressedEvent,
  subHeaderTitle,
  btn1Clicked,
  btn2Clicked,
  btn3Clicked,
}) {
  return (
    // height: "24%"
    <div style={{ display: "flex", flexDirection: "column", marginBottom: 16 }}>
      <MenuLink
        btn1Label={btn1Title}
        btn2Label={btn2Title}
        btn3Label={btn3Title}
        btn1LabelClicked={() => btn1Clicked()}
        btn2LabelClicked={() => btn2Clicked()}
        btn3LabelClicked={() => btn3Clicked()}
        labelArray={mennLabelArray}
        withCog={false}
        onMenuLinkPress={(ind) => onMenuLinkPress(ind)}
      />
      <div className="event-details-top-after-menulink-container">
        <Text variant={"m600"}>{headerTitle}</Text>

        <Text ellclass="dgdwgd" marginTop={22} variant={"r500"}>
          {subHeaderTitle}
        </Text>

        <div className="event-details-event-scroll-container">
          <div className="event-detailsTop-btns-inner">
            {eventSectionOptions.map((items, ind) => {
              return (
                <div key={ind} style={{ marginRight: 12 }}>
                  <Tags
                    SourceIcon={items.isCompleted && SuccessFilled}
                    paddingVertical={8}
                    label={items.name}
                    selected={ind == selectedEventOptionIndex ? true : false}
                    onChangeSelection={() => segmentBtnPressedEvent(ind)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
