import { USER_DATA } from '../actions';

export default function userData(state = null, action = {}) {
  switch (action.type) {
    case USER_DATA: {
      return {
        ...state,
        userData: action.payload,
      };
    }

    default:
      return state;
  }
}
