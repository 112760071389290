import axios from "axios";

const token = localStorage.getItem("authToken");

axios.defaults.headers.common["Authorization"] = `Bearer ${JSON.parse(token)}`;

class Api {
  async defaultHeader(object) {
    Object.keys(object).forEach((key) => {
      axios.defaults.headers.common[key] = object[key];
    });
  }

  GET(endpoint, params, excludeAPI) {
    return new Promise((resolve) => {
      if (navigator.onLine) {
        axios({
          method: "GET",
          url: excludeAPI
            ? this.normalizePathExcludeApi(endpoint)
            : this.normalizePath(endpoint),
          params,
          headers: { "Content-Type": "application/json" },
          responseType: "json",
        })
          .then((response) => {
            resolve({
              status: response.status,
              response: response.data,
            });
          })
          .catch((error) => {
            resolve({
              status: error?.response?.status,
              response: error?.response?.data,
            });
          });
      } else {
        resolve({
          status: 502,
          response: "Network Error",
        });
      }
    });
  }

  POST(endpoint, params, headers) {
    return new Promise((resolve) => {
      if (navigator.onLine) {
        axios({
          method: "post",
          url: this.normalizePath(endpoint),
          data: JSON.stringify(params),
          headers: { "Content-Type": "application/json", ...headers },
        })
          .then((response) => {
            resolve({
              status: response.status,
              response: response.data,
            });
          })
          .catch((error) => {
            resolve({
              status: error?.response?.status,
              response: error?.response?.data,
            });
          });
      } else {
        resolve({
          status: 502,
          response: "Network Error",
        });
      }
    });
  }

  PUT(endpoint, params, headers) {
    return new Promise((resolve) => {
      if (navigator.onLine) {
        axios({
          method: "PUT",
          url: this.normalizePath(endpoint),
          data: JSON.stringify(params),
          headers: { "Content-Type": "application/json", ...headers },
        })
          .then((response) => {
            resolve({
              status: response.status,
              response: response.data,
            });
          })
          .catch((error) => {
            resolve({
              status: error?.response?.status,
              response: error?.response?.data,
            });
          });
      } else {
        resolve({
          status: 502,
          response: "Network Error",
        });
      }
    });
  }

  DELETE(endpoint, params, headers) {
    return new Promise((resolve) => {
      if (navigator.onLine) {
        axios({
          method: "DELETE",
          url: this.normalizePath(endpoint),
          data: JSON.stringify(params),
          headers: { "Content-Type": "application/json", ...headers },
        })
          .then((response) => {
            resolve({
              status: response.status,
              response: response.data,
            });
          })
          .catch((error) => {
            resolve({
              status: error.response.status,
              response: error.response?.data,
            });
          });
      } else {
        resolve({
          status: 502,
          response: "Network Error",
        });
      }
    });
  }

  normalizePath(endpoint) {
    return `${process.env.REACT_APP_BASE_URL}/${endpoint}`;
  }

  normalizePathExcludeApi(endpoint) {
    return `${process.env.REACT_APP_API_URL}/${endpoint}`;
  }
}

export default new Api();
