import { emailReg } from "../../constants/regex";
import { useState } from "react";

const useEmailVerification = () => {
  const [isVerified, setIsVerified] = useState(false);

  const verifyEmail = (email) => {
    setIsVerified(emailReg.test(email));
  };

  return { isVerified, verifyEmail };
};
export default useEmailVerification;
