import React, { useState } from "react";
import Text from "../Text";
import ChevronRight from "../../../assets/svgs/ChevronRight.svg";

export default function ButtonPill({
  label,
  showLeftIcon,
  onClick,
  icon,
  style,
  width,
  type,
  varient,
}) {
  const divStyles = {
    width: width + 20 || "auto",
    backgroundColor:
      type === "expired"
        ? "var(--hoverGrey)"
        : type === "completed"
        ? "var(--hoverStateGreen)"
        : type === "partiallycompleted"
        ? "var(--hoverOrange)"
        : type === "error"
        ? "var(--hoverRed)"
        : type === "info"
        ? "var(--surfaceLight)"
        : "var(--hoverStateMidBlue)",
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 4,
    paddingTop: 4,
    paddingBottom: 4,
    ...style,
  };

  return (
    <div style={{ ...divStyles }} onClick={() => onClick && onClick()}>
      {/* check showLeftIcon flag for show/hide the Chevron right icon */}
      {showLeftIcon &&
        (icon ? (
          icon
        ) : (
          <ChevronRight
            width={24}
            height={24}
            // stroke={disabled ? veryDarkGrey : white}
          />
        ))}
      <Text newLineData={label?.split('\n') ? label?.split('\n') : false}
        style={{
          display: "inline-block",
          maxwidth: "100%",
          whiteSpace: "normal",
          wordBreak: "break-word",
        }}
        marginLeft={showLeftIcon ? 8 : 0}
        variant={varient === "small" ? "r200" : "r300"}
        color={
          type === "expired"
            ? "var(--grey)"
            : type === "completed"
            ? "var(--greenSuccess)"
            : type === "partiallycompleted"
            ? "var(--orange)"
            : type === "error"
            ? "var(--red)"
            : type === "info"
            ? "var(--midnightBlue)"
            : "var(--midBlue)"

        }
      >
        {`${label}`}
      </Text>
    </div>
  );
}
