import { SETTINGS_DATA } from '../actions';

export default function settingsData(state = null, action = {}) {
  switch (action.type) {
    case SETTINGS_DATA: {
      return {
        ...state,
        settingsData: action.payload,
      };
    }

    default:
      return state;
  }
}
