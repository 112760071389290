import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Text from "../../Component/Common/Text";
import Tags from "../../Component/Common/Tags/Tags";
import ButtonPill from "../../Component/Common/Buttons/ButtonPill";
import { ReactComponent as ChevronRight } from "../../assets/svgs/ChevronRight.svg";
import "./Settings.css";
import "../OrderHistory/OrderList.css";
import MenuLink from "../../Component/Common/MenuLink/MenuLink";
import CentralCustomModal from "../../Component/Common/CentralModals/CentralCustomModal";
import ButtonPrimary from "../../Component/Common/Buttons/ButtonPrimary/ButtonPrimary";
import { SHOW_ACTIVITY_INDICATOR, BANNER_CONTENT } from "../../actions";
import { getInvoiceDetails, getInvoiceUriToDownload } from "../../services/api";

function InvoiceHistory({ segmentBtnPressed, data, taxValue }) {
  const dispatch = useDispatch();
  const [selectedSegmentIndexHelp, setSelectedSegmentIndexHelp] = useState(2);
  const [invoiceList, setinvoiceList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCentralModalVisible, setIsCentralModalVisible] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState({});

  const SettingsOptions = [
    { name: "Account", id: 1 },
    { name: "Payment set-up", id: 2 },
    { name: "Billing", id: 3 },
    { name: "Social", id: 4 },
  ];

  const lineItemTitle = ["Invoice", "Status", "Total", ""];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    setinvoiceList(data);
  }, [data]);

  const getInvoiceDetailsRequest = async (invoiceId) => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const result = await getInvoiceDetails({ invoiceId: invoiceId });
    if (result.status) {
      setInvoiceDetails(result.response?.invoiceDetails);
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
      setIsCentralModalVisible(true);
    } else {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    }
  };

  const getInvoiceUriToDownloadRequest = async (invoiceId) => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const result = await getInvoiceUriToDownload({ invoiceId: invoiceId });
    setIsCentralModalVisible(false);
    if (result.status) {
      if (result?.response?.invoiceUrl) {
        // const url = window.URL.createObjectURL(
        //   new Blob([result?.response?.invoiceUrl])
        // );
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", `Invoice${invoiceId}.pdf`);
        // document.body.appendChild(link);
        // link.click();
        // link.remove();


        const link = document.createElement('a');
        link.href = result?.response?.invoiceUrl;
        link.download = `Invoice${invoiceId}.pdf`;
        // Append to the document body
        document.body.appendChild(link)
    ;
    link.target = '_blank'; 
        // Trigger the download
        link.click();
        // Clean up
        document.body.removeChild(link)
    ;
      }
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    } else {
      dispatch({
        type: BANNER_CONTENT,
        payload: {
          text: result?.response?.errorMessage,
          headingText: 'Invoice download failed',
          showBanner: true,
          type: "Error",
          withClose: true,
          withIcon: true,
          position: "top",
          width: 391,
        },
      });
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    }
  };

  const segmentBtnPressedHelp = (index) => {
    // if (index !== selectedSegmentIndexHelp) {
    setSelectedSegmentIndexHelp(index);
    segmentBtnPressed(index);
    // }
  };

  function getOrdinalSuffix(num) {
    const suffixes = ["th", "st", "nd", "rd"];
    const lastDigit = num % 10;
    const suffix =
      num > 10 && num < 20
        ? suffixes[0]
        : lastDigit <= 3
        ? suffixes[lastDigit]
        : suffixes[0];
    return num + suffix;
  }
  const renderInvoiceList = ({ items, index }) => {
    //
    return (
      <div
        className="orderList-inner-widthScroll"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="order-list-line-item-container" key={index}>
          <div
            onClick={() => getInvoiceDetailsRequest(items?.invoiceId)}
            className="order-list-line-item-inner"
            style={{
              width: "42%",
              marginRight: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Text
              style={{ cursor: "pointer" }}
              showUnderlineOnHover={true}
              color={"var(--midnightBlue)"}
              variant={"m200"}
            >
              {getOrdinalSuffix(new Date(items?.invoiceDate).getDate()) +
                " " +
                months[new Date(items?.invoiceDate).getMonth()] +
                " " +
                new Date(items?.invoiceDate).getFullYear()}
            </Text>
            <Text
              showUnderlineOnHover={true}
              style={{ cursor: "pointer" }}
              marginTop={4}
              color={"var(--grey)"}
              variant={"r100"}
            >
              {items?.invoiceId}
            </Text>
          </div>
          <div
            className="order-list-line-item-inner"
            style={{
              width: "25%",
              marginRight: 1,
              display: "flex",
              // backgroundColor: "var(--lightGrey)",
            }}
          >
            <ButtonPill
              type={items?.status === "succeeded" ? "completed" : "generic"}
              varient={"small"}
              label={items?.status === "succeeded" ? "Paid" : "Funded"}
              // label={"Funded"}
            />
          </div>
          <div
            className="order-list-line-item-inner"
            style={{
              width: "25%",
              marginRight: 1,
            }}
          >
            <Text color={"var(--midnightBlue)"} variant={"m200"}>
              {`${items?.currencySymbol}${items?.total}`}
            </Text>
          </div>

          <div
            className="order-list-line-item-inner"
            style={{
              width: "25%",
              // backgroundColor: "var(--lightGrey)",
              justifyContent: "end",
              display: "flex",
              cursor: "pointer",
            }}
            onClick={() => getInvoiceDetailsRequest(items?.invoiceId)}
          >
            <ChevronRight stroke={"var(--midBlue)"} width={24} height={24} />
          </div>
        </div>
        {index !== invoiceList.length - 1 && (
          <div
            style={{
              height: 1,
              width: "100%",
              backgroundColor: "var(--lightGrey)",
            }}
          />
        )}
      </div>
    );
  };

  const renderInvoiceDetails = () => {
    return (
      <div
        className="central-modal-contentPopup-bottomList"
        style={{
          width: "100%",
          zIndex: 1,
          height: "100%",
          overflowY: "auto",
          // backgroundColor: "var(--white)",
        }}
      >
        <div
          style={{ marginTop: 32, marginBottom: 48 }}
          className="generic-common-divider"
        />
        <Text variant={"m500"}>Summary</Text>
        <div
          style={{ marginTop: 36, marginBottom: 56 }}
          className="generic-common-row-div-space-between"
        >
          <div style={{ width: "50%" }} className="generic-common-column-div">
            <Text
              color={"var(--midBlue)"}
              textclass="Heading-div"
              variant={"m300"}
            >
              INVOICE NUMBER
            </Text>
            <Text marginTop={12} textclass="Heading-div" variant={"m400"}>
              {invoiceDetails?.invoiceId}
            </Text>

            <Text
              marginTop={24}
              color={"var(--midBlue)"}
              textclass="Heading-div"
              variant={"m300"}
            >
              TOTAL
            </Text>
            <Text marginTop={12} textclass="Heading-div" variant={"m400"}>
              {/* £50.00 */}
              {invoiceDetails?.total
                ? `${invoiceDetails?.currencySymbol}${invoiceDetails?.total}`
                : ""}
            </Text>
          </div>

          <div
            style={{ marginLeft: 24, width: "50%" }}
            className="generic-common-column-div"
          >
            <Text
              color={"var(--midBlue)"}
              textclass="Heading-div"
              variant={"m300"}
            >
              DATE
            </Text>
            <Text marginTop={12} textclass="Heading-div" variant={"m400"}>
              {getOrdinalSuffix(
                new Date(invoiceDetails?.invoiceDate).getDate()
              ) +
                " " +
                months[new Date(invoiceDetails?.invoiceDate).getMonth()] +
                " " +
                new Date(invoiceDetails?.invoiceDate).getFullYear()}
            </Text>

            <Text
              marginTop={24}
              marginBottom={12}
              color={"var(--midBlue)"}
              textclass="Heading-div"
              variant={"m300"}
            >
              STATUS
            </Text>

            <ButtonPill
              type={
                invoiceDetails?.status === "succeeded" ? "completed" : "generic"
              }
              varient={"small"}
              label={invoiceDetails?.status === "succeeded" ? "Paid" : "Funded"}
            />
          </div>
        </div>

        {Array.from({ length: taxValue ? 4 : 3 }).map((_, index) => {
          return (
            <div>
              <div className="generic-common-row-div-space-between">
                <Text
                  width={"50%"}
                  marginRight={24}
                  textclass="Heading-div"
                  variant={index === 0 ? "m300" : index === 3 ? "b400" : "m400"}
                >
                  {index === 0
                    ? "Item"
                    : index === 1
                    ? "Subtotal"
                    : index === 2 && taxValue
                    ? "Tax"
                    : "Total"}
                </Text>

                <Text
                  width={"50%"}
                  textclass="Heading-div"
                  variant={index === 0 ? "m300" : index === 3 ? "b400" : "m400"}
                >
                  {index === 0
                    ? "Price"
                    : index === 1
                    ? invoiceDetails?.subTotal
                      ? `${invoiceDetails?.currencySymbol}${invoiceDetails?.subTotal.toFixed(2)}`
                      : `-`
                    : index === 2 && taxValue
                    ? `${invoiceDetails?.currencySymbol}${taxValue.toFixed(2)}`
                    : invoiceDetails?.total.toFixed(2)
                    ? `${invoiceDetails?.currencySymbol}${taxValue ? (invoiceDetails?.total + taxValue).toFixed(2) : invoiceDetails?.total.toFixed(2)}`
                    : `-`}
                </Text>
              </div>
              {index !== 3 && (
                <div
                  style={{ marginTop: 24, marginBottom: 24 }}
                  className="generic-common-divider"
                />
              )}
            </div>
          );
        })}

        <div
          style={{ marginTop: 64, marginBottom: 40 }}
          className="generic-common-divider"
        />

        <Text variant={"m500"}>Payment info</Text>

        <div
          style={{ marginTop: 40, marginBottom: 40 }}
          className="generic-common-row-div-space-between paymenyInfo-description-block"
        >
          <div style={{ width: "50%" }} className="generic-common-column-div">
            <Text
              color={"var(--midBlue)"}
              textclass="Heading-div"
              variant={"m300"}
            >
              PAYMENT METHOD
            </Text>
            <Text marginTop={12} textclass="Heading-div" variant={"m400"}>
              {invoiceDetails?.paymentMethod
                ? invoiceDetails?.paymentMethod
                : `-`}
            </Text>

            <Text
              marginTop={24}
              color={"var(--midBlue)"}
              textclass="Heading-div"
              variant={"m300"}
            >
              CARD TYPE
            </Text>
            <Text marginTop={12} textclass="Heading-div" variant={"m400"}>
              {invoiceDetails?.cardType ? invoiceDetails?.cardType : `-`}
            </Text>
          </div>

          <div
            style={{ marginLeft: 24, width: "50%" }}
            className="generic-common-column-div"
          >
            <Text
              color={"var(--midBlue)"}
              textclass="Heading-div"
              variant={"m300"}
            >
              TRANSACTION ID
            </Text>
            <Text marginTop={12} textclass="Heading-div" variant={"m400"}>
              {invoiceDetails?.transactionId
                ? invoiceDetails?.transactionId
                : `-`}
            </Text>

            <Text
              marginTop={24}
              marginBottom={12}
              color={"var(--midBlue)"}
              textclass="Heading-div"
              variant={"m300"}
            >
              CARD NUMBER
            </Text>

            <Text marginTop={12} textclass="Heading-div" variant={"m400"}>
              {/* ************8800 */}
              {invoiceDetails?.cardNumber ? invoiceDetails.cardNumber : `-`}
            </Text>
          </div>
        </div>

        <div className="generic-common-row-centerd-div">
          <ButtonPrimary
            isClickingEffectEnabled={true}
            isSecondary={true}
            width={328}
            label={"Download invoice"}
            onPress={() =>
              getInvoiceUriToDownloadRequest(invoiceDetails?.invoiceId)
            }
          />
        </div>
      </div>
    );
  };

  return (
    <div className="home-content-div">
      <CentralCustomModal
        type={"invoiceHistory"}
        title={`Invoice ${invoiceDetails?.invoiceId} (${
          getOrdinalSuffix(new Date(invoiceDetails?.invoiceDate).getDate()) +
          " " +
          months[new Date(invoiceDetails?.invoiceDate).getMonth()] +
          " " +
          new Date(invoiceDetails?.invoiceDate).getFullYear()
        })`}
        show={isCentralModalVisible}
        onClose={() => setIsCentralModalVisible(false)}
        renderContent={() => renderInvoiceDetails()}
      />
      <div
        className="invoice-history-listing-container"
        style={{
          display: "flex",
          flexDirection: "column",
          paddingBottom: 150,
          paddingLeft: 24,
        }}
      >
        <div style={{ marginTop: 0 }} className="home-scroll-container">
          {SettingsOptions.map((items, ind) => {
            return (
              <div key={ind} style={{ marginRight: 12 }}>
                <Tags
                  paddingVertical={8}
                  label={items.name}
                  selected={ind === selectedSegmentIndexHelp ? true : false}
                  onChangeSelection={() => segmentBtnPressedHelp(ind)}
                />
              </div>
            );
          })}
        </div>
        <MenuLink
          // btn2Label={"Cancel event"}
          labelArray={[
            { label: "Billing", id: 1 },
            { label: "Invoice history", id: 2 },
          ]}
          withCog={false}
          onMenuLinkPress={(ind) => (ind === 0 ? segmentBtnPressed(2) : null)}
        />

        <div className="invoiceHistory-list-scroll">
          <div className="invoiceHistory-list-scroll-inner">
            <div
              style={{ marginLeft: 40 }}
              className="order-list-line-item-title-container"
            >
              <div className="order-list-line-item-tittleInner">
                {lineItemTitle.map((items, ind) => {
                  return (
                    <div
                      style={{
                        width:
                          ind === 0
                            ? "42%"
                            : ind === 1 || ind === 2 || ind === 3
                            ? "25%"
                            : "20%",
                        marginRight: 1,
                      }}
                    >
                      <Text variant={"m300"}>{items}</Text>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="order-list-item-parent-content">
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                  className="d-flex justify-content-center"
                >
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              ) : invoiceList.length > 0 ? (
                invoiceList.map((items, index) => {
                  return renderInvoiceList({ items, index });
                })
              ) : (
                <div
                  style={{
                    color: "var(--grey)",
                    textAlign: "center",
                    marginTop: "64px",
                    marginBottom: "64px",
                  }}
                >
                  No Invoice History found.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceHistory;
