import React, { useState, useEffect } from "react";
import Text from "../Text";
import "./Accordion.css";
import Accordion1 from "react-bootstrap/Accordion";

export default function Accordion({
  heading,
  label,
  isOpen,
  onPressDescription,
  isSegmentChanged,
  openAccordion,
  index,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [isSegmentChanged]);

  useEffect(() => {
    setOpen(openAccordion);
  }, [openAccordion]);

  useEffect(() => {
    
  }, [index]);

  const formatLabelWithNewLines = (text) => {
    return text.split("\n").map((str, idx) => (
      <React.Fragment key={idx}>
        {str}
        <br />
      </React.Fragment>
    ));
  };

  return (
    <Accordion1 accessKey={index} defaultActiveKey={index}>
      <Accordion1.Item eventKey={String(index + 1)}>
        <Accordion1.Header
          onClick={() => {
            setOpen((val) => !val);
            isOpen && isOpen(!open);
          }}
        >
          {heading}
        </Accordion1.Header>
        {open && <Accordion1.Body>{formatLabelWithNewLines(label)}</Accordion1.Body>}
      </Accordion1.Item>
    </Accordion1>
  );
}
