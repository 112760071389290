import React from 'react';
import "./Loader.css"
import { useSelector } from 'react-redux';

export default function Loader() {
  // const and values
 const showLoader = useSelector((state) => state.showLoader?.showLoader);
 
  return (
    <div className={`${showLoader ? 'central-modal-overlay22' : ''}`}>
    <div className={`loader-container22 ${showLoader ? 'show22' : ''}`}>
    <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
        </div>
        </div>
  );
}
