import React from "react";
import "./CentralModal.css";
import { ReactComponent as CloseFilled } from "../../../assets/svgs/CloseFilled.svg";
import HoverBGButton from "../Buttons/HoverBGButton/HoverBGButton";

export default function CentralModalAvatar({ show, onClose, img }) {
  if (!show) return null;

  return (
    <div
      style={{ background: "rgba(13, 34, 68, 0.90)" }}
      className="central-modal-overlay"
    >
      <div
        className="central-modal ModalAvatar" 
        style={{
          height: 692,
          width: 692,
          display: "flex",
          flexDirection: "column",
          padding: 0,
          position: "relative",
        }}
      >
        <img 
          className="ModalAvatarImg"
          content="center"
          alt=""
          style={{
            flex: 1,
            borderRadius: 8,
            boxShadow: "1px 1px 4px  4px rgba(0, 0, 0, 0.07)",
          }}
          src={img}
        />

        <div 
          className="ModalAvatarCloseBtn"
          style={{ position: "absolute", top: 24, right: 24 }}
          onClick={onClose}
        >
          <HoverBGButton
            backgroundHover={getComputedStyle(
              document.documentElement
            ).getPropertyValue("--white")}
            icon={<CloseFilled />}
            size={48}
          />
        </div>
      </div>
    </div>
  );
}
