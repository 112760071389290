import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./ForgotPassword.css";
import Footer from "../../Component/FooterNew/FooterNew";
import Text from "../../Component/Common/Text";
import ButtonPrimary from "../../Component/Common/Buttons/ButtonPrimary/ButtonPrimary";
import InputField from "../../Component/Common/InputFields/InputFieldPrimary/InputFieldPrimary";
import useEmailVerification from "../../hooks/common/useEmailverification";
import { ReactComponent as Icon } from "../../assets/svgs/Icon.svg";
import Banner from "../../Component/Common/Banner/Banner";
import { RequestOTP } from "../../services/api";
import { SHOW_ACTIVITY_INDICATOR } from "../../actions";

function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showBanner, setShowBanner] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isError, setIsError] = useState(false);
  const { isVerified, verifyEmail } = useEmailVerification();

  const ErrorCheck = (type, value, onSubmit) => {
    setIsError(false);
    if (type === "email") {
      if ((!isVerified && value !== "") || onSubmit == true) {
        setEmailError("Email address entered is not valid.");
      } else {
        setEmailError("");
      }
    }
  };

  const onSubmitEmail = async () => {
    if (email.trim() !== "") {
      setIsError(false);
      const params = {
        emailAddress: email.trimEnd(),
      };
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
      const result = await RequestOTP(params);
      if (result?.status) {
        navigate("/CodeSubmit", {
          state: {
            emailAddress: email,
          },
        });
      } else {
        setIsError(true);
        setShowBanner(true);
      }
    } else {
      if (email.trim() === "") {
        setEmailError("Email address required.");
        setIsError(true);
      }
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  return (
    <div className="page-wrapper-fp">
      <div className="login-box-container-fp">
        <Banner
          text={"There’s an issue with your email address"}
          headingText={"OTP send failed"}
          showBanner={showBanner}
          type={"Error"}
          withIcon={true}
          withClose={true}
          onClose={() => setShowBanner(false)}
          position={"top"}
        />
        <div className="login-inner-box-container-fp">
        <div className="generic-common-row-centerd-div">
          <Icon height={64} width={64}/>
          <Text variant="b500" marginLeft={8}>
          Box Office
              </Text>
          </div>
          <div className="topLogin-text">
            <div className="topLogin-tittle-text">
              <Text
                textAlign={"center"}
                variant="m700"
                marginTop={16}
                marginBottom={24}
              >
                Forgotten password?
              </Text>
            </div>
            <div className="bottomLogin-prg-text">
              <Text textAlign={"center"} variant="r300" marginBottom={32}>
                A reset code will be sent to the email address entered below.
              </Text>
            </div>
          </div>

          <div className="innerForm-account">
            <label>
              <Text
                alignSelf={"start"}
                labelclass="login-form-label"
                variant="m300"
                marginTop={24}
                marginBottom={8}
              >
                Email address
              </Text>
            </label>
            <InputField
              inputValue={email}
              placeholder={"Enter email address"}
              secureTextEntry={false}
              editable={true}
              type={"email"}
              isError={isError}
              onChangeValue={(text) => (
                setIsError(false), setEmail(text), verifyEmail(text)
              )}
              onBlur={(value) => ErrorCheck("email", value)}
              assistive={emailError}
            />

            <div className="login-sign-in-up-container">
              <div className="bottomLogin-btn">
                <ButtonPrimary
                  width={360}
                  label={"Send code"}
                  onPress={() => onSubmitEmail()}
                />
              </div>

              <div className="backTo-signIn">
                <Text cursor={"pointer"}
                  onClick={() => navigate(-1)}
                  color={"var(--midBlue)"}
                  variant="m300"
                  marginTop={30}
                >
                  Back to Sign in
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ForgotPassword;
