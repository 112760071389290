import React, { useState, useRef } from "react";
import "./Overview.css";
import { useSelector } from "react-redux";
import { FileDrop } from "react-file-drop";
import { ReactComponent as GalleryAddFilled } from "../../assets/svgs/GalleryAddFilled.svg";
import { ReactComponent as BinFilled } from "../../assets/svgs/BinFilled.svg";
import HoverBGButton from "../../Component/Common/Buttons/HoverBGButton/HoverBGButton";
import DropdownInput from "../../Component/Common/DropdownInput/DropdownInput";
import InputField from "../../Component/Common/InputFields/InputFieldPrimary/InputFieldPrimary";
import ButtonPrimary from "../../Component/Common/Buttons/ButtonPrimary/ButtonPrimary";
import Text from "../../Component/Common/Text";
import { set } from "lodash";

export default function Overview({
  onBrandNameChange,
  onBrandEmailChange,
  onBrandMobileCodeChange,
  onBrandMobileNumberChange,
  onBrandUrlChange,
  onBrandImageChange,
  onVatNumberChange,
  brandName,
  brandEmail,
  brandMobileCode,
  brandMobileNumber,
  brandUrl,
  brandImage,
  vatNumber,
  businessNameError,
  businessEmailError,
  businessCountryError,
  businessWebsiteError,
  businessLogoError,
  headingTitle,
  onBlurInput1,
}) {
  const countryList = useSelector((state) => state.countryData?.countryData);
  const hiddenFileInput = useRef(null);
  const dragInputRef = useRef(null);
  const [country, setCountry] = useState("United Kingdom");
  const [dialingCode, setDialingCode] = useState("+44");
  const [shouldShowVAT, setShouldShowVAT] = useState(false);

  const onDragInputChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      onBrandImageChange(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    convertAndSetLogo(event, fileUploaded);
    if (event.target.files && event.target.files[0]) {
      onBrandImageChange(URL.createObjectURL(event.target.files[0]));
    }
  };

  const convertAndSetLogo = (e, file) => {
    // e.preventDefault();
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
    };
    reader.readAsDataURL(file);
  };

  const onTargetClick = () => {
    dragInputRef.current.click();
  };

  const onCountryChange = (selectedCountryName) => {
    setCountry(selectedCountryName);
    const foundCountry = countryList.find(
      (country) => country.name === selectedCountryName
    );
    if (foundCountry) {
      onBrandMobileCodeChange(foundCountry.id);
      setShouldShowVAT(foundCountry?.isVatRequired);
      setDialingCode(`+${foundCountry?.dialingCode}`);
    } else {
      onBrandMobileCodeChange(null);
      setShouldShowVAT(false);
      setDialingCode("");
    }
  };

  return (
    <div className="event-about-div-bs">
      <div className="generic-common-row-div-space-between">
        <div className="generic-common-row-div">
          <Text marginRight={16} variant={"m500"}>
            {headingTitle ? headingTitle : "About"}
          </Text>
        </div>
      </div>

      <div
        className="about-blockBusinessSignUp-innerContent"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          class="event-form-div"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 40,
            flex: 1 / 2,
            paddingRight: 32,
          }}
        >
          <Text variant="m300" marginBottom={8}>
            {"Brand/Business name*"}
          </Text>

          <InputField capitalizeWords={true}
            verifyNotNeed={true}
            editable={true}
            inputValue={brandName}
            placeholder={"Enter Brand/Business name"}
            secureTextEntry={false}
            type={"genericInput"}
            withOutIcon={true}
            isError={false}
            onChangeValue={(text) => onBrandNameChange(text)}
            onBlur={(value) => {}}
            descriptive={"This will display on your public promoter profile."}
            assistive={businessNameError}
          />

          <Text variant="m300" marginBottom={8} marginTop={28}>
            {"Business email address (optional)"}
          </Text>

          <InputField
            verifyNotNeed={true}
            editable={true}
            inputValue={brandEmail}
            placeholder={"Enter email address"}
            secureTextEntry={false}
            type={"email"}
            withOutIcon={true}
            isError={false}
            onChangeValue={(text) => onBrandEmailChange(text)}
            onBlur={(value) => onBlurInput1("email", value)}
            descriptive={"This will display on your public promoter profile."}
            assistive={businessEmailError}
          />

          <Text variant="m300" marginBottom={8} marginTop={28}>
            Country*
          </Text>

          <DropdownInput
            type={"country"}
            eventCategory={country}
            data={countryList}
            onSelectedItem={(value) => onCountryChange(value)}
            editable={true}
          />

          <Text
            marginTop={8}
            variant={"label"}
            color={businessCountryError ? "var(--red)" : "var(--grey)"}
          >
            {businessCountryError
              ? businessCountryError
              : "This will display on your public promoter profile."}
          </Text>

          <Text variant="m300" marginBottom={8} marginTop={28}>
            {"Business contact number (optional)"}
          </Text>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div style={{ width: 77, marginRight: 4 }}>
              <InputField
                maxLength={4}
                verifyNotNeed={true}
                editable={false}
                inputValue={dialingCode}
                placeholder={"+44"}
                secureTextEntry={false}
                type={"email"}
                withOutIcon={true}
                isError={false}
                onChangeValue={(text) => onBrandMobileCodeChange(text)}
                onBlur={(value) => {}}
                //descriptive={"This will display on your public promoter profile."}
              />
            </div>
            <div style={{ width: "100%" }}>
              <InputField
                maxLength={12}
                isNumeric={true}
                verifyNotNeed={true}
                editable={true}
                inputValue={brandMobileNumber}
                placeholder={"Enter contact number"}
                secureTextEntry={false}
                type={"email"}
                withOutIcon={true}
                isError={false}
                onChangeValue={(text) => onBrandMobileNumberChange(text)}
                onBlur={(value) => onBlurInput1("phone", value)}
                //descriptive={"This will display on your public promoter profile."}
              />
            </div>
          </div>
          <Text marginTop={8} variant={"label"} color={"var(--grey)"}>
            {"This will display on your public promoter profile."}
          </Text>

          <Text variant="m300" marginBottom={8} marginTop={28}>
            {"Website address (optional)"}
          </Text>

          <InputField
            verifyNotNeed={true}
            editable={true}
            inputValue={brandUrl}
            placeholder={"Enter URL"}
            secureTextEntry={false}
            type={"genericInput"}
            withOutIcon={true}
            isError={false}
            onChangeValue={(text) => onBrandUrlChange(text)}
            onBlur={(value) => onBlurInput1("website", value)}
            assistive={businessWebsiteError}
            descriptive={"This will display on your public promoter profile."}
          />
{shouldShowVAT && <div className="generic-common-column-div">
          <Text variant="m300" marginBottom={8} marginTop={28}>
            {"VAT Number (optional)"}
          </Text>

          <InputField
            verifyNotNeed={true}
            editable={true}
            inputValue={vatNumber}
            placeholder={"Enter VAT number"}
            secureTextEntry={false}
            type={"genericInput"}
            withOutIcon={true}
            isError={false}
            onChangeValue={(text) => onVatNumberChange(text)}
            onBlur={(value) => onBlurInput1("vat", value)}
            assistive={businessWebsiteError}
            descriptive={"This will display on your public promoter profile."}
            />
            </div> }             
        </div>

        <div
          class="abcd-div"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 40,
            flex: 1 / 2,
            paddingLeft: 32,
            // marginBottom: 15,
          }}
        >
          <Text variant="m300" marginBottom={8}>
            Profile image*
          </Text>
          {brandImage === "" ? (
            <FileDrop
              onDrop={(files, event) => (
                event.preventDefault(),
                onBrandImageChange(URL.createObjectURL(files[0]))
              )}
            >
              <input
                onChange={onDragInputChange}
                ref={dragInputRef}
                type="file"
                className="hidden"
              />
              <div className="banner-container-overview">
                <HoverBGButton
                  icon={<GalleryAddFilled height={24} width={24} />}
                  size={48}
                />
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "start",
                    alignItems: "start",
                    paddingLeft: 21,
                    paddingRight: 16,
                    marginTop: 8,
                  }}
                >
                  <Text
                    cursor={"pointer"}
                    onClick={() => hiddenFileInput.current.click()}
                    textAlign={"center"}
                    width={"100%"}
                    underline={true}
                    variant="m300"
                    color={getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--midBlue")}
                  >
                    Click to upload
                  </Text>
                  <Text textAlign={"start"} width={"55%"} variant="m300">
                    or drag
                  </Text>
                </div>
                <Text variant="m300">and drop</Text>
                <Text
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--grey")}
                  variant="r200"
                  marginTop={16}
                >
                  JPG (max. 600 x 600px)
                </Text>

                <input
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, image/webp"
                  onChange={handleChange}
                  ref={hiddenFileInput}
                  style={{ display: "none" }}
                />
              </div>
            </FileDrop>
          ) : (
            <div className="banner-container-img-bs">
              <img
                content="center"
                alt=""
                style={{
                  height: "100%",
                  width: "100%",
                  borderRadius: 4,
                  alignSelf: "stretch",
                  objectFit: "cover",
                }}
                src={brandImage}
              />
              <div style={{ position: "absolute", right: 14, marginTop: 16 }}>
                <HoverBGButton
                  icon={
                    <BinFilled
                      fill={getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--midBlue")}
                      height={16}
                      width={16}
                    />
                  }
                  size={32}
                  backgroundHover={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--white")}
                  onPress={() => onBrandImageChange("")}
                />
              </div>
            </div>
          )}
          <Text marginTop={8} variant={"label"} color={"var(--red)"}>
            {businessLogoError}
          </Text>
        </div>
      </div>
    </div>
  );
}
