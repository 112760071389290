import { SELECTED_WALLET_INDEX } from '../actions';

export default function walletIndex(state = null, action = {}) {
  switch (action.type) {
    case SELECTED_WALLET_INDEX: {
      return {
        ...state,
        walletIndex: action.payload,
      };
    }

    default:
      return state;
  }
}
