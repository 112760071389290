import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./BusinessSignUp.css";
import { ReactComponent as BusinessSignUpMaskLeft } from "../../assets/svgs/BusinessSignUpMaskLeft.svg";
import { ReactComponent as BusinessSignUpMaskRight } from "../../assets/svgs/BusinessSignUpMaskRight.svg";
import { ReactComponent as BusinessSignUpMaskMiddle } from "../../assets/svgs/BusinessSignUpMaskMiddle.svg";
import { ReactComponent as VerifiedWhiteFilled } from "../../assets/svgs/VerifiedWhiteFilled.svg";
import Footer from "../../Component/FooterNew/FooterNew";
import Text from "../../Component/Common/Text";
import HoverBGButton from "../../Component/Common/Buttons/HoverBGButton/HoverBGButton";
import ButtonPrimary from "../../Component/Common/Buttons/ButtonPrimary/ButtonPrimary";
import InputField from "../../Component/Common/InputFields/InputFieldPrimary/InputFieldPrimary";
import useEmailVerification from "../../hooks/common/useEmailverification";
import {
    sendVerificationEmail
  } from "../../services/api";
  import {
    BANNER_CONTENT,
    SHOW_ACTIVITY_INDICATOR,
  } from "../../actions";

function UpdateEmailVerifyEmail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [brandEmail, setBrandEmail] = useState("");
  const { isVerified, verifyEmail } = useEmailVerification();
  const [isError, setIsError] = useState(false);
  const [emailError, setEmailError] = useState("");

  const handleButtonClick = () => {
    // Open the default email client with a blank compose window
    window.location.href = "mailto:";
  };

  const ErrorCheck = (type, value, onSubmit) => {
    setIsError(false);
    if (type === "email") {
      if ((!isVerified && value !== "") || onSubmit == true) {
        setEmailError("Email address entered is not valid.");
      } else {
        setEmailError("");
      }
    }
  };

  const sendVerificationEmailRequest = async () => {
    if (brandEmail?.trim() !== "") {
        dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
        const result = await sendVerificationEmail({ email: brandEmail });
        if (result?.status) {
            dispatch({
                type: BANNER_CONTENT,
                payload: {
                  text: "Successfully sent verification email.",
                  headingText: "Verification email sent...",
                  showBanner: true,
                  type: "Success",
                  withClose: true,
                  withIcon: true,
                  position: "top",
                  paddingLeft: "8px",
            parentContainerStyle: {
              top: "8px",
               zIndex: 15,
            },
                },
              });
          } else {
            dispatch({
                type: BANNER_CONTENT,
                payload: {
                  text: result?.response || "Error sending verification email",
                  headingText: "Error sending verification email",
                  showBanner: true,
                  type: "Error",
                  withClose: true,
                  withIcon: true,
                  position: "top",
                  paddingLeft: "8px",
                  parentContainerStyle: {
                    top: "8px",
                     zIndex: 15,
                  },
                },
              });
          }
          dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });

    }
    else
    {
        if (brandEmail.trim() === "") {
            setEmailError("Email address required.");
            setIsError(true);
          } 

         
    }
  }

  return (
    <div className="business-signup-page-wrapper">

      <div className="business-signup-inner-container">
        <BusinessSignUpMaskMiddle
          height={150}
          style={{ position: "absolute", top: 0, zIndex: 3 }}
          className="businessSignUp-top-bg"
        />
        <BusinessSignUpMaskLeft
          style={{ position: "absolute", left: 0, top: 0, zIndex: 2 }}
          className="businessSignUp-top-bg2"
        />
        <BusinessSignUpMaskRight
          style={{ position: "absolute", right: 0, top: 0, zIndex: 2 }}
          className="businessSignUp-top-bg3"
        />
        <div className="businessSignUp-top-plusIcon">
          <HoverBGButton
            style={{
              borderWidth: 6,
              borderColor: "var(--veryLightGrey)",
              zIndex: 7,
            }}
            withBorder={true}
            icon={<VerifiedWhiteFilled />}
            size={120}
          />
        </div>
        <div className="tittle">
          <Text
            textAlign={"center"}
            variant="m700"
            marginTop={30}
            marginBottom={24}
          >
            Verify your account
          </Text>
        </div>

        <div style={{marginTop: 40}} className="subTittle">
          <Text textAlign={"center"} variant="r300">
          {'We’ve sent an email to '}
      <span style={{ color: 'var(--midBlue)' }}>{localStorage.getItem("emailAddress")}</span>
      {'. Please click the link inside to verify your account and proceed with creating your event.'}
          </Text>
        </div>

        
              

        <div style={{alignItems: 'center',  display: 'flex', flexDirection: 'column', marginTop: 32}}  className="saveCont-btn-BusinessSignUp1">

        <Text
                alignSelf={"start"}
                labelclass="login-form-label"
                variant="m300"
                marginBottom={8}
              >
                Box office registration email address
              </Text>
            

            <InputField withOutIcon={true} style={{ width: 350 }}
              inputValue={brandEmail}
              placeholder={"Enter email address"}
              secureTextEntry={false}
              editable={true}
              type={"email"}
              isError={false}
              onChangeValue={(text) => (
                setIsError(false), setBrandEmail(text), verifyEmail(text)
              )}
              onBlur={(value) => ErrorCheck("email", value)}
              assistive={emailError}
              descriptive={'This will display on your public promoter profile.'}
            />

          <ButtonPrimary
            style={{  marginTop: 40, marginBottom: '0px !important' }}
            width={328}
            label={'Update and resend verification'}
            // onPress={() => {navigate("/Login"); handleButtonClick()}}
            onPress={() => sendVerificationEmailRequest()}
          />
          <Text cursor={"pointer"}
                  onClick={() => navigate('/Login')}
                  color={"var(--midBlue)"}
                  variant="m300"
                  marginTop={24}
                  marginBottom={64}
                >
                  Go back
                </Text>
        </div>

        
      </div>
      <Footer />
    </div>
  );
}

export default UpdateEmailVerifyEmail;
