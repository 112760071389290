import { SHOW_DRAFT } from "../actions";

export default function showDraftEvent(state = null, action = {}) {
  switch (action.type) {
    case SHOW_DRAFT: {
      return {
        ...state,
        showDraftEvent: action.payload,
      };
    }

    default:
      return state;
  }
}
