import React, { useState } from "react";
import textVariants from "../../foundation/textVariants";

const Text = ({
  variant,
  children,
  color,
  onClick,
  display,
  // fontSize,
  marginBottom,
  marginTop,
  width,
  marginLeft,
  marginRight,
  textAlign,
  alignSelf,
  cursor,
  textWrap,
  style,
  labelclass,
  textclass,
  ellclass,
  underline,
  showUnderlineOnHover,
  showBullet,
  hasHtml = false,
  newLineData
}) => {
  const [isHovered, setIsHovered] = useState(false);

  let textStyle = textVariants[variant] || textVariants.body;
  textStyle = {
    ...textStyle,
    color: color || "var(--midnightBlue)",
    display: display || null,
    // fontSize: fontSize || null,
    marginBottom: marginBottom || 0,
    marginTop: marginTop || 0,
    width: width || "auto",
    marginLeft: marginLeft || 0,
    marginRight: marginRight || 0,
    cursor: cursor ? cursor : "default",
    textAlign: textAlign ? textAlign : "left",
    alignSelf: alignSelf ? alignSelf : "auto",
    textWrap: textWrap ? "nowrap" : "wrap",
    textDecoration: underline || isHovered ? "underline" : "none",
    content: '•',
    ...style,
  };
  const bulletStyle = {
    display: 'inline-block',
    marginRight: '8px',
  };
  

  return (
    !hasHtml ? <span
      onMouseEnter={() => showUnderlineOnHover && setIsHovered(true)}
      onMouseLeave={() => showUnderlineOnHover && setIsHovered(false)}
      className={(labelclass, textclass, ellclass)}
      onClick={onClick}
      style={textStyle}
    >
      {showBullet && <span style={bulletStyle}>•</span>}
      {/* {children} */}
      {newLineData ? newLineData?.map((line, index) => (        
        <React.Fragment key={index}>
          {line}
          {index < newLineData.length - 1 && <br />}
        </React.Fragment>
      )) : children}
    </span> :
    <span
    onMouseEnter={() => showUnderlineOnHover && setIsHovered(true)}
    onMouseLeave={() => showUnderlineOnHover && setIsHovered(false)}
    className={(labelclass, textclass, ellclass)}
    onClick={onClick}
    style={textStyle}
    dangerouslySetInnerHTML={{ __html: children }}
  />
  );
};

{/* <div
                                    key={pIndex}
                                    style={{
                                      width: '100%',             // Full width of container
                                      marginBottom: '1em',       // Space between paragraphs
                                      whiteSpace: 'pre-wrap',    // Preserves line breaks and spaces
                                      wordBreak: 'break-word',
                                      marginBottom: 48
                                         // Breaks long words to prevent overflow
                                    }}
                                    dangerouslySetInnerHTML={{ __html: text}}
                                  /> */}

export default Text;
