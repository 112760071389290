import React, { useState, useEffect } from "react";
import "./OrderList.css";
import { debounce } from "lodash";
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as ChevronRight } from "../../assets/svgs/ChevronRight.svg";
import Tags from "../../Component/Common/Tags/Tags";
import Text from "../../Component/Common/Text";
import Footer from "../../Component/FooterNew/FooterNew";
import SideBar from "../../Component/SideBar/SideBar";
import MenuLink from "../../Component/Common/MenuLink/MenuLink";
import ButtonPill from "../../Component/Common/Buttons/ButtonPill";
import SearchInputField from "../../Component/Common/InputFields/SearchInputField/SearchInputField";
import DropdownInput from "../../Component/Common/DropdownInput/DropdownInput";
import {
  GetOrderPaymentStatuses,
  GetEventTicketTypes,
  getOrderList,
  getOrderListV2,
  getEventOrderToCsv,
} from "../../services/api";
import { SELECTED_EVENT_CATEGORY_DATA } from "../../actions";
import ButtonPrimary from "../../Component/Common/Buttons/ButtonPrimary/ButtonPrimary";

function OrderList() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state } = location;
  let eventId = state?.eventId;
  if (!eventId) {
    const parts = window.location.pathname.split("/");
    eventId = parts[parts.indexOf("EventDetails") + 1];
  }
  const [item, setItem] = useState(state?.event || false);
  const currencyList = useSelector((state) => state.currencyData?.currencyData);
  const [selectedEventOptionIndex, setSelectedEventOptionIndex] = useState(0);
  const [selectedEntrance, setSelectedEntrance] = useState("All dates");
  const [selectedEntranceValue, setSelectedEntranceValue] = useState(undefined);
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [selectedPaymentStatus, setSelectedPaymentStatus] =
    useState("All statuses");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTicketType, setSelectedTicketType] =
    useState("All ticket types");
  const [searchQuery, setSearchQuery] = useState("");
  const [enteredList, setEnteredList] = useState([]);
  const [paymentStatusList, setPaymentStatusList] = useState([
    { name: "All statuses", id: undefined },
  ]);
  const [ticketTypesList, setTicketTypesList] = useState([
    { name: "All ticket types", id: undefined },
  ]);

  const eventSectionOptions = [
    { name: "Overview", id: 1 },
    { name: "Event details", id: 2 },
    { name: "Venue", id: 3 },
    { name: "Requirements", id: 4 },
    { name: "Fees", id: 5 },
    { name: "Tickets", id: 6 },
    { name: "Add Ons", id: 7 },
    { name: "Guest list", id: 8 },
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const lineItemTitle = [
    "Order number",
    "Status",
    "Date",
    "Items",
    "Total",
    "",
  ];

  const [currPage, setCurrPage] = useState(0);
  const [isEndThresholdReached, setIsEndThresholdReached] = useState(false);

  useEffect(() => {
    getOrderPaymentStatusesRequest();
    getEventTicketTypesRequest();
  }, []);

  useEffect(() => {
    console.log("selectedPaymentStatus", selectedPaymentStatus);
    console.log("selectedTicketType", selectedTicketType);
    console.log("selectedEntranceValue", selectedEntranceValue);
    console.log("searchQuery", searchQuery);
    setCurrPage(0);
    if (searchQuery !== "" ) {
      debouncedGetOrderList();
    } else {
      getOrderListRequest();
    }

    return () => {
      debouncedGetOrderList.cancel();
    };
  }, [
    selectedPaymentStatus,
    selectedTicketType,
    selectedEntranceValue,
    searchQuery,
  ]);

  const getOrderListRequest = async (currentPage) => {
    try {
      const result = await getOrderListV2({
        // const result = await getOrderList({
        eventId: eventId,
        statusIds: paymentStatusList?.find(
          (x) => x.name === selectedPaymentStatus
        )?.id,
        eventTicketTypeIds: ticketTypesList?.find(
          (x) => x.name === selectedTicketType
        )?.id,
        date: selectedEntranceValue,
        searchString: searchQuery,
        pageNumber: currPage,
        pageSize: 10,
      });
      if (result.status) {
        item === false && setItem(result.response?.eventDetails);
        setIsEndThresholdReached(
          result.response?.ordersDetails?.length === 10 ? false : true
        );
        const orderList = [...enteredList, ...result.response?.ordersDetails];
        const sortedOrderList = orderList.sort((a, b) => {
          const dateA = new Date(a.dateCreated);
          const dateB = new Date(b.dateCreated);
          return dateB - dateA;
        });
        setEnteredList(sortedOrderList);
        setCurrPage(currPage + 1);
      } else {
        item === undefined && navigate("/Login");
        console.error("API Call Error: ", result.error);
      }
    } catch (error) {
      console.error("Error fetching entered list: ", error);
    }
  };

  const debouncedGetOrderList = debounce(getOrderListRequest, 300);

  const getOrderPaymentStatusesRequest = async () => {
    const result = await GetOrderPaymentStatuses({});
    if (result.status) {
      setPaymentStatusList([
        ...paymentStatusList,
        ...renameStatuses(result?.response),
      ]);
    }
  };

  function renameStatuses(statuses) {
    return statuses.map((status) => {
      if (status.name === "Refunded") {
        return { ...status, name: "Ticket cancelled" };
      } else if (status.name === "Partial Refund") {
        return { ...status, name: "Partial cancellation" };
      } else if (status.name === "Approved") {
        return { ...status, name: "Completed" };
      } else {
        return status;
      }
    });
  }

  const getEventTicketTypesRequest = async () => {
    const result = await GetEventTicketTypes({ eventId: eventId });
    if (result.status) {
      let tempTicketTypesList = result?.response;
      setTicketTypesList([...ticketTypesList, ...tempTicketTypesList]);
    }
  };

  const segmentBtnPressedEvent = (index) => {
    setSelectedEventOptionIndex(index);
    dispatch({
      type: SELECTED_EVENT_CATEGORY_DATA,
      payload: index,
    });
    navigate("/EventDetails/" + eventId);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 400);
  };

  function getOrdinalSuffix(num) {
    const suffixes = ["th", "st", "nd", "rd"];
    const lastDigit = num % 10;
    const suffix =
      num > 10 && num < 20
        ? suffixes[0]
        : lastDigit <= 3
        ? suffixes[lastDigit]
        : suffixes[0];
    return num + suffix;
  }

  const getOrderToCsvRequest = async () => {
    const params = {
      // orderId: orderId,
      eventId: eventId,
      statusIds: paymentStatusList?.find(
        (x) => x.name === selectedPaymentStatus
      )?.id,
      eventTicketTypeIds: ticketTypesList?.find(
        (x) => x.name === selectedTicketType
      )?.id,
      date: selectedEntranceValue,
      searchString: searchQuery,
      pageNumber: currPage,
      pageSize: 10,
    };
    const result = await getEventOrderToCsv(params);
    if (result?.status) {
      const url = window.URL.createObjectURL(new Blob([result.response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `Order${eventId}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
    }
  };

  const formattedTime = (d) =>
    `${(d.getHours() < 10 ? "0" : "") + d.getHours()}:${
      (d.getMinutes() < 10 ? "0" : "") + d.getMinutes()
    }`;

  // render for list of assets with it's state
  const renderOrderList = ({ items, index }) => {
    return (
      <div
        className="orderList-inner-widthScroll"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="order-list-line-item-container" key={index}>
          <div
            className="order-list-line-item-inner"
            style={{
              width: "50%",
              marginRight: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Text
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/EventDetails/${eventId}/OrderList/OrderDetails/${items?.orderId}`,
                  {
                    state: {
                      orderId: items?.orderId,
                      event: item,
                    },
                  }
                )
              }
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--midnightBlue")}
              variant={"m200"}
            >
              {`#${items?.orderId}`}
            </Text>
            <Text
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(
                  `/EventDetails/${eventId}/OrderList/OrderDetails/${items?.orderId}`,
                  {
                    state: {
                      orderId: items?.orderId,
                      event: item,
                    },
                  }
                )
              }
              marginTop={4}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--grey")}
              variant={"r100"}
            >
              {`${items?.firstName || ""} ${items?.lastName || ""}`}
            </Text>
          </div>
          <div
            className="order-list-line-item-inner"
            style={{
              width: "30%",
              marginRight: 1,
              display: "flex",
            }}
          >
            <ButtonPill
              type={
                items?.paymentStatus === "Approved"
                  ? "completed"
                  : items?.paymentStatus === "PartialRefund"
                  ? "partiallycompleted"
                  : items?.paymentStatus === "Failed" ||
                    items?.paymentStatus === "PaymentExpired"
                  ? "error"
                  : items?.paymentStatus === "Refunded" ||
                    items?.paymentStatus === "NotFound"
                  ? "expired"
                  : "generic"
              }
              varient={"small"}
              label={
                items?.paymentStatus === "Approved"
                  ? "Completed"
                  : items?.paymentStatus === "Pending"
                  ? "Pending payment"
                  : items?.paymentStatus === "NotFound"
                  ? "Not found"
                  : items?.paymentStatus === "PaymentExpired"
                  ? "Payment expired"
                  : items?.paymentStatus === "PartialRefund"
                  ? "Partial cancellation"
                  : items?.paymentStatus === "Failed"
                  ? "Payment failed"
                  : items?.paymentStatus === "Refunded"
                  ? "Ticket cancelled"
                  : items?.paymentStatus
              }
            />
          </div>
          <div
            className="order-list-line-item-inner"
            style={{
              width: "30%",
              marginRight: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Text
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--midnightBlue")}
              variant={"m200"}
            >
              {getOrdinalSuffix(new Date(items?.dateCreated).getDate()) +
                " " +
                months[new Date(items?.dateCreated).getMonth()] +
                " " +
                new Date(items?.dateCreated).getFullYear()}
            </Text>
            <Text
              marginTop={4}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--grey")}
              variant={"r100"}
            >
              {String(formattedTime(new Date(String(items?.dateCreated)))) ||
                "-"}
            </Text>
          </div>
          <div
            className="order-list-line-item-inner"
            style={{
              width: "30%",
              marginRight: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Text
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--midnightBlue")}
              variant={"m200"}
            >
              {items?.orderItems.reduce(
                (accumulator, item) => accumulator + item.quantity,
                0
              ) || "-"}
            </Text>
            <Text
              marginTop={4}
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--grey")}
              variant={"r100"}
            >
              {items?.orderItems
                .map(
                  (item) => `${item.quantity} x ${item.eventTicketType.name}`
                )
                .join(", ") || "-"}
            </Text>
          </div>

          <div
            className="order-list-line-item-inner"
            style={{
              width: "20%",
              marginRight: 1,
            }}
          >
            <Text variant={"m300"}>{`${
              currencyList?.find((x) => x.id === items?.currencyId)?.symbol ||
              currencyList?.find((x) => x.id === items?.currencyId)?.code
            }${items?.orderTotal}`}</Text>
          </div>

          <div
            className="order-list-line-item-inner"
            style={{
              width: "20%",
              justifyContent: "end",
              display: "flex",
              cursor: "pointer",
            }}
            onClick={() =>
              navigate(
                `/EventDetails/${eventId}/OrderList/OrderDetails/${items?.orderId}`,
                {
                  state: {
                    orderId: items?.orderId,
                    event: item,
                  },
                }
              )
            }
          >
            <ChevronRight
              stroke={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--midBlue")}
              width={24}
              height={24}
            />
          </div>
        </div>
        {index !== enteredList.length - 1 && (
          <div
            style={{
              display: "none",
              height: 1,
              width: "100%",
              backgroundColor: getComputedStyle(
                document.documentElement
              ).getPropertyValue("--lightGrey"),
            }}
          />
        )}
      </div>
    );
  };
  return (
    <div className="page-wrapper-attendee">
      <div className="main-body-content">
        <div id="sidebar" className="home-header-sideNav">
          <SideBar
            onChangeOption={(index) =>
              navigate("/Home", {
                state: { selectedIndex: index },
              })
            }
            createEventClicked={() => navigate("/CreateEvent")}
            onGoToEventClicked={(eventId) =>
              navigate(`/EventDetails/${eventId}`, {
                state: {
                  eventId: eventId,
                },
              })
            }
          />
        </div>

        <div className="rht-body-inner-content">
          <div className="order-content-div listing-top-headerPagination">
            <MenuLink
              // btn2Label={"Cancel event"}
              labelArray={[
                { label: "Home", id: 1 },
                { label: item?.name, id: 2 },
              ]}
              withCog={false}
              onMenuLinkPress={(ind) =>
                ind === 0
                  ? navigate("/Home")
                  : ind === 1
                  ? navigate("/EventDetails/" + eventId)
                  : null
              }
            />
            <div
              style={{ paddingTop: 24 }}
              className="event-details-top-after-menulink-container"
            >
              <Text variant={"m600"}>{item?.name}</Text>

              <div className="event-details-event-scroll-container">
                <div className="event-detailsTop-btns-inner">
                  {eventSectionOptions.map((items, ind) => {
                    return (
                      <div key={ind} style={{ marginRight: 8 }}>
                        <Tags
                          disabled={false}
                          paddingVertical={8}
                          label={items.name}
                          selected={
                            ind == selectedEventOptionIndex ? true : false
                          }
                          onChangeSelection={() => segmentBtnPressedEvent(ind)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="listingPagination-block">
              <MenuLink
                style={{
                  paddingTop: 16,
                  paddingBottom: 16,
                  borderBottomColor: getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--lightGrey"),
                  borderBottomWidth: "1px !important",
                  boxShadow: "0.3px 0.3px 0.3px 0.3px rgba(0, 0, 0, 0.05)",
                  marginBottom: 0,
                }}
                labelArray={[
                  { label: "Overview", id: 1 },
                  { label: "Order history", id: 2 },
                ]}
                withCog={false}
                onMenuLinkPress={(ind) =>
                  ind === 0 ? navigate("/EventDetails/" + eventId) : null
                }
              />
            </div>

            <div
              className="order-list-parent-content listing-tittleParg-block"
              style={{ justifyContent: "space-between", marginRight: 24 }}
            >
              <div
                className="OrderListTopTitleButtonCont"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: "1 0 0",
                }}
              >
                <div className="listing-tittle">
                  <Text variant={"m600"}>Order history</Text>
                </div>
                <div className="listing-parg">
                  <Text marginTop={16} variant={"r500"}>
                    Keep track of your ticket sales history and manage ticket
                    cancellations
                  </Text>
                </div>
              </div>
              <div className="btnContEAD forDesktop">
                <ButtonPrimary
                  isClickingEffectEnabled={true}
                  isSecondary={true}
                  label={"Export attendee details"}
                  onPress={() => getOrderToCsvRequest()}
                  width={328}
                  style={{ marginLeft: 24 }}
                />
              </div>
            </div>
            <div className="listingSearch-block">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "45%",
                  marginLeft: 24,
                }}
              >
                <SearchInputField inputStyles={{fontWeight: 400, fontSize: 16}}
                  editable={true}
                  // placeholder={"Search order details"}
                  placeholder={"Search name, email or order #."}
                  onChangeSearchValue={(Event) => {
                    setEnteredList([]);
                    setCurrPage(0);
                    setSearchQuery(Event === "clear" ? "" : Event);
                  }}
                />
              </div>
            </div>

            <div
              className="listing-form-filedStyle"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 32,
                marginLeft: 24,
                marginRight: 24,
              }}
            >
              <div
                className="listing-form-filed-item"
                style={{
                  flex: 1 / 3,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text marginBottom={8} variant={"m300"}>
                  Status
                </Text>
                <DropdownInput
                  eventCategory={selectedPaymentStatus}
                  data={paymentStatusList}
                  onSelectedItem={(value) => {
                  selectedPaymentStatus !== value && setEnteredList([]);
                    setCurrPage(0);
                    setSelectedPaymentStatus(value);
                  }}
                  editable={true}
                />
              </div>
              <div
                className="listing-form-filed-item"
                style={{
                  flex: 1 / 3,
                  marginLeft: 24,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text marginBottom={8} variant={"m300"}>
                  Dates
                </Text>
                <DropdownInput
                  resetRequired={true}
                  showDropdown={isDatePickerVisible}
                  onToggleDropdown={() =>
                    setIsDatePickerVisible(!isDatePickerVisible)
                  }
                  eventCategory={selectedEntrance}
                  data={[]}
                  editable={true}
                  resetBtnClicked={() => {
                    setSelectedDate("");
                    setSelectedEntrance("All dates");
                    setEnteredList([]);
                    setCurrPage(0);
                    setSelectedEntranceValue("");
                    setTimeout(() => {
                      setIsDatePickerVisible(false);
                    }, 100);
                  }}
                  customRender={() => {
                    return (
                      <div style={{ marginBottom: -10 }}>
                        <DatePicker
                          selected={selectedDate}
                          shouldCloseOnSelect={true}
                          onChange={(update) => {
                            setSelectedDate(update);
                            setSelectedEntrance(
                              `${new Date(update).getDate()}/${
                                new Date(update).getMonth() + 1
                              }/${new Date(update).getFullYear()}`
                            );
                            setEnteredList([]);
                            setCurrPage(0);
                            setSelectedEntranceValue(
                              update.toLocaleString("en-US", {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric",
                              })
                            );
                            setIsDatePickerVisible(!isDatePickerVisible);
                          }}
                          startDate={new Date()}
                          inline
                        />
                      </div>
                    );
                  }}
                />
              </div>
              <div
                className="listing-form-filed-item"
                style={{
                  flex: 1 / 3,
                  marginLeft: 24,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text marginBottom={8} variant={"m300"}>
                  Ticket types
                </Text>
                <DropdownInput
                  eventCategory={selectedTicketType}
                  data={ticketTypesList}
                  onSelectedItem={(value) => {
                  selectedTicketType !== value && setEnteredList([]);
                    setCurrPage(0);
                    setSelectedTicketType(value);
                  }}
                  editable={true}
                />
              </div>
            </div>

            <div className="btnContEADmobile forMobile">
              <ButtonPrimary
                isClickingEffectEnabled={true}
                isSecondary={true}
                label={"Export attendee details"}
                onPress={() => getOrderToCsvRequest()}
                width={328}
                style={{ marginLeft: 24 }}
              />
            </div>

            <div
              style={{ marginLeft: 24, marginRight: 24 }}
              className="orderHistroy-list-container"
            >
              <div className="orderHistroy-list-container-inner">
                <div className="order-list-line-item-title-container">
                  <div className="order-list-line-item-tittleInner">
                    {lineItemTitle.map((items, ind) => {
                      return (
                        <div
                          style={{
                            width:
                              ind === 0
                                ? "50%"
                                : ind === 1 || ind === 2 || ind === 3
                                ? "28%"
                                : "20%",
                            marginRight: 1,
                          }}
                        >
                          <Text variant={"m300"}>{items}</Text>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div
                  style={{ overflowY: "hidden", marginRight: 0 }}
                  className="order-list-item-parent-content"
                >
                  <InfiniteScroll
                    dataLength={enteredList.length}
                    next={() => {
                      setTimeout(() => {
                        getOrderListRequest();
                      }, 1000);
                    }}
                    hasMore={!isEndThresholdReached}
                    loader={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "70px",
                        }}
                        className="d-flex justify-content-center"
                      >
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    }
                  >
                    {enteredList.length > 0 ? (
                      enteredList.map((items, index) => {
                        return renderOrderList({ items, index });
                      })
                    ) : (
                      <div
                        style={{
                          color: "var(--grey)",
                          textAlign: "center",
                          marginTop: "64px",
                          marginBottom: "64px",
                        }}
                      >
                        No tickets have been purchased for this event.
                      </div>
                    )}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginLeft: "-380" }}>
        <Footer />
      </div>
    </div>
  );
}

export default OrderList;
