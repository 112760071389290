import React from "react";
import "./CentralModal.css";
import Text from "../Text";
import { ReactComponent as CloseFilled } from "../../../assets/svgs/CloseFilled.svg";

export default function CentralCustomModal({
  show,
  onClose,
  renderContent,
  title,
  isDynamicSize,
  showDividerTop,
  styles,
  type,
  titleVariant,
}) {
  if (!show) return null;

  return (
    <div className={`${title === 'Resize Poster' ? 'resize-poster-overlay ' : ''}central-modal-overlay`}>      <div
        style={{
          flex: showDividerTop ? "none !important" : 1,
          width: showDividerTop ? "692px" : null,
          ...styles,
        }}
        className={
          isDynamicSize
            ? "central-modal-dynamic-size"
            : type === "invoiceHistory"
            ? "central-modal-custom central-modal-invoice-history"
            : type === "addPaymentMethod"
            ? "central-modal-custom central-modal-add-payment-method"
            : "central-modal-custom"
        }
      >
        <div className="generic-common-row-div-space-between modal-add-payment-method-title" onClick={onClose}>
          <Text variant={titleVariant ? titleVariant : "m600"}>{title}</Text>
          <CloseFilled style={{cursor: 'pointer'}}/>
        </div>
        {showDividerTop && (
          <div
            style={{
              marginTop: 24,
              marginBottom: type === 'disconnectMailChimp' ? 0 : 32,
              borderBottom: "1px solid var(--lightGrey)",
              marginLeft: -24,
              marginRight: -24,
            }}
          />
        )}
        <div style={{overflow: type === "addPaymentMethod" ? 'hidden' : 'auto'}} className="central-modal-content">
          {renderContent && renderContent()}
        </div>
      </div>
    </div>
  );
}
