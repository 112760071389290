import React, { useState, useEffect } from "react";
import "./AttendeeList.css";
import "../OrderHistory/OrderList.css";
import { debounce } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ReactComponent as SuccessFilled } from "../../assets/svgs/SuccessFilled.svg";
import { ReactComponent as SuccessFilledWhite } from "../../assets/svgs/SuccessFilledWhite.svg";
import { ReactComponent as CloseFilled } from "../../assets/svgs/CloseFilled.svg";
import { ReactComponent as CloseFilledWhite } from "../../assets/svgs/CloseFilledWhite.svg";
import { ReactComponent as ClockFilled } from "../../assets/svgs/ClockFilled.svg";
import { ReactComponent as ClockFilledWhite } from "../../assets/svgs/ClockFilledWhite.svg";
import { ReactComponent as RecordCircle } from "../../assets/svgs/RecordCircle.svg";
import { ReactComponent as ChevronRight } from "../../assets/svgs/ChevronRight.svg";
import { ReactComponent as WarningFilled } from "../../assets/svgs/WarningFilled.svg";
import { ReactComponent as MinusFilled } from "../../assets/svgs/MinusFilled.svg";
import { ReactComponent as UserDefault } from "../../assets/svgs/UserFilled.svg";
import { ReactComponent as VerifiedWhiteFilled } from "../../assets/svgs/VerifiedWhiteFilled.svg";
import HoverBGButton from "../../Component/Common/Buttons/HoverBGButton/HoverBGButton";
import Tags from "../../Component/Common/Tags/Tags";
import Text from "../../Component/Common/Text";
import Footer from "../../Component/FooterNew/FooterNew";
import SideBar from "../../Component/SideBar/SideBar";
import MenuLink from "../../Component/Common/MenuLink/MenuLink";
import SearchInputField from "../../Component/Common/InputFields/SearchInputField/SearchInputField";
import DropdownInput from "../../Component/Common/DropdownInput/DropdownInput";
import {
  getEnteredListWithPagination,
  getEnteredList,
  getEventStatistics,
} from "../../services/api";
import {
  SELECTED_EVENT_CATEGORY_DATA,
  SHOW_ACTIVITY_INDICATOR,
} from "../../actions";

function AttendeeList() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  let eventId = state?.eventId;
  if (!eventId) {
    const parts = window.location.pathname.split("/");
    eventId = parts[parts.indexOf("EventDetails") + 1];
  }
  const dispatch = useDispatch();
  const [item, setItem] = useState(state?.event || false);
  const [selectedEventOptionIndex, setSelectedEventOptionIndex] = useState(0);
  const [selectedAttendeeOptionIndex, setSelectedAttendeeOptionIndex] =
    useState(0);
  const [selectedKycOptionIndex, setSelectedKycOptionIndex] = useState(0);
  const [selectedEntrance, setSelectedEntrance] = useState("All Entrances");
  const [isEntered, setIsEntered] = useState(false);
  const [isCheckedOut, setIsCheckedOut] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [isNotEntered, setIsNotEntered] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [enteredList, setEnteredList] = useState([]);
  const [entryStatusArr, setEntryStatusArr] = useState([]);
  const [attendeeOptions, setAttendeeOptions] = useState([
    { name: "All", id: 1, icon: null, isSelected: true },
    { name: "Approved", id: 2, icon: SuccessFilled, isSelected: false },
    { name: "Rejected", id: 3, icon: CloseFilled, isSelected: false },
    { name: "Checked out", id: 5, icon: ClockFilled, isSelected: false },
    { name: "Not entered", id: 5, icon: MinusFilled, isSelected: false },
  ]);

  const [kycOptions, setKycOptions] = useState([
    { name: "All", id: 1, icon: null, isSelected: true },
    { name: "Verified", id: 2, isSelected: false },
    { name: "Not verified", id: 3, isSelected: false },
  ]);

  const [venueEntrances, setVenueEntrances] = useState([
    { name: "All Entrances", id: undefined },
  ]);

  const [eventStatisticsObj, setEventStatisticsObj] = useState({});
  const [currPage, setCurrPage] = useState(0);
  const [isEndThresholdReached, setIsEndThresholdReached] = useState(false);

  const [progressMenuOptions, setProgressMenuOptions] = useState([
    {
      icon: <SuccessFilled fill="var(--white)" height={24} width={24} />,
    },
    {
      icon: <CloseFilled fill="var(--white)" height={24} width={24} />,
    },
    {
      icon: <ClockFilled fill="var(--white)" height={24} width={24} />,
    },
    {
      icon: <MinusFilled height={24} width={24} />,
    },
  ]);

  const eventSectionOptions = [
    { name: "Overview", id: 1 },
    { name: "Event details", id: 2 },
    { name: "Venue", id: 3 },
    { name: "Requirements", id: 4 },
    { name: "Fees", id: 5 },
    { name: "Tickets", id: 6 },
    { name: "Add Ons", id: 7 },
    { name: "Guest list", id: 8 },
  ];

  useEffect(() => {
    setIsEntered(
      attendeeOptions.length === 5
        ? attendeeOptions[1]?.isSelected
        : attendeeOptions[0]?.isSelected
    );
    setIsRejected(
      attendeeOptions.length === 5
        ? attendeeOptions[2]?.isSelected
        : attendeeOptions[1]?.isSelected
    );
    setIsCheckedOut(
      attendeeOptions.length === 5
        ? attendeeOptions[3]?.isSelected
        : attendeeOptions[2]?.isSelected
    );
    setIsNotEntered(
      attendeeOptions.length === 5
        ? attendeeOptions[4]?.isSelected
        : attendeeOptions[3]?.isSelected
    );
  }, [selectedAttendeeOptionIndex, attendeeOptions]);

  useEffect(() => {
    getEventStatisticsRequest(eventId);
  }, []);

  useEffect(() => {
    var entryStatusArray = [];
    isEntered
      ? entryStatusArray.push("CheckedIn")
      : entryStatusArray?.filter((item) => item !== "CheckedIn");
    isRejected
      ? entryStatusArray.push("Rejected")
      : entryStatusArray?.filter((item) => item !== "Rejected");

    isCheckedOut
      ? entryStatusArray.push("CheckedOut")
      : entryStatusArray?.filter((item) => item !== "CheckedOut");

    isNotEntered
      ? entryStatusArray.push("NotPresent")
      : entryStatusArray?.filter((item) => item !== "NotPresent");

    setEntryStatusArr(entryStatusArray);
    setCurrPage(0);
    debouncedGetAttendeeList(entryStatusArray);
    return () => {
      debouncedGetAttendeeList.cancel();
    };
  }, [
    isEntered,
    isRejected,
    isCheckedOut,
    isNotEntered,
    searchQuery,
    selectedEntrance,
    selectedKycOptionIndex,
  ]);

  useEffect(() => {
    item &&
      setVenueEntrances([...venueEntrances, ...item.venue?.venueEntrances]);
  }, [item]);

  const getEnteredListRequest = async (entryStatusArray) => {
    try {
      // const result = await getEnteredList(
      const result = await getEnteredListWithPagination(
        venueEntrances?.find((x) => x.name === selectedEntrance)?.id !==
          undefined
          ? {
              eventId: eventId,
              searchMatch: searchQuery,
              entryStatus: entryStatusArray,
              entranceId: venueEntrances?.find(
                (x) => x.name === selectedEntrance
              )?.id,
              pageNumber: currPage,
              pageSize: 10,
              kycVerified:
                selectedKycOptionIndex === 0
                  ? null
                  : selectedKycOptionIndex === 1
                  ? true
                  : false,
            }
          : {
              eventId: eventId,
              searchMatch: searchQuery,
              entryStatus: entryStatusArray,
              pageNumber: currPage,
              pageSize: 10,
              kycVerified:
                selectedKycOptionIndex === 0
                  ? null
                  : selectedKycOptionIndex === 1
                  ? true
                  : false,
            }
      );
      if (result.status) {
        if (result.response?.attendee?.length > 0) {
          item === false && setItem(result.response?.eventDetails);
          setIsEndThresholdReached(
            result.response?.attendee?.length === 10 ? false : true
          );
          setEnteredList([...enteredList, ...result.response?.attendee]);
          setCurrPage(currPage + 1);
        } else {
          setIsEndThresholdReached(true);
          // setEnteredList([]);
          // setCurrPage(0);
        }
      } else {
        console.error("API Call Error: ", result.error);
      }
    } catch (error) {
      console.error("Error fetching entered list: ", error);
    }
  };

  const debouncedGetAttendeeList = debounce((entryStatusArray) => {
    getEnteredListRequest(entryStatusArray);
  }, 1000);

  const getEventStatisticsRequest = async (eventId) => {
    const result = await getEventStatistics({
      eventId: eventId,
    });
    if (result.status) {
      setEventStatisticsObj(result.response.totals);
    } else {
    }
  };

  useEffect(() => {}, [enteredList]);

  function areObjectPropertiesEqual(objArray, key) {
    return objArray.every((obj) => obj[key] === objArray[0][key]);
  }

  useEffect(() => {
    if (
      attendeeOptions.length === 4 &&
      areObjectPropertiesEqual(attendeeOptions, "isSelected") === false
    ) {
      const newArray = [
        ...attendeeOptions.slice(0, 0),
        { name: "All", id: 1, icon: null, isSelected: false },
        ...attendeeOptions.slice(0),
      ];
      setAttendeeOptions(newArray);
    }
  }, [attendeeOptions]);

  const segmentBtnPressedKyc = (index) => {
    if (index != selectedKycOptionIndex) {
      setSelectedKycOptionIndex(index);
    }
    // if (index === 0) {
    //   let tempStatusOptions = [...kycOptions];
    //   tempStatusOptions.map((item, ind) => {
    //     item.isSelected = ind === 0 ? true : false;
    //   });
    //   setKycOptions(tempStatusOptions);
    // } else {
    //   let tempStatusOptions = [...kycOptions];
    //   tempStatusOptions[0].isSelected = false;
    //   tempStatusOptions[index].isSelected =
    //     !tempStatusOptions[index].isSelected;
    //     setKycOptions(tempStatusOptions);
    // }
  };

  const segmentBtnPressedAttendee = (index) => {
    setSelectedAttendeeOptionIndex(index);
    if (index === 0) {
      let tempStatusOptions = [...attendeeOptions];
      tempStatusOptions.map((item, ind) => {
        item.isSelected = ind === 0 ? true : false;
      });
      setAttendeeOptions(tempStatusOptions);
    } else {
      let tempStatusOptions = [...attendeeOptions];
      tempStatusOptions[0].isSelected = false;
      tempStatusOptions[index].isSelected =
        !tempStatusOptions[index].isSelected;
      setAttendeeOptions(tempStatusOptions);
    }
  };

  const segmentBtnPressedEvent = (index) => {
    setSelectedEventOptionIndex(index);
    dispatch({
      type: SELECTED_EVENT_CATEGORY_DATA,
      payload: index,
    });
    navigate("/EventDetails/" + eventId);
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 400);
  };

  function getTimeDiffStatus(startDate, endDate) {
    const currentDate = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (currentDate < start) {
      // Event is upcoming
      const diffTime = start - currentDate;
      const days = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diffTime % (1000 * 60)) / 1000);

      if (days > 0) {
        return "Upcoming";
      } else {
        return `${hours}h ${minutes}m ${seconds}s`;
      }
    } else if (currentDate >= start && currentDate <= end) {
      // Event is live
      return "Live";
    } else {
      // Event has expired
      return "Expired";
    }
  }

  function ImageItem({ imageUrl }) {
    const [isValid, setIsValid] = useState(true);

    const handleImageError = () => {
      setIsValid(false);
    };

    return (
      <div>
        {isValid ? (
          <img
            onError={handleImageError}
            content="center"
            alt=""
            src={imageUrl}
            style={{
              height: 40,
              width: 40,
              borderRadius: 20,
            }}
          />
        ) : (
          <div
            style={{
              height: 40,
              width: 40,
              borderRadius: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: "1px 1px 3px 3px rgba(0, 0, 0, 0.05)",
            }}
          >
            <UserDefault height={20} width={20} />
          </div>
        )}
      </div>
    );
  }

  // render for list of assets with it's state
  const renderAttendeeList = ({ items, index }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: 60,
          cursor: "pointer",
        }}
        key={index}
        onClick={() =>
          navigate(
            `/EventDetails/${eventId}/AttendeeList/AttendeeDetails/${items?.ticket?.ticketId}`,
            {
              state: {
                ticketId: items?.ticket?.ticketId,
                eventId: eventId,
              },
            }
          )
        }
      >
        <div className="attendee-list-container">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <ImageItem
              imageUrl={process.env.REACT_APP_API_URL + items?.imageUri}
            />
            <div style={{ marginLeft: -15, marginTop: 27, zIndex: 44 }}>
              {items?.entryStatus === "CheckedIn" ? (
                <SuccessFilledWhite
                  fill={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--green")}
                  width={16}
                  height={16}
                />
              ) : items?.entryStatus === "NotPresent" ? (
                <div style={{ width: 16, height: 16 }}></div>
              ) : items?.entryStatus === "CheckedOut" ? (
                <ClockFilledWhite
                  fill={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--midBlue")}
                  width={16}
                  height={16}
                />
              ) : items?.entryStatus?.toLowerCase().includes("rejected") ? (
                <CloseFilledWhite
                  fill={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--red")}
                  width={16}
                  height={16}
                />
              ) : null}
            </div>
            <div
              style={{
                marginLeft: 12,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div className="generic-common-row-div">
                <Text
                  marginRight={8}
                  showUnderlineOnHover={true}
                  cursor={"pointer"}
                  variant={"m200"}
                >
                  {items?.ticket?.assignmentStatus === "Assigned"
                    ? (items?.firstName
                        ? items?.firstName
                        : items?.purchaserUser?.firstName
                        ? items?.purchaserUser?.firstName
                        : items?.ticket?.ticketId) +
                      " " +
                      (items?.lastName
                        ? items?.lastName
                        : items?.purchaserUser?.lastName
                        ? items?.purchaserUser?.lastName
                        : "")
                    : items?.ticket?.ticketId
                    ? `${items?.ticket?.ticketId}` +
                      (items?.purchaserUser?.fullName
                        ? ` (${items?.purchaserUser?.fullName})`
                        : "")
                    : ""}
                </Text>
                {items?.kycStatus === "Verified" && <VerifiedWhiteFilled />}
              </div>
              <Text
                cursor={"pointer"}
                marginTop={3}
                variant={"r100"}
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--veryDarkGrey")}
              >
                {items?.ticket?.type}
              </Text>
            </div>
          </div>
          <div
            style={{ cursor: "pointer", padding: 10 }}
            onClick={() =>
              navigate(
                `/EventDetails/${eventId}/AttendeeList/AttendeeDetails/${items?.ticket?.ticketId}`,
                {
                  state: {
                    name:
                      items?.ticket?.assignmentStatus === "Assigned"
                        ? (items?.firstName
                            ? items?.firstName
                            : items?.purchaserUser?.firstName
                            ? items?.purchaserUser?.firstName
                            : items?.ticket?.ticketId) +
                          " " +
                          (items?.lastName
                            ? items?.lastName
                            : items?.purchaserUser?.lastName
                            ? items?.purchaserUser?.lastName
                            : "")
                        : items?.ticket?.ticketId
                        ? `${items?.ticket?.ticketId}` +
                          (items?.purchaserUser?.fullName
                            ? ` (${items?.purchaserUser?.fullName})`
                            : "")
                        : "",
                    eventId: eventId,
                    item: items,
                    entryStatus: items?.entryStatus,
                    event: item,
                  },
                }
              )
            }
          >
            <ChevronRight
              stroke={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--midBlue")}
              width={24}
              height={24}
            />
          </div>
        </div>
        {index !== enteredList.length - 1 && (
          <div className="generic-common-divider" />
        )}
      </div>
    );
  };

  return (
    <div className="page-wrapper-attendee">
      <div className="main-body-content">
        <div id="sidebar" className="home-header-sideNav">
          <SideBar
            onChangeOption={(index) =>
              navigate("/Home", {
                state: { selectedIndex: index },
              })
            }
            createEventClicked={() => navigate("/CreateEvent")}
            onGoToEventClicked={(eventId) =>
              navigate(`/EventDetails/${eventId}`, {
                state: {
                  eventId: eventId,
                },
              })
            }
          />
        </div>

        <div className="rht-body-inner-content">
          <div className="order-content-div listing-top-headerPagination">
            <MenuLink
              showAnyButton={true}
              // btn2Label={"Cancel event"}
              labelArray={[
                { label: "Home", id: 1 },
                { label: item?.name, id: 2 },
              ]}
              withCog={false}
              onMenuLinkPress={(ind) =>
                ind === 0
                  ? navigate("/Home")
                  : ind === 1
                  ? navigate("/EventDetails/" + eventId)
                  : null
              }
            />
            <div className="event-details-top-after-menulink-container">
              <Text variant={"m600"}>{item?.name}</Text>

              <div className="event-details-event-scroll-container">
                <div className="event-detailsTop-btns-inner">
                  {eventSectionOptions.map((items, ind) => {
                    return (
                      <div key={ind} style={{ marginRight: 8 }}>
                        <Tags
                          disabled={false}
                          paddingVertical={8}
                          label={items.name}
                          selected={
                            ind == selectedEventOptionIndex ? true : false
                          }
                          onChangeSelection={() => segmentBtnPressedEvent(ind)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="listingPagination-block">
              <MenuLink
                style={{
                  paddingTop: 16,
                  paddingBottom: 16,
                  borderBottomColor: getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--lightGrey"),
                  borderBottomWidth: "1px !important",
                  boxShadow: "0.3px 0.3px 0.3px 0.3px rgba(0, 0, 0, 0.05)",
                  marginBottom: 0,
                }}
                labelArray={[
                  { label: "Overview", id: 1 },
                  { label: "Event entry list", id: 2 },
                ]}
                withCog={false}
                onMenuLinkPress={(ind) =>
                  ind === 0 ? navigate("/EventDetails/" + eventId) : null
                }
              />
            </div>

            <div className="attendee-parent-content">
              <div
                className="content-top"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1 / 2,
                }}
              >
                {item?.startDate &&
                  item?.endDate &&
                  getTimeDiffStatus(item?.startDate, item?.endDate) !==
                    "Upcoming" && (
                    <Tags
                      backgroundColor={
                        getTimeDiffStatus(item?.startDate, item?.endDate) ===
                        "Live"
                          ? "var(--fuchsiaSecondary"
                          : null
                      }
                      paddingVertical={8}
                      label={getTimeDiffStatus(item?.startDate, item?.endDate)}
                      SourceIcon={
                        getTimeDiffStatus(item?.startDate, item?.endDate) ===
                        "Expired"
                          ? WarningFilled
                          : RecordCircle
                      }
                      selected={true}
                      onChangeSelection={() => {}}
                      disabled={
                        getTimeDiffStatus(item?.startDate, item?.endDate) ===
                        "Expired"
                          ? true
                          : false
                      }
                      style={{
                        borderColor:
                          getTimeDiffStatus(item?.startDate, item?.endDate) ===
                          "Expired"
                            ? "var(--grey)"
                            : null,
                        width:
                          getTimeDiffStatus(item?.startDate, item?.endDate) ===
                          "Expired"
                            ? 110 
                            :  getTimeDiffStatus(item?.startDate, item?.endDate) ===
                            "Live" ?  90 : 145,
                      }}
                    />
                  )}

                <div className="titleCont">
                  <Text marginTop={32} variant={"m600"}>
                    Event entry list
                  </Text>
                </div>
                <div className="subTitleCont">
                  <Text marginTop={16} variant={"r500"}>
                    Search and filter ticket holders for this event
                  </Text>
                </div>
              </div>
              <div
                className="content-bottom"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  flex: 1 / 2,
                  border: "1px solid var(--lightGrey)",
                  backgroundColor: "var(--white)",
                  height: 120,
                  // marginLeft: 77,
                }}
              >
                {progressMenuOptions.map((item, ind) => {
                  return (
                    <div
                      className="countBox"
                      style={{
                        flex: 1 / 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        // marginRight:
                        //   ind !== progressMenuOptions.length - 1 && 2,
                        borderRightColor: "var(--lightGrey)",
                        borderRightWidth: 1,
                      }}
                    >
                      {/* <HoverBGButton
                        withBorder={true}
                        backgroundHover={
                          ind === 0
                            ? getComputedStyle(
                                document.documentElement
                              ).getPropertyValue("--hoverGreen")
                            : ind === 1
                            ? getComputedStyle(
                                document.documentElement
                              ).getPropertyValue("--hoverRed")
                            : null
                        }
                        icon={item.icon}
                        size={64}
                        progressValue={item.progress}
                        isChecked={item.progress === 100 ? true : false}
                      /> */}
                      <div
                        style={{
                          backgroundColor:
                            ind === 0
                              ? "var(--green)"
                              : ind === 1
                              ? "var(--red)"
                              : ind === 2
                              ? "var(--midBlue)"
                              : null,
                        }}
                        className="scan-progress-indicator-content-view"
                      >
                        {item.icon}
                      </div>
                      <Text marginLeft={8} marginTop={12} variant={"b500"}>
                        {ind === 0
                          ? eventStatisticsObj?.checkedIn
                            ? eventStatisticsObj?.checkedIn
                            : "0"
                          : ind === 1
                          ? eventStatisticsObj?.rejected
                            ? eventStatisticsObj?.rejected
                            : "0"
                          : ind === 2
                          ? eventStatisticsObj?.checkedOut
                            ? eventStatisticsObj?.checkedOut
                            : "0"
                          : ind === 3
                          ? eventStatisticsObj?.toScan
                            ? eventStatisticsObj?.toScan
                            : "0"
                          : "0"}
                      </Text>
                      <Text marginLeft={8} marginTop={8} variant={"m300"}>
                        {ind === 0
                          ? "approved"
                          : ind === 1
                          ? "rejected"
                          : ind === 2
                          ? "checked out"
                          : "not entered"}
                      </Text>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              className="attendee-search-cont"
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1,
                justifyContent: "space-between",
                marginLeft: 48,
                marginRight: 24,
              }}
            >
              <div style={{ width: "50%" }}>
                <SearchInputField
                  editable={true}
                  placeholder={"Search for attendees..."}
                  onChangeSearchValue={(Event) => {
                    setSearchQuery(Event === "clear" ? "" : Event);
                    setEnteredList([]);
                    setCurrPage(0);
                  }}
                />
              </div>
              <DropdownInput
                width={160}
                eventCategory={selectedEntrance}
                data={venueEntrances}
                onSelectedItem={(value) => {
                  setSelectedEntrance(value);
                  setEnteredList([]);
                  setCurrPage(0);
                }}
                editable={true}
              />
            </div>
            <div
              className="attendee-event-bar"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 32,
                marginLeft: 48,
                marginRight: 24,
              }}
            >
              <div
                style={{ width: "50%" }}
                className="attendee-event-scroll-container"
              >
                {attendeeOptions.map((items, ind) => {
                  return (
                    <div key={ind} style={{ marginRight: 12 }}>
                      <Tags
                        paddingVertical={8}
                        label={items.name}
                        SourceIcon={items.icon}
                        selected={items.isSelected}
                        onChangeSelection={() => {
                          segmentBtnPressedAttendee(ind);
                          setEnteredList([]);
                          setCurrPage(0);
                        }}
                      />
                    </div>
                  );
                })}
              </div>

              <div
                style={{ maxWidth: "50%", marginLeft: 16 }}
                className="attendee-event-scroll-container"
              >
                {kycOptions.map((items, ind) => {
                  return (
                    <div key={ind} style={{ marginRight: 12 }}>
                      <Tags
                        paddingVertical={8}
                        label={items.name}
                        selected={ind == selectedKycOptionIndex ? true : false}
                        onChangeSelection={() => {
                          segmentBtnPressedKyc(ind);
                          setEnteredList([]);
                          setCurrPage(0);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="attendee-list-parent-content">
              <InfiniteScroll
                dataLength={enteredList.length}
                next={() => {
                  setTimeout(() => {
                    getEnteredListRequest(entryStatusArr);
                  }, 1000);
                }}
                hasMore={!isEndThresholdReached}
                loader={
                  !isEndThresholdReached ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "70px",
                      }}
                      className="d-flex justify-content-center"
                    >
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : null
                }
              >
                {enteredList.length > 0 ? (
                  enteredList.map((items, index) => {
                    return renderAttendeeList({ items, index });
                  })
                ) : (
                  <div
                    style={{
                      color: "var(--grey)",
                      textAlign: "center",
                      marginTop: "64px",
                      marginBottom: "64px",
                    }}
                  >
                    No attendees have entered this event.
                  </div>
                )}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginLeft: "-380" }}>
        <Footer />
      </div>
    </div>
  );
}

export default AttendeeList;
