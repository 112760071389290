import React from "react";
import Text from "../../Common/Text";
import Popup from "reactjs-popup";
import { ReactComponent as InfoFilled } from "../../../assets/svgs/InfoFilled.svg";

export default function TooltipPopup({ label }) {
  const isMobile = window.innerWidth <= 768;
  return (
    <Popup
      repositionOnResize={true}
      offsetY={6}
      offsetX={160}
      arrow={false}
      contentStyle={{
        width: "347px",
        borderRadius: "8px",
        padding: "12px 16px",
        boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.16)",
        border: "none",
      }}
      trigger={(open) => (
        <div style={{ cursor: "pointer", marginLeft: 16 }}>
          <InfoFilled width={24} height={24} />
        </div>
      )}
      //   position="left bottom"
      on={["hover"]}
      closeOnDocumentClick
    >
      <Text marginRight={13} variant={"r200"}>
        {label ? label : "This information will display for customers in the app"}
      </Text>
    </Popup>
  );
}
