import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../EventDetails/EventDetails.css";
import InputField from "../../Common/InputFields/InputFieldPrimary/InputFieldPrimary";
import ButtonPrimary from "../../Common/Buttons/ButtonPrimary/ButtonPrimary";
import DropdownInput from "../../../Component/Common/DropdownInput/DropdownInput";
import TooltipPopup from "../../../Component/Common/TooltipPopup/TooltipPopup";
import Text from "../../Common/Text";
import { getCitiesByCountryId } from "../../../services/api";
import {
  SHOW_ACTIVITY_INDICATOR,
} from "../../../actions";

export default function VenueAddress({
  onAddressLine1Change,
  onAddressLine2Change,
  onPostalCodeChange,
  onCountryChange,
  onCityChange,
  toHaveAccessibleErrorMessage,
  shouldShowSubmitButton,
  onSaveClicked,
  onDiscardClicked,
  addressLine1,
  addressLine2,
  postalCode,
  country,
  city,
}) {
  const dispatch = useDispatch();
  const countryList = useSelector((state) => state.countryData?.countryData);
  const [cityList, setCityList] = useState([]);
  const [filteredCityList, setFilteredCityList] = useState([]);
  useEffect(() => {
    setFilteredCityList(cityList);
  }, []);

  useEffect(() => {
    const foundCountry = countryList.find(
      (countryObj) => countryObj.name === country
    );
    console.log('country', foundCountry);
    if (foundCountry) {
      getCitiesByCountryIdRequest(foundCountry.id);
    }
  }, [country]);

  const getCitiesByCountryIdRequest = async (countryId) => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const params = {
      countryId: countryId,
    };
    const result = await getCitiesByCountryId(params);
    if (result?.status && result?.response?.cities?.length > 0) {
      console.log('result', result?.response?.cities);
      setCityList(result?.response?.cities);
      setFilteredCityList(result?.response?.cities);
    } else {
      setCityList([]);
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  const onChangeTownCitySearch = (event) => {
    const query = event?.toLowerCase() || '';
    const filtered = cityList.filter(item => 
      item.name.toLowerCase().includes(query)
    );
    setFilteredCityList(filtered);
  };

  return (
    <div className="event-about-div">
      <div className="generic-common-row-div">
        <Text variant={"m500"}>
          Address
        </Text>
        <span className="AddressInfoIconCont">
          <TooltipPopup />
        </span>
      </div>

      <div className="AddressFieldCont"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        >
        <div className="AddressLeftCont"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 40,
            flex: 1 / 2,
            paddingRight: 32,
            //marginRight: 32,
          }}
          >
            <div className="CommonFormGroup">
              <Text variant="m300" marginBottom={8}>
                Address line 1*
              </Text>

              <InputField
                inputValue={addressLine1}
                verifyNotNeed={true}
                shouldShowErrorOnAssistive={true}
                placeholder={"e.g. Building 24"}
                secureTextEntry={false}
                editable={true}
                type={"eventName"}
                withOutIcon={true}
                isError={false}
                onChangeValue={(text) => onAddressLine1Change(text)}
                onBlur={(value) => {}}
                assistive={
                  toHaveAccessibleErrorMessage?.includes("addressLine1")
                    ? "*Address Line 1 is required"
                    : ""
                }
              />

            </div>
            
            <div className="CommonFormGroup">
              <Text variant="m300" marginBottom={8} marginTop={0}>
                Address line 2
              </Text>

              <InputField
                inputValue={addressLine2}
                verifyNotNeed={true}
                placeholder={"e.g. Rochester Square"}
                secureTextEntry={false}
                editable={true}
                type={"eventName"}
                withOutIcon={true}
                isError={false}
                onChangeValue={(text) => onAddressLine2Change(text)}
                onBlur={(value) => {}}
                assistive={
                  toHaveAccessibleErrorMessage?.includes("addressLine2")
                    ? "*Address Line 2 is required"
                    : ""
                }
              />
            </div>
                      

          
        </div>
        <div className="AddressRightCont"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 40,
            flex: 1 / 2,
            paddingLeft: 32,
            marginBottom: 15,
          }}
        >

          <div className="CommonFormGroup">
            <Text variant="m300" marginBottom={8}>
              Postal Code*
            </Text>

            <InputField
              inputValue={postalCode}
              verifyNotNeed={true}
              shouldShowErrorOnAssistive={true}
              placeholder={"e.g. SE11 8JX"}
              secureTextEntry={false}
              editable={true}
              type={"eventName"}
              withOutIcon={true}
              isError={false}
              onChangeValue={(text) => onPostalCodeChange(text)}
              onBlur={(value) => {}}
              assistive={
                toHaveAccessibleErrorMessage?.includes("postalCode")
                  ? "*Postal Code is required"
                  : ""
              }
            />
          </div>

          <div className="CommonFormGroup">
            <Text variant="m300" marginBottom={8} marginTop={0}>
              Country*
            </Text>

            <DropdownInput
              type={"country"}
              eventCategory={country || 'e.g. United Kingdom'}
              data={countryList}
              onSelectedItem={(value) => {onCountryChange(value); onCityChange('')}}
              editable={true}
            />
            <Text
              marginTop={8}
              variant={"label"}
              alignSelf={"flex-start"}
              color={"var(--red)"}
            >
              {toHaveAccessibleErrorMessage?.includes("country")
                ? "*Country is required"
                : ""}
            </Text>
          </div>
          

          
          
        </div>
      </div>

      <div className="EventTownCityCont"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        >

        <div className="TownCityCont"
          style={{
            display: "flex",
            //flex: 1 / 2,
            flexDirection: "column",
            width: "50%",
            paddingRight: 32,
            //marginTop: 26,
          }}
        >
          <div className="CommonFormGroup mb-0">
            <Text variant="m300" marginBottom={8}>
              Town/City*
            </Text>

            <DropdownInput
              type={"city"}
              eventCategory={city || 'Select City'}
              data={filteredCityList}
              onSelectedItem={(value) => onCityChange(value)}
              editable={cityList?.length > 0 ? true : false}
              searchPlaceHolder={"Search City"}
              onChangeSearchValue={(event) => {event === 'clear' ? setFilteredCityList(cityList) : onChangeTownCitySearch(event)}
              }
            />

<Text
              marginTop={8}
              variant={"label"}
              alignSelf={"flex-start"}
              color={"var(--red)"}
            >
              {toHaveAccessibleErrorMessage?.includes("city")
                ? "*Town/City is required"
                : ""}
            </Text>



            {/* <InputField
              inputValue={city}
              verifyNotNeed={true}
              shouldShowErrorOnAssistive={true}
              placeholder={"e.g. London"}
              secureTextEntry={false}
              editable={true}
              type={"eventName"}
              withOutIcon={true}
              isError={false}
              onChangeValue={(text) => onCityChange(text)}
              onBlur={(value) => {}}
              assistive={
                toHaveAccessibleErrorMessage?.includes("city")
                  ? "*Town/City is required"
                  : ""
              }
            /> */}
          </div>
          
        </div>

      </div>

      {shouldShowSubmitButton && (
        <div
          className="bottom-buttons-container-edit-view"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 40,
            justifyContent: "center",
          }}
        >
          <ButtonPrimary
            isSecondary={true}
            label={"Cancel"}
            onPress={() => onDiscardClicked()}
            width={328}
            style={{ marginRight: 24 }}
          />
          <ButtonPrimary
            label={"Save changes"}
            onPress={() => onSaveClicked()}
            width={328}
          />
        </div>
      )}
    </div>
  );
}
