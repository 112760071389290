import {
  lowerCaseReg,
  numberReg,
  symbolReg,
  upperCaseReg,
} from "../../constants/regex";

import { useState } from "react";

const usePasswordVerification = () => {
  const verifyPassword = (password) => {
    let validationArray = [];
    if (password?.length < 8) {
      validationArray.push("Length");
    }
    if (upperCaseReg.test(password) === false) {
      validationArray.push("Upper");
    }
    if (lowerCaseReg.test(password) === false) {
      validationArray.push("Lower");
    }
    if (numberReg.test(password) === false) {
      validationArray.push("Number");
    }
    if (symbolReg.test(password) === false) {
      validationArray.push("Symbol");
    }
    return validationArray;
  };

  return {verifyPassword };
};

export default usePasswordVerification;
