import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./Login.css";
import { ReactComponent as CheckBoxCheckedCircle } from "../../assets/svgs/CheckBoxCheckedCircle.svg";
import { ReactComponent as CheckBoxUncheckCircle } from "../../assets/svgs/CheckBoxUncheckCircle.svg";
import Footer from "../../Component/FooterNew/FooterNew";
import Text from "../../Component/Common/Text";
import ButtonPrimary from "../../Component/Common/Buttons/ButtonPrimary/ButtonPrimary";
import InputField from "../../Component/Common/InputFields/InputFieldPrimary/InputFieldPrimary";
import useEmailVerification from "../../hooks/common/useEmailverification";
import usePasswordVerification from "../../hooks/common/usePasswordVerification";
import { ReactComponent as Icon } from "../../assets/svgs/Icon.svg";
import Banner from "../../Component/Common/Banner/Banner";
import {
  login,
  getUserDetails,
  getCurrencyList,
  getCountryList,
  getEventCategories,
  getEventList,
  getDraftEventExist,
  getInactiveDraftEventList
} from "../../services/api";
import Api from "../../utils/Api";
import {
  AUTH_TOKEN,
  USER_DATA,
  CURRENCY_DATA,
  COUNTRY_DATA,
  EVENT_CATEGORY_DATA,
  EVENT_DATA,
  SHOW_DRAFT,
  SHOW_ACTIVITY_INDICATOR,
} from "../../actions";

function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showBanner, setShowBanner] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isError, setIsError] = useState(false);
  const { isVerified, verifyEmail } = useEmailVerification();
  const { verifyPassword } = usePasswordVerification();

  useEffect(() => {
    setTimeout(() => {
      hideLoaderScreen();
    }, 500);
  }, []);

  useEffect(() => {}, []);

  const hideLoaderScreen = () => {
    setShowLoader(false);
    const loader = document.getElementById("loader");
    if (loader !== null) {
      var fadeEffect = setInterval(function () {
        if (!loader.style.opacity) {
          loader.style.opacity = 1;
        }
        if (loader.style.opacity > 0) {
          loader.style.opacity -= 0.1;
        } else {
          //setPageLoader(false);
          setTimeout(() => {
            loader.remove();
          }, 500);
          clearInterval(fadeEffect);
        }
      }, 100);
    }
  };

  const ErrorCheck = (type, value, onSubmit) => {
    setIsError(false);
    if (type === "email") {
      if ((!isVerified && value !== "") || onSubmit == true) {
        setEmailError("Email address entered is not valid.");
      } else {
        setEmailError("");
      }
    }
    if (type === "password") {
      const validationArray = verifyPassword(value);
      if (
        (validationArray.length !== 0 && value !== "") ||
        onSubmit == true
      ) {
        setPasswordError("Password entered does not meet criteria.");
      } else {
        setPasswordError("");
      }
    }
  };

  const onSubmitLogin = async () => {
    if (email.trim() !== "" && password.trim() !== "") {
      setIsError(false);
      const params = {
        emailAddress: email.trimEnd(),
        password: password,
        tokenExpiryDays: rememberMe ? 30 : 0,
      };
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
      const result = await login(params);

      if (result?.status) {
        localStorage.setItem("authToken", JSON.stringify(result.response));
        dispatch({ type: AUTH_TOKEN, payload: result.response });
        Api.defaultHeader({
          authorization: `Bearer ${result.response}`,
        });
        eventData();
        getCurrencyListRequest();
        getCountryListRequest();
        getEventCategoriesRequest();
      } else {
        setIsError(true);
        console.log('eqweqwe:', result?.response?.response);
        if(result?.response?.response === 'This email address has not been verified. Please verify your email before logging in') {
          localStorage.setItem("emailAddress", email.trimEnd());
          navigate('/VerifyEmail')
        }
        else
        {
        setLoginErrorMessage(result?.response?.response  || '')
        setShowBanner(true);
        }
        dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
      }
    } else {
      if (email.trim() === "") {
        setEmailError("Email address required.");
        setIsError(true);
      }
      if (password.trim() === "") {
        setPasswordError("Password required.");
        setIsError(true);
      }
    }
  };

  const userData = async () => {
    const result = await getUserDetails();
    if (result.status) {
      dispatch({ type: USER_DATA, payload: result.response });
      navigate(
        result?.response?.businesses?.length > 0 ? "/Home" : "/BusinessSignUp"
      );
      // navigate("/Home");
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    } else {
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    }
  };

  const getCurrencyListRequest = async () => {
    const result = await getCurrencyList({});
    if (result.status) {
      dispatch({ type: CURRENCY_DATA, payload: result.response });
    }
  };

  const getCountryListRequest = async () => {
    const result = await getCountryList({});
    if (result.status) {
      dispatch({ type: COUNTRY_DATA, payload: result.response });
    }
  };

  const getEventCategoriesRequest = async () => {
    const result = await getEventCategories({});
    if (result.status) {
      dispatch({ type: EVENT_CATEGORY_DATA, payload: result.response });
    }
  };

  const eventData = async (type) => {
    const result = await getEventList({
      isPublished:
        parseInt(localStorage.getItem("selectedSegmentIndex")) === 2
          ? false
          : true,
      type:
        localStorage.getItem("selectedSegmentIndex") === null ||
        parseInt(localStorage.getItem("selectedSegmentIndex")) === 0
          ? "Current"
          : parseInt(localStorage.getItem("selectedSegmentIndex")) === 1
          ? "Past"
          : "All",
    });
    if (result.status) {
      // dispatch({ type: EVENT_DATA, payload: result.response });
      parseInt(localStorage.getItem("selectedSegmentIndex")) !== 2
        ? dispatch({ type: EVENT_DATA, payload: result.response })
        : getInactiveDraftEventListRequest(result.response);
      checkForDraft();
    } else {
      dispatch({ type: EVENT_DATA, payload: [] });
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
      checkForDraft();
    }
  };

  const getInactiveDraftEventListRequest = async (existingDraftList) => {
    const result = await getInactiveDraftEventList({});
    if (result.status) {
      dispatch({
        type: EVENT_DATA,
        payload: [...existingDraftList, ...result.response],
      });
    } else {
      dispatch({ type: EVENT_DATA, payload: existingDraftList });
    }
  };

  // const checkForDraft = async () => {
  //   const result = await getEventList({
  //     isPublished: false,
  //     category: "",
  //     type: "All",
  //   });
  //   userData();
  //   dispatch({
  //     type: SHOW_DRAFT,
  //     payload: result?.response?.length > 0 ? true : false,
  //   });
  // };

  const checkForDraft = async () => {
    const result = await getDraftEventExist({});
    dispatch({
      type: SHOW_DRAFT,
      payload: result?.response?.count > 0 ? true : false,
    });
    userData();
  };

  return (
    <div className="page-wrapper-login">
      {showLoader && (
        <div id="loader" className="loader">
          <div className="loader-logo">
            <span className="logo"></span>
          </div>
        </div>
      )}
      <Banner paddingLeft={8}
        text={loginErrorMessage || "There’s an issue with your email address or password."}
        headingText={"Sign in failed"}
        showBanner={showBanner}
        type={"Error"}
        withIcon={true}
        withClose={true}
        onClose={() => setShowBanner(false)}
        position={"top"}
      />
      <div className="login-box-container-login">
        <div className="login-inner-box-container-login">
          <div className="generic-common-row-centerd-div">
          <Icon height={64} width={64}/>
          <Text variant="b500" marginLeft={8}>
          Box Office
              </Text>
          </div>

          <div className="topLogin-text">
            <div className="topLogin-tittle-text">
              <Text variant="m700" marginTop={16} marginBottom={24}>
                Sign in to your account
              </Text>
            </div>

            <div className="bottomLogin-prg-text">
              <Text variant="r300" marginBottom={32}>
                Welcome back! Please enter your details.
              </Text>
            </div>
          </div>

          <div className="innerForm-account">
            <label>
              <Text
                alignSelf={"start"}
                labelclass="login-form-label"
                variant="m300"
                marginBottom={8}
              >
                Email address
              </Text>
            </label>

            <InputField
              inputValue={email}
              placeholder={"Enter email address"}
              secureTextEntry={false}
              editable={true}
              type={"email"}
              isError={isError}
              onChangeValue={(text) => (
                setIsError(false), setEmail(text), verifyEmail(text)
              )}
              onBlur={(value) => ErrorCheck("email", value)}
              assistive={emailError}
            />

            <label>
              <Text
                labelclass="login-form-label"
                variant="m300"
                marginTop={24}
                marginBottom={8}
                alignSelf={"start"}
              >
                Password
              </Text>
            </label>

            <InputField
              placeholder={"Enter password"}
              secureTextEntry={true}
              editable={true}
              isError={isError}
              onChangeValue={(text) => (
                setIsError(false), setPassword(text), verifyPassword(text)
              )}
              onBlur={(value) => ErrorCheck("password", value)}
              assistive={passwordError}
            />

            <div className="login-remember-container">
              <div
                style={{ height: 40 }}
                onClick={() => setRememberMe(!rememberMe)}
                className="generic-common-row-div"
              >
                <div
                  style={{
                    width: 38,
                    height: 38,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  {rememberMe ? (
                    <CheckBoxCheckedCircle width={24} height={24} />
                  ) : (
                    <CheckBoxUncheckCircle width={24} height={24} />
                  )}
                </div>

                <Text variant="r200" marginLeft={6}>
                  Remember for 30 days
                </Text>
              </div>

              <div className="forgotten-password-text">
                <Text
                  onClick={() => navigate("/ForgotPassword")}
                  color={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--midBlue")}
                  variant="r200"
                  marginLeft={16}
                  cursor={"pointer"}
                >
                  Forgotten password?
                </Text>
              </div>
            </div>

            <div className="login-sign-in-up-container">
              <div className="bottomLogin-btn">
                <ButtonPrimary
                  width={360}
                  label={"Sign in"}
                  onPress={() => onSubmitLogin()}
                />
              </div>
              <div 
              onClick={() => navigate("/SignUp")} 
              className="dontAccount">
                <Text cursor={"pointer"}
                  color={"var(--midBlue)"}
                  variant="m300"
                  marginTop={30}
                >
                  Don’t have an account?
                </Text>
                <Text marginLeft={4} cursor={"pointer"}
                  onClick={() => navigate("/SignUp")}
                  color={"var(--midBlue)"}
                  variant="m300"
                  marginTop={30}
                >
                  {'Sign up'}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Login;
