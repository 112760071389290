import { CURRENCY_DATA } from "../actions";
export default function currencyData(state = [], action) {
  switch (action.type) {
    case CURRENCY_DATA: {
      return {
        ...state,
        currencyData: action.payload,
      };
    }
    default:
      return state;
  }
}
