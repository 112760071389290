import React, { useState, useEffect } from "react";
import Text from "../Text";
import "./AccordionCustom.css";
import Accordion1 from "react-bootstrap/Accordion";

export default function AccordionCustom({ heading, renderContent, isOpen }) {
  return (
    <Accordion1 defaultActiveKey={isOpen ? "1" : null}>
      <Accordion1.Item eventKey={isOpen ? "1" : 2}>
        <Accordion1.Header>
          <Text variant="m400">{heading}</Text>
        </Accordion1.Header>
        <Accordion1.Body>{renderContent && renderContent()}</Accordion1.Body>
      </Accordion1.Item>
    </Accordion1>
  );
}
