import React from "react";
import "./HoverBGButton.css";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { ReactComponent as SuccessFilled } from "../../../../assets/svgs/SuccessFilled.svg";

export default function ButtonPrimary({
  onPress,
  disabled,
  icon,
  size,
  backgroundHover,
  withBorder,
  progressValue,
  isChecked,
  style,
}) {
  const hoverDivStyles = {
    display: "flex",
    height: size,
    width: size,
    borderRadius: size / 2,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: backgroundHover
      ? backgroundHover
      : "var(--twotix-light-blue)",
    border: withBorder ? "1px solid #EBEBEB" : "none",
    ...style,
  };

  return (
    <div
      // style={{ width: size + 8, height: size + 8 }}
      style={{ width: 48, height: 48 }}
      className="BusinessSignUp-inner-createBlock"
    >
      <CircularProgressbarWithChildren
        maxValue={100}
        minValue={0}
        counterClockwise={false}
        background={false}
        value={progressValue && progressValue !== 100 ? progressValue : 0}
        styles={buildStyles({
          // rotation: 0.5,
          strokeLinecap: "round",
          pathTransitionDuration: 0.5,
          // Colors
          pathColor:
            progressValue && progressValue !== 100
              ? getComputedStyle(document.documentElement).getPropertyValue(
                  "--midBlue"
                )
              : "transparent",
          trailColor: "transparent",
          backgroundColor: "#3e98c7",
          width: size,
          height: size,
          radius: size / 2,
        })}
      >
        {" "}
        <div
          className="hover-bg-button"
          style={{ ...hoverDivStyles }}
          onClick={() => (onPress ? onPress() : {})}
          aria-disabled={disabled}
        >
          {icon}
          {isChecked && (
            <SuccessFilled
              style={{ bottom: 4, marginLeft: 44, position: "absolute" }}
            />
          )}
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
}
