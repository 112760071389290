import { SHOW_ACTIVITY_INDICATOR } from '../actions';

export default function showLoader(state = null, action = {}) {
  switch (action.type) {
    case SHOW_ACTIVITY_INDICATOR: {
      return {
        ...state,
        showLoader: action.payload,
      };
    }

    default:
      return state;
  }
}
