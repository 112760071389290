export const AUTH_TOKEN = "AUTH_TOKEN";
export const LANGUAGE_LOCALE = "LANGUAGE_LOCALE";
export const USER_DATA = "USER_DATA";
export const EVENT_DATA = "EVENT_DATA";
export const DRAFT_DATA = "DRAFT_DATA";
export const CURRENCY_DATA = "CURRENCY_DATA";
export const COUNTRY_DATA = "COUNTRY_DATA";
export const EVENT_CATEGORY_DATA = "EVENT_CATEGORY_DATA";
export const BANNER_CONTENT = "BANNER_CONTENT";
export const SHOW_ACTIVITY_INDICATOR = "SHOW_ACTIVITY_INDICATOR";
export const SETTINGS_DATA = "SETTINGS_DATA";
export const SELECTED_EVENT_DATA = "SELECTED_EVENT_DATA";
export const SELECTED_WALLET_INDEX = "SELECTED_WALLET_INDEX";
export const CONNECT_WALLET_FOR_NFT = "CONNECT_WALLET_FOR_NFT";
export const SHOW_DRAFT = "SHOW_DRAFT";
export const EVENT_TYPE = "EVENT_TYPE";
export const SELECTED_EVENT_CATEGORY_DATA = "SELECTED_EVENT_CATEGORY_DATA";
