import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./ForgotPassword";
import Footer from "../../Component/FooterNew/FooterNew";
import Text from "../../Component/Common/Text";
import ButtonPrimary from "../../Component/Common/Buttons/ButtonPrimary/ButtonPrimary";
import InputField from "../../Component/Common/InputFields/InputFieldPrimary/InputFieldPrimary";
import usePasswordVerification from "../../hooks/common/usePasswordVerification";
import { ReactComponent as Icon } from "../../assets/svgs/Icon.svg";
import Banner from "../../Component/Common/Banner/Banner";
import { resetPassword } from "../../services/api";
import { SHOW_ACTIVITY_INDICATOR } from "../../actions";

function CreatePassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { emailAddress } = location?.state || '';
  const [showBanner, setShowBanner] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const { verifyPassword } = usePasswordVerification();
  const [isVerifiedPassword, setIsVerifiedPassword] = useState(true);
  const [passwordVerification, setPasswordVerification] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const passwordCriteria = ["Length", "Upper", "Lower", "Number", "Symbol"];

  useEffect(() => {
    if (password !== "") {
      const validationArray = verifyPassword(password);
      setIsVerifiedPassword(validationArray.length > 0 ? false : true);
      setPasswordVerification(validationArray);
    }
  }, [password]);

  // const VerifyPasswordCombination = () => {
  //   const validationArray = usePasswordVerification(password);
  //   setPasswordVerification(validationArray);
  // }

  const ErrorCheck = (type, value, onSubmit) => {
    if (type === "password") {
      if ((!isVerifiedPassword && value !== "") || onSubmit == true) {
        setPasswordError("Password entered does not meet criteria.");
      } else {
        setPasswordError("");
      }
    }

    if (type === "confirmPassword") {
      if ((password !== value && value !== "") || onSubmit == true) {
        setConfirmPasswordError("Confirm Password did not match.");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  const onSubmitPassword = async () => {
    if (
      password.trim() !== "" &&
      confirmPassword.trim() !== "" &&
      passwordError === "" &&
      confirmPasswordError === "" && password === confirmPassword
    ) {
      const params = {
        emailAddress: emailAddress,
        password: password,
        // userRole: "NotSet"
      };


      console.log('params::3123123', params);

      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
      const result = await resetPassword(params);

      if (result?.status) {
        navigate("/PasswordUpdated");
      } else {
        setShowBanner(true);
      }
    } else {
      if (password.trim() === "") {
        setPasswordError("Password required.");
      }

      if (confirmPassword.trim() === "") {
        setConfirmPasswordError("Confirm password required.");
      }

      if (password !== confirmPassword) {
        setConfirmPasswordError("Confirm Password did not match.");
      }
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  return (
    <div className="page-wrapper-fp">
      <div className="login-box-container-fp">
        <Banner
          text={"This password has been previously used"}
          headingText={"Password reset failed"}
          showBanner={showBanner}
          type={"Error"}
          withIcon={true}
          withClose={true}
          onClose={() => setShowBanner(false)}
          position={"top"}
        />
        <div className="login-inner-box-container-fp">
        <div className="generic-common-row-centerd-div">
          <Icon height={64} width={64}/>
          <Text variant="b500" marginLeft={8}>
          Box Office
              </Text>
          </div>

          <div className="topLogin-text">
            <div className="topLogin-tittle-text">
              <Text variant="m700" marginTop={16} marginBottom={24}>
                Create new password
              </Text>
            </div>
            <div className="bottomLogin-prg-text">
              <Text variant="r300" marginBottom={32} textAlign={"center"}>
                Your new password must be different from previous passwords.
              </Text>
            </div>
          </div>

          <label>
            <Text
              alignSelf={"start"}
              labelclass="login-form-label"
              variant="m300"
              marginTop={16}
              marginBottom={8}
            >
              New password
            </Text>
          </label>

          <InputField
            inputValue={password}
            placeholder={"Enter new password"}
            secureTextEntry={true}
            editable={true}
            onChangeValue={(text) => (
              setPasswordError(""), setPassword(text), verifyPassword(text)
            )}
            onFocus={() => setIsFocused(true)}
            onBlur={(value) => {
              ErrorCheck("password", value);
              setIsFocused(false);
            }}
            assistive={passwordError}
          />
          {isFocused && (
            <div
              style={{ alignSelf: "start", flexWrap: "wrap"}}
              className="generic-common-row-div"
            >
              {passwordCriteria.map((item, index) => {
                return (
                  <div key={index} style={{marginRight: 3}} className="generic-common-row-div">
                    <Text marginRight={3} variant={"r100"} marginTop={8}>
                      {`${item}: `}
                    </Text>
                    <Text
                      variant={"r100"}
                      color={
                        passwordVerification.includes(item) ? "red" : "green"
                      }
                      marginTop={8}
                    >
                      {`${passwordVerification.includes(item) ? "No" : "Yes"}${index !== 4 ? ",  " : ""}  `}
                    </Text>
                  </div>
                );
              })}
            </div>
          )}

          <label>
            <Text
              labelclass="login-form-label"
              variant="m300"
              marginTop={24}
              marginBottom={8}
              alignSelf={"start"}
            >
              Confirm new password
            </Text>
          </label>

          <InputField
            inputValue={confirmPassword}
            placeholder={"Confirm your password"}
            secureTextEntry={true}
            editable={true}
            onChangeValue={(text) => (
              setConfirmPasswordError(""), setConfirmPassword(text)
            )}
            onBlur={(value) => ErrorCheck("confirmPassword", value)}
            assistive={confirmPasswordError}
          />

          <div className="login-sign-in-up-container">
            <div className="bottomLogin-btn">
              <ButtonPrimary
                width={360}
                label={"Update password"}
                // onPress={() => navigate("/PasswordUpdated")}
                onPress={() => onSubmitPassword()}
              />
            </div>

            <div className="backTo-signIn">
              <Text
                cursor={"pointer"}
                onClick={() => navigate(-3)}
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--midBlue")}
                variant="m300"
                marginTop={30}
              >
                Back to Sign in
              </Text>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CreatePassword;
