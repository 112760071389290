import React from "react";
import "./CMS.css";
import { useNavigate } from "react-router-dom";

function Email() {
  const navigate = useNavigate();

  return (
    <div className="cms-page-wrapper">
    </div>
  );
}

export default Email;