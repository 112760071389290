import { CONNECT_WALLET_FOR_NFT } from '../actions';

export default function connectWalletForNFT(state = null, action = {}) {
  switch (action.type) {
    case CONNECT_WALLET_FOR_NFT: {
      return {
        ...state,
        connectWalletForNFT: action.payload,
      };
    }

    default:
      return state;
  }
}
