import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./ForgotPassword.css";
import Footer from "../../Component/FooterNew/FooterNew";
import Text from "../../Component/Common/Text";
import HoverBGButton from "../../Component/Common/Buttons/HoverBGButton/HoverBGButton";
import ButtonPrimary from "../../Component/Common/Buttons/ButtonPrimary/ButtonPrimary";
import CodesInputField from "../../Component/ForgottenPassword/CodesInputField";
import { ReactComponent as MailNotificationFilled } from "../../assets/svgs/MailNotificationFilled.svg";
import Banner from "../../Component/Common/Banner/Banner";
import { PasswordResetValidateOTP, RequestOTP } from "../../services/api";
import { SHOW_ACTIVITY_INDICATOR } from "../../actions";

function CodeSubmit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { emailAddress } = location.state;
  const [showBanner, setShowBanner] = useState(false);
  const [assistiveText, setAssistiveText] = useState("");
  const [OTPVerified, setOTPVerified] = useState("");
  const [reset, setReset] = useState(false);

  const passwordResetValidateOTPRequest = async (otp) => {
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    if (otp?.length === 6) {
      const params = {
        emailAddress: emailAddress,
        otp: otp,
      };
      const result = await PasswordResetValidateOTP(params);
      if (result?.status) {
        setAssistiveText("");
        setOTPVerified("yes");
        navigate("/CreatePassword", {
          state: {
            emailAddress: emailAddress,
          },
        });
      } else {
        setAssistiveText(result?.response?.errorMessage);
        setOTPVerified("no");
      }
    } else {
      setAssistiveText("Valid OTP is required");
      setOTPVerified("no");
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  const onResendOTP = async () => {
    const params = {
      emailAddress: emailAddress,
    };
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
    const result = await RequestOTP(params);
    if (result?.status) {
      setOTPVerified("yes");
      setAssistiveText("");
      setReset(true);
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  const onBackspacePressed = () => {
    setOTPVerified("");
    setAssistiveText("");
  };

  const OTPComplete = (newOTP) => {
    if (newOTP.length === 6) {
      passwordResetValidateOTPRequest(newOTP);
    }
  };

  return (
    <div className="page-wrapper-fp">
      <div className="login-box-container-fp check-yourEmail-container">
        <Banner
          text={"There’s an issue with your email address or password."}
          headingText={"Sign in failed"}
          showBanner={showBanner}
          type={"Error"}
          withIcon={true}
          withClose={true}
          onClose={() => setShowBanner(false)}
          position={"top"}
        />
        <div className="login-inner-box-container-fp">
          <HoverBGButton icon={<MailNotificationFilled />} size={80} />

          <div className="topLogin-text">
            <div className="topLogin-tittle-text">
              <Text
                textAlign={"center"}
                variant="m700"
                marginTop={30}
                marginBottom={24}
              >
                Check your email
              </Text>
            </div>

            <div className="bottomLogin-prg-text">
              <Text textAlign={"center"} variant="r300" marginBottom={32}>
                We’ve sent a verification code to{" "}
                <span>{emailAddress}</span>
              </Text>
            </div>
          </div>

          <CodesInputField
            backspacePressed={onBackspacePressed}
            assistive={assistiveText}
            numberOfFields={6}
            onComplete={(newOTP) => OTPComplete(newOTP)}
            success={OTPVerified}
            reset={reset}
            setReset={setReset}
            onCodeEnterFinished={() => {}}
          />
        </div>
        <div className="login-sign-in-up-container">
          <ButtonPrimary
            width={360}
            label={"Verify now"}
            onPress={() => navigate("/CreatePassword")}
            // onPress={() => onSubmitLogin()}
          />
          <div className="generic-common-row-div checkEmail-resend">
            <div className="checkEmail-receive">
              <Text onClick={() => {}} variant="m300" marginTop={36}>
                {"Didn’t receive the email? "}
              </Text>
            </div>

            <div className="clickHere-resend">
              <Text cursor={"pointer"}
                onClick={() => onResendOTP()}
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--midBlue")}
                variant="m300"
                marginTop={36}
                marginLeft={4}
              >
                {" Click here to resend"}
              </Text>
            </div>
          </div>
          <Text cursor={"pointer"}
            onClick={() => navigate(-2)}
            color={getComputedStyle(document.documentElement).getPropertyValue(
              "--midBlue"
            )}
            variant="m300"
            marginTop={45}
            marginLeft={4}
          >
            {"Back to Sign in"}
          </Text>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CodeSubmit;
