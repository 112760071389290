import React from "react";
import "./FooterNew.css";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { ReactComponent as Icon } from "../../assets/svgs/Icon.svg";
import { ReactComponent as AppleFilledWhite } from "../../assets/svgs/AppleFilledWhite.svg";
import { ReactComponent as AndroidFilledWhite } from "../../assets/svgs/AndroidFilledWhite.svg";
import ButtonPrimary from "../Common/Buttons/ButtonPrimary/ButtonPrimary";
import Text from "../../Component/Common/Text";

const Footer = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const navigateToAppleStore = () => {
    const url = "https://apps.apple.com/in/app/twotixx/id6451201695";
    if (url) {
      window.open(url, "_blank").focus();
    }
  };

  const navigateToPlaystore = () => {
    const url = "https://play.google.com/store/apps/details?id=com.twotixx";
    if (url) {
      window.open(url, "_blank").focus();
    }
  };

  const handleLinkClickTwitter = (e) => {
    e.preventDefault();
    // window.open("www.x.com/twotixxofficial", "_blank");
    window.open("https://www.x.com/twotixxofficial", "_blank").focus();
  };

  return (
    <div className="footer-d content5">
      <div className="container1 footer-topInner-link">
        <div className="content4">
          <div className="logo-and-links">
            <div className="logo-and-supporting-text">
              <div style={{cursor: pathname === '/Home' ? null : 'pointer'}} onClick={() => navigate("/Home")} className="footerLogo">
                <Icon width={64} height={64} style={{ marginTop: -15 }} />
              </div>
            </div>

            <div className="footer-links-section">
              {/* <div className="linksCont footer-links-company"> */}
                {/* <h4>Company</h4> */}
                {/* <Link to="/About">
                  <text>About</text>
                </Link> */}

                {/* <Link onClick={() => logOutRequest()}>
                  <text>Event login</text>
                </Link> */}
              {/* </div> */}
              <div className="linksCont footer-links-software">
                <h4>Contact</h4>
                {/* <Link to="/TicketGuard">
                  <text>TicketGuard™</text>
                </Link> */}

                <Link to="mailto:help@twotixx.com">
                  <text>Email</text>
                </Link>

                <Link to="#" onClick={handleLinkClickTwitter}>
                  <text>Twitter / X</text>
                </Link>

                {/* <a style={{ marginTop: 32 }}
                  href="https://www.x"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Twitter / X
                </a> */}



              </div>
              {/* <div className="linksCont footer-links-contact"> */}
                {/* <h4>Contact</h4>
                <Link to="mailto:help@twotixx.com">
                  <text>Email</text>
                </Link>

                <Link to="/Twitter">
                  <text>Twitter</text>
                </Link> */}
              {/* </div> */}
            </div>
          </div>
          <div className="app-store-actions">
            {/* <div className="heading4">Get the app</div> */}
            <div className="actions">
              <ButtonPrimary
                textWrap={"noWrap"}
                width={180}
                label={"Download on iOS"}
                icon={<AppleFilledWhite />}
                showLeftIcon={true}
                onPress={navigateToAppleStore}
              />
              <ButtonPrimary
                style={{ marginTop: 24 }}
                label={"Download on Android"}
                icon={<AndroidFilledWhite />}
                showLeftIcon={true}
                onPress={navigateToPlaystore}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="generic-common-divider"/>
      <div style={{alignSelf: 'end'}} className="generic-common-row-div footer-bottomBar-link">
       <Text cursor={'pointer'} color={"var(--newTextBody)"} variant={"m200"}>Terms Of Service</Text>
       <Text marginLeft={8} marginRight={8}  color={"var(--newTextBody)"} variant={"m200"}>|</Text>
       <Text cursor={'pointer'} variant={"m200"}  color={"var(--newTextBody)"}>Privacy Policy</Text>
      </div>
    </div>
  );
};

export default Footer;
