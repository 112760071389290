import { EVENT_DATA } from "../actions";

export default function eventData(state = null, action = {}) {
  switch (action.type) {
    case EVENT_DATA: {
      return {
        ...state,
        eventData: action.payload,
      };
    }

    default:
      return state;
  }
}
