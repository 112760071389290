import { EVENT_CATEGORY_DATA } from "../actions";
export default function eventCategoryData(state = [], action) {
  switch (action.type) {
    case EVENT_CATEGORY_DATA: {
      return {
        ...state,
        eventCategoryData: action.payload,
      };
    }
    default:
      return state;
  }
}
