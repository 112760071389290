import { urlReg } from "../../constants/regex";
import { useState } from "react";

const useUrlVerification = () => {
  const [isVerifiedUrl, setIsVerifiedUrl] = useState(false);

  const verifyUrl = (url) => {
    setIsVerifiedUrl(
      urlReg.test(url) || /^(www\.)?[^ "]+(\.[a-zA-Z]{2,})+$/.test(url)
    );
  };

  return { isVerifiedUrl, verifyUrl };
};
export default useUrlVerification;
