import { EVENT_TYPE } from "../actions";

export default function eventType(state = null, action = {}) {
  switch (action.type) {
    case EVENT_TYPE: {
      return {
        ...state,
        eventType: action.payload,
      };
    }

    default:
      return state;
  }
}
