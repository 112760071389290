import React, { useEffect, useRef, useState } from "react";
import "./SearchInputField.css";
import { ReactComponent as CloseFilled } from "../../../../assets/svgs/CloseFilled.svg";
import { ReactComponent as SearchFilled } from "../../../../assets/svgs/SearchFilled.svg";

export default function SearchInputField({
  placeholder,
  onChangeSearchValue,
  needTextDescription,
  editable,
  style,
  autoFocused,
  inputStyles,
}) {
  // constants and values
  const [filterString, setFilterString] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  useEffect (() => {
    autoFocused && inputRef.current.focus(); 
  }, [autoFocused]);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const clearText = () => {
    setFilterString("");
    searchItems("");
    setIsFocused(false);
    onChangeSearchValue('clear')
  };

  const searchItems = (event) => {
    setFilterString(event ? event.target.value : "");
    onChangeSearchValue(needTextDescription ? event : event?.target?.value);
  };
  const onClickOutsideListener = () => {
    handleBlur();
    document.removeEventListener("click", onClickOutsideListener);
  };

  return (
    <div
      onMouseLeave={() => {
        document.addEventListener("click", onClickOutsideListener);
      }}
      className="searchInputField-searchContentView"
      style={{
        backgroundColor: editable
          ? getComputedStyle(document.documentElement).getPropertyValue(
              "--white"
            )
          : getComputedStyle(document.documentElement).getPropertyValue(
              "--veryLightGrey"
            ),
        boxShadow: editable ? "0px 1px 2px rgba(0, 0, 0, 0.1)" : "none",
        borderColor: isFocused
          ? getComputedStyle(document.documentElement).getPropertyValue(
              "--midBlue"
            )
          : getComputedStyle(document.documentElement).getPropertyValue(
              "--lightGrey"
            ),
        ...style,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flex: 1,
        }}
      >
        {editable ? (
          <SearchFilled
            style={{
              width: 20,
              height: 20,
              marginLeft: 16,
              marginRight: 12,
            }}
          />
        ) : (
          <SearchFilled
            stroke={getComputedStyle(document.documentElement).getPropertyValue(
              "--midGrey"
            )}
            style={{
              width: 20,
              height: 20,
              marginLeft: 16,
              marginRight: 12,
            }}
          />
        )}
        <input
          ref={inputRef}
          disabled={editable ? false : true}
          style={{ border: "none", borderWidth: 0, borderColor: "transparent", ...inputStyles }}
          type={"text"}
          className="searchInputField-inputFieldPrimary"
          placeholder={placeholder}
          autoCapitalize={"words"}
          onFocus={handleFocus}
          value={filterString}
          onChange={searchItems}
        />
      </div>
      {isFocused && filterString.trim() !== "" && (
        <div
          className="searchInputField-clearSearchContainer"
          onClick={() => clearText()}
        >
          <CloseFilled
            fill={getComputedStyle(document.documentElement).getPropertyValue(
              "--veryDarkBlue"
            )}
          />
        </div>
      )}
    </div>
  );
}
