import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./BusinessSignUp.css";
import { useDispatch } from "react-redux";
import { ReactComponent as BusinessSignUpMaskLeft } from "../../assets/svgs/BusinessSignUpMaskLeft.svg";
import { ReactComponent as BusinessSignUpMaskRight } from "../../assets/svgs/BusinessSignUpMaskRight.svg";
import { ReactComponent as BusinessSignUpMaskMiddle } from "../../assets/svgs/BusinessSignUpMaskMiddle.svg";
import { ReactComponent as VerifiedWhiteFilled } from "../../assets/svgs/VerifiedWhiteFilled.svg";
import Footer from "../../Component/FooterNew/FooterNew";
import Text from "../../Component/Common/Text";
import HoverBGButton from "../../Component/Common/Buttons/HoverBGButton/HoverBGButton";
import ButtonPrimary from "../../Component/Common/Buttons/ButtonPrimary/ButtonPrimary";
import {
  sendVerificationEmail
} from "../../services/api";
import {
  BANNER_CONTENT,
  SHOW_ACTIVITY_INDICATOR,
} from "../../actions";

function VerifyEmail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [brandEmail, setBrandEmail] = useState("");

  const handleButtonClick = () => {
    // Open the default email client with a blank compose window
    window.location.href = "mailto:";
  };

  const sendVerificationEmailRequest = async () => {
    
        dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
        const result = await sendVerificationEmail({ email: localStorage.getItem("emailAddress") });
        if (result?.status) {
            dispatch({
                type: BANNER_CONTENT,
                payload: {
                  text: "Successfully sent verification email.",
                  headingText: "Verification email sent...",
                  showBanner: true,
                  type: "Success",
                  withClose: true,
                  withIcon: true,
                  position: "top",
                  paddingLeft: "8px",
            parentContainerStyle: {
              top: "8px",
               zIndex: 15,
            },
                },
              });
          } else {
            dispatch({
                type: BANNER_CONTENT,
                payload: {
                  text: result?.response || "Error sending verification email",
                  headingText: "Error sending verification email",
                  showBanner: true,
                  type: "Error",
                  withClose: true,
                  withIcon: true,
                  position: "top",
                  paddingLeft: "8px",
            parentContainerStyle: {
              top: "8px",
               zIndex: 15,
            },
                },
              });
          }
          dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
    }


  return (
    <div className="business-signup-page-wrapper">

      <div className="business-signup-inner-container">
        <BusinessSignUpMaskMiddle
          height={150}
          style={{ position: "absolute", top: 0, zIndex: 3 }}
          className="businessSignUp-top-bg"
        />
        <BusinessSignUpMaskLeft
          style={{ position: "absolute", left: 0, top: 0, zIndex: 2 }}
          className="businessSignUp-top-bg2"
        />
        <BusinessSignUpMaskRight
          style={{ position: "absolute", right: 0, top: 0, zIndex: 2 }}
          className="businessSignUp-top-bg3"
        />
        <div className="businessSignUp-top-plusIcon">
          <HoverBGButton
            style={{
              borderWidth: 6,
              borderColor: "var(--veryLightGrey)",
              zIndex: 7,
            }}
            withBorder={true}
            icon={<VerifiedWhiteFilled />}
            size={120}
          />
        </div>
        <div className="tittle">
          <Text
            textAlign={"center"}
            variant="m700"
            marginTop={30}
            marginBottom={24}
          >
            Verify your account
          </Text>
        </div>

        <div className="subTittle">
          <Text textAlign={"center"} variant="r300">
          {'We’ve sent an email to '}
      <span style={{ color: 'var(--midBlue)' }}>{localStorage.getItem("emailAddress")}</span>
      {'. Please click the link inside to verify your account and proceed with creating your event.'}
          </Text>
        </div>

        <div style={{alignItems: 'center',  display: 'flex', flexDirection: 'column'}}  className="saveCont-btn-BusinessSignUp1">
          <ButtonPrimary
            style={{  marginTop: 64, marginBottom: '0px !important' }}
            width={328}
            label={'Resend email verification'}
            onPress={() => sendVerificationEmailRequest()}
          />
          <Text cursor={"pointer"}
                  onClick={() => navigate('/UpdateEmailVerifyEmail')}
                  color={"var(--midBlue)"}
                  variant="m300"
                  marginTop={24}
                >
                  Change email address
                </Text>


<div className="go-to-login-verify-email">
                <Text cursor={"pointer"}
                  onClick={() => navigate('/Login')}
                  color={"var(--twotixx-text-midnight-blue)"}
                  variant="m400"
              
                >
                  Already verified your account?
                </Text>
                <Text marginLeft={4} cursor={"pointer"}
                  onClick={() => navigate('/Login')}
                  color={"var(--midBlue)"}
                  variant="m400"
            
                >
                  Log in here
                </Text>
                </div>
        </div>

        
      </div>
      <Footer />
    </div>
  );
}

export default VerifyEmail;
