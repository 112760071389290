import React, { useState, useRef, useEffect } from "react";
import "./CodesInputField.css";
import Text from "../../Component/Common/Text";

export default function CodesInputField({
  numberOfFields,
  onComplete,
  backspacePressed,
  reset,
  onCodeEnterFinished,
  setReset,
  ...props
}) {
  // constants and values
  const [otp, setOTP] = useState(new Array(numberOfFields).fill(""));
  const refs = useRef([]);
  const { success, assistive } = props;

  useEffect(() => {
    setOTP(new Array(numberOfFields).fill(""));
    setReset(false);
    refs.current[0].focus();
  }, [reset]);

  useEffect(() => {
    const hasCompleteOtp = otp.filter((value) => value !== "").length === 4;
    if (hasCompleteOtp) {
      onCodeEnterFinished(); // Call the OTP submit function here
    }
  }, [otp]);

  // handle type event to store and send the OTP to the Parent component
  const handleChange = async (index, value) => {
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);
    if (newOTP.every((value) => value !== "")) {
      if (onComplete) {
        onComplete(newOTP.join(""));
      }
    }
  };
  // handle the Backspace event and set focus to the required Input field
  const handleBackspace = (index) => {
    // if (index > 0 && otp[index] === "") {
    if (index > 0) {
      backspacePressed();
      //   otp[index - 1] = "";
      //   let newOTPValues = [...otp];
      //   newOTPValues[index - 1] = "";
      //   setOTP(newOTPValues);
      refs.current[index - 1].focus();
    }
  };

  // check whether Backspace event called and accordingly set focus to the required Input field
  const handleKeyPress = (index, event) => {
    if (event.nativeEvent.inputType === "deleteContentBackward") {
      handleBackspace(index);
    } else if (index < numberOfFields - 1) {
      refs.current[index + 1].focus();
    }
  };
  // child component added to show and getting input codes
  const renderFields = () => {
    const fields = [];
    for (let i = 0; i < numberOfFields; i++) {
      const isFirst = i === 0;
      fields.push(
        <div
          className="input-code-field-container"
          style={{
            textAlign: "center",
            backgroundColor: getComputedStyle(
              document.documentElement
            ).getPropertyValue("--red"),
            borderColor:
              otp.includes("") === false && success === "no"
                ? getComputedStyle(document.documentElement).getPropertyValue(
                    "--red"
                  )
                : otp.includes("") === false && success === "yes"
                ? getComputedStyle(document.documentElement).getPropertyValue(
                    "--green"
                  )
                : "#EBEBEB",
          }}
        >
          <input
            id="codeField"
            className="input-code-field"
            key={i}
            type="text"
            ref={(ref) => (refs.current[i] = ref)}
            placeholder={"-"}
            style={{
              color:
                success === "no"
                  ? getComputedStyle(document.documentElement).getPropertyValue(
                      "--red"
                    )
                  : getComputedStyle(document.documentElement).getPropertyValue(
                      "--midBlue"
                    ),
              width: "100%",
              height: "100%",
              textAlign: "center",
              borderStyle: "0 none !important",
            }}
            value={otp[i]}
            maxLength={1}
            contentEditable={true}
            onChange={(event) => {
              if (
                /[0-9]/.test(event.target.value) ||
                event.nativeEvent.inputType === "deleteContentBackward"
              ) {
                handleChange(i, event.target.value);
                handleKeyPress(i, event);
              } else {
                event.preventDefault();
              }
            }}
            onPaste={(event) => {
              event.preventDefault();
              const pasteData = event.clipboardData.getData("text");
              if (/^\d+$/.test(pasteData)) {
                const newOtp = otp.slice();
                for (
                  let j = 0;
                  j < pasteData.length && i + j < numberOfFields;
                  j++
                ) {
                  newOtp[i + j] = pasteData[j];
                }
                setOTP(newOtp);
                if (newOtp.every((value) => value !== "")) {
                  if (onComplete) {
                    onComplete(newOtp.join(""));
                  }
                }
              }
            }}
          />
        </div>
      );
    }
    // Check if otp array contains 6 non-empty values
    return fields;
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "center",
      }}
    >
      <div className="code-input-container">{renderFields()}</div>
      {assistive && (
        <Text
          marginTop={8}
          variant={"r200"}
          color={getComputedStyle(document.documentElement).getPropertyValue(
            "--red"
          )}
        >
          {assistive}
        </Text>
      )}
    </div>
  );
}
