import { SELECTED_EVENT_DATA } from '../actions';

export default function selectedEventData(state = null, action = {}) {
  switch (action.type) {
    case SELECTED_EVENT_DATA: {
      return {
        ...state,
        selectedEventData: action.payload,
      };
    }
    default:
      return state;
  }
}
