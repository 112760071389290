import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "./CentralModal.css";
import Text from "../Text";
import HoverBGButton from "../Buttons/HoverBGButton/HoverBGButton";
import ButtonPrimary from "../Buttons/ButtonPrimary/ButtonPrimary";
import RadioButton from "../../Common/Controls/RadioButton";
import { ReactComponent as CloseFilled } from "../../../assets/svgs/CloseFilled.svg";
import { useEffect } from "react";

export default function CentralModalOptions({
  icon,
  title,
  description,
  show,
  onClose,
  shouldShowButtons,
  onButton1Clicked,
  onButton2Clicked,
  button1Title,
  button2Title,
  selectedOptionsIndex,
  onChangeStatus,
}) {
  const kYCOptions = [
    {
      text: "Approved",
      value: 1,
    },
    {
      text: "Rejected",
      value: 2,
    },
    {
      text: "Checked out",
      value: 3,
    },
    {
      text: "Not Entered",
      value: 4,
    },
  ];

  const [selectedIndex, setSelectedIndex] = useState(selectedOptionsIndex);
  const { pathname } = useLocation();

  useEffect(() => {
    setSelectedIndex(selectedOptionsIndex);
  }, [selectedOptionsIndex]);

  if (!show) return null;

  return (
    <div className="central-modal-overlay">
      <div style={{ width: 600 }} className="central-modal">
        <div className="central-modal-close" onClick={onClose}>
          <CloseFilled style={{cursor: 'pointer'}}/>
        </div>
        <div className="central-modal-content">
          <HoverBGButton
            backgroundHover={
              pathname === "/Home" &&
              getComputedStyle(document.documentElement).getPropertyValue(
                "--hoverGreen"
              )
            }
            icon={icon}
            size={48}
          />
          <Text marginTop={24} variant="m600">
            {title}
          </Text>
          <Text marginTop={16} marginBottom={16} variant="r400">
            {description}
          </Text>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: kYCOptions.length > 3 ? "100%" : "55%",
              marginTop: 36,
              marginBottom: 12,
            }}
          >
            <RadioButton
              // vertically={true}
              twoColumn={true}
              data={kYCOptions}
              selectIndex={selectedOptionsIndex}
              onSelect={(item, index) => {
                onChangeStatus && onChangeStatus(index);
                setSelectedIndex(index);
              }}
            />
          </div>
          {shouldShowButtons && (
            <div
              className="central-bottom-button-container"
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 24,
                marginBottom: 16,
              }}
            >
              <ButtonPrimary
                isSecondary={true}
                label={button1Title}
                onPress={() => (
                  onButton1Clicked(), setSelectedIndex(selectedOptionsIndex)
                )}
              />
              <ButtonPrimary
                disabled={selectedOptionsIndex === selectedIndex ? true : false}
                label={button2Title}
                onPress={() =>
                  selectedOptionsIndex !== selectedIndex &&
                  (onButton2Clicked(selectedIndex),
                  setSelectedIndex(selectedOptionsIndex))
                }
                style={{ marginLeft: 32 }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
