import React from "react";
import { useLocation } from "react-router-dom";
import "./CentralModal.css";
import Text from "../Text";
import HoverBGButton from "../Buttons/HoverBGButton/HoverBGButton";
import ButtonPrimary from "../Buttons/ButtonPrimary/ButtonPrimary";
import { ReactComponent as CloseFilled } from "../../../assets/svgs/CloseFilled.svg";

export default function CentralModalSuccess({
  icon,
  title,
  description,
  show,
  onClose,
  shouldShowButtons,
  onButton1Clicked,
  onButton2Clicked,
  button1Title,
  button2Title,
  renderContent,
  isDisabledButton1,
  type,
}) {
  const { pathname } = useLocation();
  if (!show) return null;

  return (
    <div className="central-modal-overlay">
      <div
        style={{ width: type === "perosnalDetails" ? 692 : 600 }}
        className="central-modal"
      >
        <div
          className={
            type === "perosnalDetails"
              ? "generic-common-row-div-space-between"
              : "central-modal-close"
          }
          onClick={onClose}
        >
          {type === "perosnalDetails" && (
            <Text style={{}} variant="m600">
              {title}
            </Text>
          )}
          <CloseFilled style={{cursor: 'pointer'}}/>
        </div>
        <div className="central-modal-content">
          {icon && (
            <HoverBGButton
              backgroundHover={
                pathname === "/Home" &&
                getComputedStyle(document.documentElement).getPropertyValue(
                  "--hoverGreen"
                )
              }
              icon={icon}
              size={48}
            />
          )}
          {type !== "perosnalDetails" && (
            <Text marginTop={24} variant="m600">
              {title}
            </Text>
          )}
          <Text
            marginTop={type !== "perosnalDetails" ? 16 : 40}
            marginBottom={16}
            variant={type !== "perosnalDetails" ? "r400" : "r300"}
          >
            {description}
          </Text>
          {renderContent && renderContent()}
          {shouldShowButtons && (
            <div
              className="central-bottom-button-container"
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 24,
                marginBottom: 16,
              }}
            >
              <ButtonPrimary
                disabled={isDisabledButton1}
                isSecondary={
                  title === "Save your draft?" || title === "Add Bolt On"
                    ? false
                    : true
                }
                label={button1Title}
                onPress={() => onButton1Clicked()}
              />

              <ButtonPrimary
                isSecondary={
                  title === "Save your draft?" || title === "Add Bolt On"
                    ? true
                    : false
                }
                label={button2Title}
                onPress={() => onButton2Clicked()}
                style={{ marginLeft: 32 }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
