import React from "react";
import "./PaymentDetails.css";
import InputField from "../Common/InputFields/InputFieldPrimary/InputFieldPrimary";
import ButtonPrimary from "../Common/Buttons/ButtonPrimary/ButtonPrimary";
import ButtonPill from "../../Component/Common/Buttons/ButtonPill";
import TooltipOptionPopup from "../../Component/Common/TooltipPopup/TooltipOptionPopup";
import { ReactComponent as SuccessFilledWhite } from "../../assets/svgs/SuccessFilledWhite.svg";
import Text from "../Common/Text";

export default function PaymentDetails({
  currentBalance,
  currentThreshold,
  onPayNowBtnClicked,
  onAddPaymentMethodBtnClicked,
  onEditThresholdBtnClicked,
  onViewInvoiceHistoryBtnClicked,
  paymentData,
  isOpenPaymentOptionPopup,
  onPaymentOptionClicked,
  taxValue
}) {
  return (
    <div
      style={{ width: "100%" }}
      className="generic-common-column-div payment-verified-items"
    >
      <div style={{ width: "100%" }} className="payment-verified-container">
        <div
          className="payment-verified-inner"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{ width: "100%" }}
            className="generic-common-column-div payment-verified-inner-lft"
          >
            <Text textclass="Heading-div" variant={"m500"}>
              Current balance
            </Text>
            {currentBalance?.length === 0 ? (
              <div
                style={{ marginTop: 32 }}
                className="generic-common-row-centerd-div"
              >
                <SuccessFilledWhite width={24} height={24} />
                <Text color={'var(--green)'} marginLeft={8} variant={"m300"}>
                  You’re up to date, you have no balances to pay.
                </Text>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <div className="payment-row-outer">
                  {currentBalance &&
                    currentBalance?.map((item, index) => {
                      return (
                        <div className="payment-row-inner">
                          <div>
                            <Text textclass="Heading-div" variant={"m200"}>
                              {`${item?.currencyName?.toUpperCase()} (${
                                item?.code
                              })`}
                            </Text>

                            <div
                              style={{ marginTop: "4px", marginBottom: '6px' }}
                              className="generic-common-row-div"
                            >
                              <Text
                                color={"var(--midBlue)"}
                                textclass="Heading-div"
                                variant={"b600"}
                              >
                                {`${item?.symbol || ""}${item?.unpaidFees}`}
                              </Text>
                             {taxValue && <Text
                                marginLeft={4}
                                color={"var(--midBlue)"}
                                textclass="Heading-div"
                                variant={"r300"}
                              >
                                {` + ${taxValue} (Tax)`}
                              </Text>}
                            </div>

                            <Text color={"var(--textDisabled)"} variant={"r300"}>
                  {`You’ll be charged on this Monday or when you reach ${currentThreshold[index]?.currency.symbol}${currentThreshold[index]?.amount} in fees,
                  which ever comes first.`}
                </Text>
                          </div>
                          {item.unpaidFees && item?.unpaidFees !== 0 ? (
                            <ButtonPrimary
                              disabled={!paymentData?.length}
                              label={"Pay Now"}
                              onPress={() => onPayNowBtnClicked(item, index)}
                              width={296}
                            />
                          ) : null}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        style={{ width: "100%", marginTop: 40 }}
        className="payment-verified-container"
      >
        <div
          className="payment-verified-inner"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{ width: "50%" }}
            className="generic-common-column-div payment-verified-inner-lft"
          >
            <Text textclass="Heading-div" variant={"m500"}>
              Payment methods
            </Text>
            <Text marginTop={24} variant={"r300"}>
              Add a debit or credit card to pay your fees.
            </Text>
          </div>

          <ButtonPrimary
            isSecondary={true}
            label={"Add payment method"}
            onPress={() => onAddPaymentMethodBtnClicked()}
            width={296}
          />
        </div>

        {paymentData.length > 0 && (
          <div
            className="payment-items-box"
            style={{
              width: "100%",
              marginTop: 40,
            }}
          >
            <div style={{}} className="generic-common-divider" />
            <div
              className="payment-items-box-inner"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gridGap: 24,
                rowGap: 0,
              }}
            >
              {paymentData.map((item, ind) => {
                return (
                  <div
                    style={{ padding: 20, width: "100%" }}
                    className="payment-verified-container"
                  >
                    <div
                      style={{ width: "100%" }}
                      className="generic-common-row-div-space-between-not-centered"
                    >
                      <div className="generic-common-row-div-top">
                        <img
                          style={{ height: 36, width: 48 }}
                          src={`data:image/jpeg;base64,${item?.brandLogoBase64}`}
                          alt="Base64"
                        />
                        <div
                          style={{
                            marginLeft: 16,
                          }}
                          className="generic-common-column-div"
                        >
                          <div className="generic-common-row-div paymentMethod-inner-cardDefault">
                            <div className="paymentMethod-inner-card-lft">
                              <Text
                                marginTop={-6}
                                marginRight={12}
                                variant={"m300"}
                              >
                                {`${item?.brand} • ${item?.last4}`}
                              </Text>
                            </div>

                            {item?.isDefault && (
                              <div className="paymentMethod-inner-Default-rht">
                                <ButtonPill
                                  type={"generic"}
                                  varient={"small"}
                                  label={"Default"}
                                />
                              </div>
                            )}
                          </div>
                          <Text
                            marginTop={4}
                            variant={"r100"}
                          >{`Expires ${item?.expMonth
                            .toString()
                            .padStart(2, "0")}/${item?.expYear
                            .toString()
                            .slice(-2)}`}</Text>
                        </div>
                      </div>
                      <div>
                        <TooltipOptionPopup
                          selectedIndex={ind}
                          isOpen={isOpenPaymentOptionPopup}
                          onOptionClicked={(index) =>
                            onPaymentOptionClicked(index, ind)
                          }
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>{" "}
          </div>
        )}
      </div>

      <div
        style={{ width: "100%", marginTop: 40 }}
        className="payment-verified-container"
      >
        <div
          className="payment-verified-inner"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{ width: "100%" }}
            className="generic-common-column-div payment-verified-inner-lft"
          >
            <Text textclass="Heading-div" variant={"m500"}>
              Payment threshold
            </Text>
            {currentThreshold?.length > 0 ? <div className="payment-row-outer">
              {currentThreshold?.map((item, index) => {
                return (
                  <div className="payment-row-inner">
                    <div>
                      <Text textclass="Heading-div" variant={"m200"}>
                        {`${item?.currency?.name?.toUpperCase()} (${
                          item?.currency.code
                        })`}
                      </Text>

                      <div
                        style={{ margin: "4px 0px" }}
                        className="generic-common-row-div"
                      >
                        <Text
                          color={"var(--midBlue)"}
                          textclass="Heading-div"
                          variant={"m500"}
                        >
                          {`${item?.currency.symbol}${item?.amount}`}
                        </Text>
                      </div>
                      <Text
                        color={"var(--textDisabled)"}
                        textclass="Heading-div"
                        variant={"r200"}
                      >
                        {`You’ll be charged on this Monday or when you reach ${item?.currency.symbol}${item?.amount} in fees, which ever comes first.`}
                      </Text>
                    </div>
                    <ButtonPrimary
                      isSecondary={true}
                      label={"Edit threshold"}
                      onPress={() => onEditThresholdBtnClicked(item, index)}
                      width={296}
                    />
                  </div>
                );
              })}
            </div> : <Text marginTop={32} variant={"m300"}>
                  No threshold set yet.
                </Text> }
          </div>
        </div>
      </div>

      <div
        style={{ width: "100%", marginTop: 40 }}
        className="payment-verified-container"
      >
        <div
          className="payment-verified-inner"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{ width: "50%" }}
            className="generic-common-column-div payment-verified-inner-lft"
          >
            <Text textclass="Heading-div" variant={"m500"}>
              Payment invoices
            </Text>

            <Text marginTop={16} variant={"r300"}>
              View and download your invoice history.
            </Text>
          </div>

          <ButtonPrimary
            isSecondary={true}
            label={"View invoice history"}
            onPress={() => onViewInvoiceHistoryBtnClicked()}
            width={296}
          />
        </div>
      </div>
    </div>
  );
}
