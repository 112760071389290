import { SELECTED_EVENT_CATEGORY_DATA } from "../actions";

export default function selectedEventCategoryData(state = null, action = {}) {
  switch (action.type) {
    case SELECTED_EVENT_CATEGORY_DATA: {
      return {
        ...state,
        selectedEventCategoryData: action.payload,
      };
    }
    default:
      return state;
  }
}
