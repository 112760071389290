// import { Alert } from 'react-native';
import Api from "../utils/Api";

export function setLoginDetails(token) {
  Api.defaultHeader({
    authorization: `Bearer ${token}`,
  });
}

function getStatus(response) {
  if (response.status === 200 || response.status === 204) {
    return { status: true, response: response.response };
  } else if (response.status === 502) {
    // Alert.alert('Internet not available', 'Please check your internet setting');
    return { status: false };
  } else {
    if (response.status === 400) {
      return { status: false, response: response.response, isWithError: true };
    } else if (response.status === 428) {
      // Alert.alert(response, 'Something Wrong');
      return { status: false, response: response.response };
    } else {
      return { status: false, response: response };
    }
  }
}

// login
export async function login(params) {
  try {
    const res = await Api.POST("Account/Login", params);
    if (res) {
      setLoginDetails(res.response);
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// send otp forgot password
export async function RequestOTP(params) {
  try {
    const res = await Api.POST("Account/admin/forgot-password", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// send otp forgot password
export async function PasswordResetValidateOTP(params) {
  try {
    const res = await Api.POST("Account/admin/validate-otp", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// email existence check
export async function emailInUse(params) {
  try {
    const res = await Api.POST("Register/EmailInUse", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}
// SendVerificationEmail
export async function sendVerificationEmail(params) {
  try {
    const res = await Api.GET(
      `Register/SendVerificationEmail?emailAddress=${params.email}`
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// terms of service
export async function termsOfService(params) {
  try {
    const res = await Api.GET("Support/TermsAndConditions", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// privacy policy
export async function privacyPolicy(params) {
  try {
    const res = await Api.GET("Support/PrivacyPolicy", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// sign up
export async function signUpMobileWallet(params) {
  try {
    const res = await Api.POST("Register/MobileWallet", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// delete user account
export async function deleteUserAccount() {
  try {
    const res = await Api.DELETE("Account/Delete");
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// request OTP for forgot password
export async function requestOTP(params) {
  try {
    const res = await Api.POST("Account/RequestOTP", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res?.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// otp verification for forgot password
export async function verifyOTP(params) {
  try {
    const res = await Api.POST("Account/PasswordResetValidateOTP", params);
    if (res) {
      setLoginDetails(res.response.jwtToken);
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// reset password for forgot password
export async function resetPassword(params) {
  try {
    const res = await Api.POST("Account/admin/reset-password", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get event with event status
export async function getEvents(params) {
  try {
    const res = await Api.GET(`MobileWallet/GetEvents/${params}`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get event details from event id
export async function getEvent(params) {
  try {
    const res = await Api.GET(`MobileWallet/GetEvent/${params}`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get user details
export async function getUserDetails(params) {
  try {
    const res = await Api.GET("Account/GetUser", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// Get Entered List /api/Venue/{eventId}/GetEnteredList
export async function getEnteredList(params) {
  try {
    const res = await Api.POST(`EventManager/GetEventAttendeeStatus`, params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res?.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// Get Entered List /api/Venue/{eventId}/GetEnteredList
export async function getEnteredListWithPagination(params) {
  try {
    const res = await Api.POST(
      `EventManager/v2/GetEventAttendeeStatus`,
      params
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res?.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get attendee details
export async function getAttendeeDetails(params) {
  try {
    const res = await Api.POST(`EventManager/GetEventAttendeeDetails`, params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// refresh jwt token
export async function refreshToken() {
  try {
    const res = await Api.GET("Account/RefreshToken");
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// assign ticket
export async function assignTicket(params) {
  try {
    const res = await Api.PUT("MobileWallet/Ticket/Assign", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// unassign ticket
export async function unAssignTicket(params) {
  try {
    const res = await Api.PUT(`MobileWallet/Ticket/UnAssign/${params}`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get all guests
export async function getAllGuest() {
  try {
    const res = await Api.GET("MobileWallet/Guest/GetAll");
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get all unassigned guests
export async function getAllUnAssignedGuest(params) {
  try {
    const res = await Api.GET(`MobileWallet/Guest/GetUnassigned/${params}`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// check guest eligibility
export async function checkEligibility(params) {
  try {
    const res = await Api.PUT("MobileWallet/Ticket/CheckEligibility", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// to get QR for scanning ticket
export async function ticketScanAction(params) {
  try {
    const res = await Api.POST("MobileWallet/TicketScan/Base64", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// waiting for scanning ticket
export async function isTicketScanComplete(params) {
  try {
    const res = await Api.POST(
      `MobileWallet/IsTicketScanComplete?scanCode=${params}`
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// result from scanning ticket
export async function ticketScanResult(params) {
  try {
    const res = await Api.POST("MobileWallet/GetTicketScanResult", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// to get QR for scanning ticket
export async function checkOut(params) {
  try {
    const res = await Api.POST("MobileWallet/CheckOut", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get verified or not verified user id
export async function getIsUserVerified(params) {
  try {
    const res = await Api.PUT(`Account/Verify/${params.userId}`, params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// change password
export async function updateUserProfile(params) {
  try {
    const res = await Api.PUT("Account/UpdateUser", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get user details
export async function logOutUser(params) {
  try {
    const res = await Api.GET("Account/Logout", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get guest list
export async function getGuestList(params) {
  try {
    const res = await Api.GET("MobileWallet/Guest/GetAll", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// add guest
export async function addGuests(params) {
  try {
    const res = await Api.POST("MobileWallet/Guest/Add", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

//delete guest
export async function deleteGuest(params) {
  try {
    const res = await Api.DELETE(
      `MobileWallet/Guest/Remove/${params.guestUserId}`,
      params
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

//update guest details
export async function editGuests(params) {
  try {
    const res = await Api.PUT("MobileWallet/Guest/Edit", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// Event list for guest
export async function getGuestEventList(params) {
  try {
    const res = await Api.GET(
      `MobileWallet/Guest/GetEvents/${params.guestId}/${params.eventStatus}`
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get user details
export async function getGuestDetails(params) {
  try {
    const res = await Api.GET(`MobileWallet/Guest/Get/${params.guestId}`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get wallet list api is not ready yet
export async function getWalletList(params) {
  try {
    const res = await Api.GET("MobileWallet/Guest/GetAll", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get all wallets
export async function getAllWalletAssets() {
  try {
    const res = await Api.GET("Wallet/Get/All");
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// add or update wallet name
export async function updateWalletFriendlyName(params) {
  try {
    const res = await Api.POST(
      `Wallet/UpdateWalletAlias?walletAddress=${params.walletAddress}`,
      params
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

//remove wallet name
export async function removeWallet(params) {
  try {
    const res = await Api.DELETE(`Wallet/RemoveWallet?walletAddress=${params}`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get Marketplace event with event status
export async function getMarketplaceEvents(params) {
  try {
    const res = await Api.GET(
      `MobileWallet/MarketPlace/GetEvents?category=${params.category}`,
      params
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get Marketplace event with event status
export async function getMarketplaceEventDetails(params) {
  try {
    const res = await Api.GET(`MobileWallet/MarketPlace/GetEvent/${params}`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

//Buy ticket through marketplace
export async function buyTicket(params) {
  try {
    const res = await Api.PUT("MobileWallet/MarketPlace/Buy", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get all collectibles
export async function getAllCollectibles() {
  try {
    const res = await Api.GET(`Wallet/Get/Collectibles`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// add or update wallet name
export async function claimCollectable(params) {
  try {
    const res = await Api.POST(`Wallet/Collectible/Mint`, params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get collectible details
export async function getCollectiblesDetails(params) {
  try {
    const res = await Api.GET(`Wallet/GetCollectible/${params.collectibleId}`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// connect with xumm wallet
export async function getXumm(params) {
  try {
    const res = await Api.GET("Xumm/SignIn", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

//remove wallet name
export async function deleteAccount() {
  try {
    const res = await Api.DELETE("Account/Delete");
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get next event
export async function getNextEvent() {
  try {
    const res = await Api.GET("MobileWallet/GetNextEvent");
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get user details
export async function getEventList(params) {
  try {
    const res = await Api.GET(
      `EventManager/GetEvents/${params.type}/${
        params.category ? params.category + "/" : ""
      }?isPublished=${params.isPublished}`
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get inactive draft event list
export async function getInactiveDraftEventList(params) {
  try {
    const res = await Api.GET(
      `EventManager/Draft/Get/${params.draftEventId ? params.draftEventId : ""}`
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}
// get all unassigned guests
export async function getEventStatistics(params) {
  try {
    const res = await Api.GET(
      `EventManager/GetEventStatistics/${params.eventId}?eventStatisticTicketTypes=${params.eventStatisticTicketTypes}`
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

export async function GetEventStatisticsByTicketType(params) {
  try {
    const res = await Api.GET(
      `EventManager/GetEventStatisticsByTicketType/${params.eventId}${
        params.eventTicketTypeId ? `/${params.eventTicketTypeId}` : ""
      }`
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get all unassigned guests
export async function GetEventTicketTypes(params) {
  try {
    const res = await Api.GET(`GetEventTicketTypes/${params.eventId}`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// Get currency list
export async function getCurrencyList(params) {
  try {
    const res = await Api.GET(`GetCurrencies`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res?.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// Get country list
export async function getCountryList(params) {
  try {
    const res = await Api.GET(`GetCountries`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res?.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// Get country list
export async function getCitiesByCountryId(params) {
  try {
    const res = await Api.GET(`getCitiesByCountryId?countryId=${params?.countryId}`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res?.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// Get Order Payment Statuses
export async function GetOrderPaymentStatuses(params) {
  try {
    const res = await Api.GET(`GetOrderPaymentStatuses`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res?.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// create event
export async function createEvent(eventPayload) {
  try {
    const res = await Api.POST(`EventManager/CreateEvent`, eventPayload);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

export async function createEventWithInactiveDraft(eventPayload) {
  try {
    const res = await Api.POST(`EventManager/Draft/Add`, eventPayload);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}
// update event
export async function updateEvent(params) {
  try {
    const res = await Api.PUT("EventManager/UpdateEvent", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// update inactive event
export async function updateInactiveDraftEvent(params) {
  console.log('updateInactiveDraftEvent::::: ',params);
  try {
    const res = await Api.PUT(`EventManager/Draft/Edit/${params.draftEventId}`, params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// update event
export async function setUserEntryStatus(params) {
  try {
    const res = await Api.PUT(
      `EventManager/SetUserEntryStatus/${params.eventId}/${params.userId}/${params.entryStatus}?ticketId=${params.ticketId}`,
      params
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// create event
export async function reportIssue(params) {
  try {
    const res = await Api.POST(`EventManager/ReportIssue`, params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// delete user account
export async function deleteEvent(params) {
  try {
    const res = await Api.DELETE(
      `EventManager/DeleteEvent/${params.eventId}`,
      params
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// delete user account
export async function deleteInactiveDraftEvent(params) {
  try {
    const res = await Api.DELETE(
      `EventManager/Draft/Delete/${params.draftEventId}`,
      params
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// Get event category list
export async function getEventCategories(params) {
  try {
    const res = await Api.GET(`EventManager/GetEventCategories`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res?.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get getEntranceUserNameList
export async function getEntranceUserNameList(params) {
  try {
    const res = await Api.GET(
      `EventManager/GetEventVenueUsers/${params.eventId}`
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get getEntrancePassword
export async function getEntrancePassword(params) {
  try {
    const res = await Api.GET(`Venue/GetVenueLoginForEvent/${params.eventId}`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get Order List
export async function getOrderList(params) {
  try {
    const res = await Api.GET(
      `TicketSelling/GetOrders/${params.eventId}/${
        params.searchString.length > 0
          ? `?searchString=${params.searchString}`
          : ""
      }${
        params.statusIds !== undefined
          ? (params.searchString.length > 0 ? "&" : "?") +
            `statusIds=${params.statusIds}`
          : ""
      }${
        params.eventTicketTypeIds !== undefined
          ? (params.searchString !== "" || params.statusIds !== undefined
              ? "&"
              : "?") + `eventTicketTypeIds=${params.eventTicketTypeIds}`
          : ""
      }${
        params.date !== undefined
          ? (params.searchString !== "" ||
            params.statusIds !== undefined ||
            params.eventTicketTypeIds !== undefined
              ? "&"
              : "?") + `date=${params.date}`
          : ""
      }${
        (params.date !== undefined ||
        params.searchString !== "" ||
        params.statusIds !== undefined ||
        params.eventTicketTypeIds !== undefined
          ? "&"
          : "?") + `pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`
      }`
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get v2 Order List
export async function getOrderListV2(params) {
  try {
    const res = await Api.GET(
      `TicketSelling/v2/GetOrders/${params.eventId}/${
        params.searchString.length > 0
          ? `?searchString=${params.searchString}`
          : ""
      }${
        params.statusIds !== undefined
          ? (params.searchString.length > 0 ? "&" : "?") +
            `statusIds=${params.statusIds}`
          : ""
      }${
        params.eventTicketTypeIds !== undefined
          ? (params.searchString !== "" || params.statusIds !== undefined
              ? "&"
              : "?") + `eventTicketTypeIds=${params.eventTicketTypeIds}`
          : ""
      }${
        params.date !== undefined
          ? (params.searchString !== "" ||
            params.statusIds !== undefined ||
            params.eventTicketTypeIds !== undefined
              ? "&"
              : "?") + `date=${params.date}`
          : ""
      }${
        (params.date !== undefined ||
        params.searchString !== "" ||
        params.statusIds !== undefined ||
        params.eventTicketTypeIds !== undefined
          ? "&"
          : "?") + `pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`
      }`
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// assign ticket
export async function orderCancel(params) {
  try {
    const res = await Api.PUT("Order/Cancel", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// export event order details in csv
export async function getEventOrderToCsv(params) {
  try {
    const res = await Api.GET(
      `TicketSelling/ExportOrdersToCsv/${params.eventId}/${
        params.searchString.length > 0
          ? `?searchString=${params.searchString}`
          : ""
      }${
        params.statusIds !== undefined
          ? (params.searchString.length > 0 ? "&" : "?") +
            `statusIds=${params.statusIds}`
          : ""
      }${
        params.eventTicketTypeIds !== undefined
          ? (params.searchString !== "" || params.statusIds !== undefined
              ? "&"
              : "?") + `eventTicketTypeIds=${params.eventTicketTypeIds}`
          : ""
      }${
        params.date !== undefined
          ? (params.searchString !== "" ||
            params.statusIds !== undefined ||
            params.eventTicketTypeIds !== undefined
              ? "&"
              : "?") + `date=${params.date}`
          : ""
      }${
        (params.date !== undefined ||
        params.searchString !== "" ||
        params.statusIds !== undefined ||
        params.eventTicketTypeIds !== undefined
          ? "&"
          : "?") + `pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`
      }`
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// export order details in csv
export async function getOrderToCsv(params) {
  try {
    const res = await Api.GET(
      `TicketSelling/ExportOrderToCsv/${params.orderId}`
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// export order details in csv
export async function getOrderDetails(params) {
  try {
    const res = await Api.GET(`Order/Get/${params.orderId}`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get event details from event id
export async function getEventDetails(params) {
  try {
    const res = await Api.GET(`EventManager/GetEvent/${params.eventId}`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// signup
export async function register(params) {
  try {
    const res = await Api.POST("Register/MobileWallet", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// business signup
export async function businessRegister(params) {
  try {
    const res = await Api.POST("Business/Create", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// stripe account create and account links
export async function StripeAccountConnect(params) {
  try {
    const res = await Api.POST(`Account/Create/StripeAccount`, params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get Stripe Account Status
export async function getStripeAccountStatus(params) {
  try {
    const res = await Api.GET(
      `Account/GetStripeAccountStatus?stripeAccountId=${params.stripeAccountId}`
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get Draft Event Existance
export async function getDraftEventExist(params) {
  try {
    const res = await Api.GET(`EventManager/GetDraftEventCount`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// update user profile details and business details
export async function UpdateUserProfile(params) {
  try {
    const res = await Api.PUT("Account/UpdateUserProfile", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// add guest to list for sending ticket
export async function addGuestList(params) {
  try {
    const res = await Api.POST("EventManager/GuestList/Add", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// add guest to list for sending ticket
export async function removeGuestList(params) {
  try {
    const res = await Api.DELETE("EventManager/GuestList/Delete", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get guest list for sending or revoking ticket
export async function getGuestListToSendRevokeTickets(params) {
  try {
    const res = await Api.GET(`EventManager/GuestList/Get/${params.eventId}`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get Url for Kyc Verify
export async function getUrlKycVerify(params) {
  try {
    const res = await Api.GET(`Kyc/GetUrl?returnURL=${params.returnURL}`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// add card to stripe
export async function addCard(params) {
  try {
    const res = await Api.POST("Stripe/AddCard", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get card list from stripe 
export async function getUsersCards(params) {
  try {
    const res = await Api.GET(`Stripe/GetUsersCards`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// remove card from stripe
export async function removeCard(params) {
  try {
    const res = await Api.DELETE(`Stripe/RemoveCard?stripeCardId=${params.stripeCardId}`, params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// set default card
export async function setDefaultCard(params) {
  try {
    const res = await Api.PUT("Stripe/SetDefaultCard", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// pay now from stripe
export async function payNow(params) {
  try {
    const res = await Api.POST("Stripe/PayNow", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get invoice history 
export async function getInvoiceHistory(params) {
  try {
    const res = await Api.GET(`Stripe/GetInvoiceHistory`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get invoice history 
export async function getInvoiceDetails(params) {
  try {
    const res = await Api.GET(`Stripe/GetInvoiceDetails?InvoiceId=${params.invoiceId}`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get invoice history 
export async function getInvoiceUriToDownload(params) {
  try {
    const res = await Api.GET(`Stripe/DownloadInvoice?invoiceId=${params.invoiceId}`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get unpaid amount
export async function GetUserUnpaidAmount(params) {
  try {
    const res = await Api.GET(`Account/GetUserUnpaidAmount`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get payment threshold
export async function getUserPaymentThreshold(params) {
  try {
    const res = await Api.GET(`Account/GetUserPaymentThresholds`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// update payment threshold
export async function addUpdateUserPaymentThreshold(params) {
  try {
    const res = await Api.POST("Account/AddUpdateUserPaymentThreshold", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}


// update payment threshold
export async function updateEventTicketSellingFlag(params) {
  try {
    const res = await Api.PUT("EventManager/SetEventTicketSellingFlag", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// validate guest ticket quantity
export async function validateGuestTicketQuantity(params) {
  try {
    const res = await Api.POST("Order/ValidateTicketDrop", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get mailchimp login url
export async function getMailChimpLoginURL(params) {
  try {
    const res = await Api.GET(
      `MailChimp/GetMailChimpLoginURL`
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}


// connect mailchimp account
export async function connectMailChimpAccount(params) {
  try {
    const res = await Api.POST(`MailChimp/ConnectMailChimpAccount`, params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get mailchimp account details
export async function getMailChimpAccountDetails(params) {
  try {
    const res = await Api.GET(
      `MailChimp/GetMailChimpAccountDetails`
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}


// disconnect mailchimp account
export async function DisconnectMailChimpAccount(params) {
  try {
    const res = await Api.POST(
      `MailChimp/DisconnectMailChimpAccount`
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// get mailchimp account details
export async function venueUserInUser(params) {
  try {
    const res = await Api.GET(
      `Venue/VenueUserInUser?userName=${params.userName}`
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// create event discount code
export async function createEventDiscountCode(params, eventId) {
  try {
    const res = await Api.POST(`EventManager/CreateEventDiscountCode/${eventId}`, params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

//delete event discount code
export async function deleteEventDiscountCode(params) {
  try {
    const res = await Api.DELETE(
      `EventManager/DeleteEventDiscountCode/${params.eventId}/${params.eventDiscountCodeId}`,
      params
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// update event discount code
export async function UpdateEventDiscountCode(params, eventId) {
  try {
    const res = await Api.PUT(`EventManager/UpdateEventDiscountCode/${eventId}`, params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

export async function getVenueList(params) {
  try {
    const res = await Api.GET(
      `EventManager/VenueLookUp/${params.eventName}`
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// remove payment method
export async function removePaymentMethod(params) {
  try {
    const res = await Api.DELETE(`Account/Delete/StripeAccount`);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

// send email to all attendees
export async function sendEventEmailToAttendees(params) {
  try {
    const res = await Api.PUT("EventManager/SendEventEmailToAttendees", params);
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}

export async function getEventOrganiserEmailHistory(params) {
  try {
    const res = await Api.GET(
      `EventManager/GetEventOrganiserEmailHistory?eventId=${params.eventId}`
    );
    if (res) {
      return getStatus(res);
    } else {
      return { status: false, message: res.message };
    }
  } catch (error) {
    return { status: false, message: "Oops, Something Went Wrong" };
  }
}



