import { COUNTRY_DATA } from "../actions";
export default function countryData(state = [], action) {
  switch (action.type) {
    case COUNTRY_DATA: {
      return {
        ...state,
        countryData: action.payload,
      };
    }
    default:
      return state;
  }
}
