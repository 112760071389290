import React, { useEffect, useState } from "react";
import "../EventDetails/EventDetails.css";
import RadioButton from "../../Common/Controls/RadioButton";
import TooltipPopup from "../../../Component/Common/TooltipPopup/TooltipPopup";
import ButtonPrimary from "../../../Component/Common/Buttons/ButtonPrimary/ButtonPrimary";
import Text from "../../../Component/Common/Text";

export default function RefundPolicy({
  onRefundAcceptedChange,
  shouldShowSubmitButton,
  onSaveClicked,
  onDiscardClicked,
  isRefundAccepted,
  toHaveAccessibleErrorMessage
}) {
  const refundOptions = [
    {
      text: "No refunds accepted",
      subDetails:
        "The customer will be informed that refunds are not available.",
      value: 1,
    },
    {
      text: "Refund requests accepted",
      subDetails:
        "If a customer wishes to request a refund, they are instructed to contact you.",
      value: 2,
    },
  ];

  const [isRefundAcceptedVal, setIsRefundAcceptedVal] =
    useState(isRefundAccepted);

  useEffect(() => {
    setIsRefundAcceptedVal(isRefundAccepted);
  }, [isRefundAccepted]);

  return (
    <div className="event-about-div RefundPolicyCont">
      <div className="generic-common-row-div">
        <Text variant={"m500"}>
          Refund Policy
        </Text>
        <Text color={'var(--contentError)'} variant={"m500"}>
          *
        </Text>
        <TooltipPopup />
      </div>

      <Text marginTop={16} marginRight={16} variant={"r300"}>
        Do you plan to offer refunds to ticket purchasers during the sale
        period?
      </Text>

      <div 
        className="RefundPolicyFormCont"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 32,
        }}
      >
        <RadioButton
          styles={{ marginBottom: 0 }}
          vertically={false}
          data={refundOptions}
          selectIndex={isRefundAcceptedVal}
          onSelect={(item, index) => {
            setIsRefundAcceptedVal(index);
            onRefundAcceptedChange(index);
          }}
        />
      </div>

      <Text
            marginTop={8}
            variant={"label"}
            alignSelf={"center"}
            color={"var(--red)"}
          >
            {toHaveAccessibleErrorMessage?.includes("refundPolicy")
              ? "*Refund policy is required"
              : ""}
          </Text>

      {shouldShowSubmitButton && (
        <div
          className="bottom-buttons-container-edit-view"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 40,
            justifyContent: "center",
          }}
        >
          <ButtonPrimary
            isSecondary={true}
            label={"Cancel"}
            onPress={() => onDiscardClicked()}
            width={328}
            style={{ marginRight: 24 }}
          />
          <ButtonPrimary
            label={"Save changes"}
            onPress={() => onSaveClicked()}
            width={328}
          />
        </div>
      )}
    </div>
  );
}
