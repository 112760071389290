import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import "../EventDetails/EventDetails.css";
import ButtonPrimary from "../../Common/Buttons/ButtonPrimary/ButtonPrimary";
import Text from "../../Common/Text";
import Tags from "../../Common/Tags/Tags";
import RadioButton from "../../Common/Controls/RadioButton";
import DropdownInput from "../../Common/DropdownInput/DropdownInput";
import { ReactComponent as TicketGuard } from "../../../assets/svgs/TicketGuard.svg";
import { ReactComponent as VerifiedFilled } from "../../../assets/svgs/VerifiedFilled.svg";
import { ReactComponent as Chart } from "../../../assets/svgs/Chart.svg";
import { ReactComponent as SecurityFilled } from "../../../assets/svgs/SecurityFilled.svg";

export default function KYC({
  onChangeKYCType,
  shouldShowSubmitButton,
  onSaveClicked,
  onDiscardClicked,
  type,
  minimumAge,
  onChangeMinimumAge,
  toHaveAccessibleErrorMessage,
  ticketGuardFee
}) {
  const kYCOptions = [
    {
      text: "Required",
      subDetails: "Attendee’s will be required to verify their identity",
      value: 1,
    },
    {
      text: "Not required",
      subDetails: "No verification is needed for this event",
      value: 2,
    },
  ];

  const [selectedCategory, setSelectedCategory] = useState(null);
  const minimumAgeOption = [];
  for (let i = 1; i <= 99; i += 1) {
    minimumAgeOption.push({ name: String(i) });
  }

  useEffect(() => {
    setSelectedCategory(type);
  }, [type]);

  return (
    <div
      style={{ position: "relative", marginTop: 52 }}
      className="event-about-div TicketGuardCont"
    >
      <div
        style={{
          position: "absolute",
          top: -18,
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Tags
          paddingVertical={8}
          label={"Recommended"}
          textVairant={"m200"}
          selected={true}
          onChangeSelection={() => {}}
        />
      </div>
      <div 
        className="TicketGuardTitleLogo"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span className="TicketGuardTitle">
          <Text marginRight={16} variant={"m500"}>Add TicketGuard™</Text>
        </span>
        <span className="TicketGuardLogo">
          <TicketGuard />
        </span>
      </div>

      <Text marginTop={16} marginRight={16} variant={"r300"}>
      Enhance event security by requiring attendees to verify their profiles using our TicketGuard™ solution
      </Text>

      <div 
        className="TicketGuardFormCont"
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 40,
        }}
      >
        <div 
          className="TicketGuardFormContLeft"
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1 / 2,
            marginRight: 16,
          }}
        >
          <RadioButton
            vertically={true}
            data={kYCOptions}
            selectIndex={type}
            onSelect={(item, index) => {
              setSelectedCategory(index);
              onChangeKYCType(index);
            }}
          />

          <div
            style={{ marginTop: 12, marginBottom: 32 }}
            className="generic-common-divider"
          />

          <Text variant="m300" marginBottom={8}>
            Minimum age for TicketGuard™*
          </Text>

          <DropdownInput
            // width={82}
            eventCategory={minimumAge}
            data={minimumAgeOption}
            onSelectedItem={(value) => onChangeMinimumAge(value)}
            editable={true}
          />

<Text
            marginTop={8}
            variant={"label"}
            alignSelf={"flex-start"}
            color={"var(--red)"}
          >
            {toHaveAccessibleErrorMessage?.includes("kycMinAge")
              ? "*Minimum age is required"
              : ""}
          </Text>
        </div>
        <div 
          className="TicketGuardFormContRight"
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1 / 2,
            marginLeft: 16,
          }}
        >
          <Text color={"var(--midBlue)"} variant="b600">
           {ticketGuardFee}
          </Text>
          <Text
            marginTop={-4}
            color={"var(--midnightBlue)"}
            variant="m400"
            marginBottom={8}
          >
          {ticketGuardFee ? 'per ticket' : ''}
          </Text>
          <div style={{ marginTop: 32 }} className="generic-common-row-div">
            <VerifiedFilled />
            <Text color={"var(--midnightBlue)"} variant="m400" marginLeft={14}>
              ID Verified Attendees
            </Text>
          </div>
          <div style={{ marginTop: 16 }} className="generic-common-row-div">
            <Chart />
            <Text color={"var(--midnightBlue)"} variant="m400" marginLeft={14}>
              Increased visibility
            </Text>
          </div>
          <div style={{ marginTop: 16 }} className="generic-common-row-div">
            <SecurityFilled />
            <Text color={"var(--midnightBlue)"} variant="m400" marginLeft={14}>
              Improved security
            </Text>
          </div>

          <Text
            color={"var(--midnightBlue)"}
            marginTop={32}
            variant="r300"
            //marginLeft={14}
          >
            {
              "TicketGuard fees are charged to the buyer as part of a platform fee or you can choose to absorb them. Not available on child tickets."
            }
          </Text>

          <Text
            color={"var(--midnightBlue)"}
            marginTop={32}
            variant="r300"
            //marginLeft={14}
          >
            {
              " For large scale events over 10,000 capacity contact us for bespoke pricing."
            }
          </Text>
        </div>
      </div>

      {shouldShowSubmitButton && (
        <div
          className="bottom-buttons-container-edit-view"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 40,
            justifyContent: "center",
          }}
        >
          <ButtonPrimary
            isSecondary={true}
            label={"Cancel"}
            onPress={() => onDiscardClicked()}
            width={328}
            style={{ marginRight: 24 }}
          />
          <ButtonPrimary
            label={"Save changes"}
            onPress={() => onSaveClicked()}
            width={328}
          />
        </div>
      )}
    </div>
  );
}
