import React, { useEffect, useState } from "react";
import "./Fees.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "../EventDetails/EventDetails.css";
import Tooltip from "rc-tooltip"; // 5.1.1
import "rc-tooltip/assets/bootstrap_white.css";
import TooltipPopup from "../../../Component/Common/TooltipPopup/TooltipPopup";
import RadioButton from "../../Common/Controls/RadioButton";
import ButtonPrimary from "../../../Component/Common/Buttons/ButtonPrimary/ButtonPrimary";
import Text from "../../../Component/Common/Text";

export default function Fees({
  feesObject,
  onAddTenPercentageBookingChange,
  onAddFeesToTicketPriceChange,
  shouldShowSubmitButton,
  onSaveClicked,
  onDiscardClicked,
  addTenPercentageBooking,
  addFeesToTicketPrice,
  feeLineItemArray,
  onChangeBookingPrice,
  bookingPercentVal,
  currencySymbol,
  toHaveAccessibleErrorMessage
}) {
  const addTenPercentageBookingOptions = [
    {
      text: "Do not add a booking fee",
      subDetails:
        "No booking fee will be added to the price of your listed tickets.",
      value: 1,
    },
    {
      text: "Add a booking fee per ticket ",
      subDetails: `A booking fee (max. value of ${parseFloat(feesObject?.maxBookingPct || 0).toFixed(2)}%/${currencySymbol}${feesObject?.maxBookingFee}) is split with Twotixx 20/80, so you receive 80%.`,
      value: 2,
    },
  ];

  const addFeesToTicketPriceOptions = [
    {
      text: "Add fees to ticket price",
      subDetails: "Fees are added to the ticket price and passed on to the buyer.",
      value: 1,
    },
    {
      text: "Absorb the fees",
      subDetails: "Fees, excluding the booking fee, will be charged directly to you, the event organiser.",
      value: 2,
    },
  ];

  const [isAddTenPercentageBooking, setIsAddTenPercentageBooking] = useState(
    addTenPercentageBooking
  );

  const [isAddFeesToTicketPrice, setIsAddFeesToTicketPrice] =
    useState(addFeesToTicketPrice);

  const [bookingPercentValue, setBookingPercentValue] =
    useState(bookingPercentVal);

  useEffect(() => {
    setIsAddTenPercentageBooking(addTenPercentageBooking);
  }, [addTenPercentageBooking]);

  useEffect(() => {
    setIsAddFeesToTicketPrice(addFeesToTicketPrice);
  }, [addFeesToTicketPrice]);

  useEffect(() => {
    setBookingPercentValue(bookingPercentVal);
  }, [bookingPercentVal]);

  return (
    <div className="event-about-div CreateEventFeesCont">
      <div
        className="FeesTitleCont"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text marginRight={16} variant={"m500"}>
            Fees per ticket sold
          </Text>
        </div>
      </div>

      <div
        className="FeesRadioCont"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 32,
          marginBottom: 24,
        }}
      >
        <RadioButton
          marginRight={24}
          styles={{ marginBottom: 0 }}
          vertically={false}
          data={addTenPercentageBookingOptions}
          selectIndex={addTenPercentageBooking}
          onSelect={(item, index) => {
            setIsAddTenPercentageBooking(0);
            onAddTenPercentageBookingChange(index);
          }}
        />
      </div>

      {addTenPercentageBooking === 1 && <div className="booking-fees-progress-container">
        <div
          className="generic-common-column-div"
          style={{ width: "498px", maxWidth: "100%" }}
        >
          <Text marginBottom={16} variant={"m300"}>
            Select a booking fee
          </Text>
          <Slider handleRender={(node, handleProps) => {
              const handlePosition = handleProps.offset; // Get the handle's position
              const tooltipStyle = {
                left: `${handlePosition}%`, // Position the tooltip based on the handle position
                transform: 'translateX(-50%)', // Center the tooltip relative to the handle
              };
        return (
          <Tooltip style={tooltipStyle}
            overlayInnerStyle={{ minHeight: "auto", justifyContent: "center", alignItems: "center" }}
            overlay={  handleProps.value + '%'}
            placement="top"
            align={{
              offset: [0, -10], // Adjusts tooltip's vertical position
            }}
          >
            {node}
          </Tooltip>
        );
      }}
            onChangeComplete={(value) => {
              setBookingPercentValue(value);
              onChangeBookingPrice(value);
            }}
            railStyle={{ backgroundColor: "#3C3C432E", height: 5 }}
            trackStyle={{ backgroundColor: "var(--midBlue)", height: 5, ...(bookingPercentVal === 5 ? { width: '46%' } : {}), }}
            handleStyle={{
              height: 28,
              width: 28,
              marginLeft: bookingPercentVal === 5 ? 21 : 0,
              marginTop: -12,
              backgroundColor: "var(--white)",
              border: "none !important",
              opacity: 1,
              boxShadow: "1px 1px 4px 4px rgba(0, 0, 0, 0.07)",
              transition: "all 0.2s ease",
            }}
            handleStyleActive={{
              border: "none !important",
            }}
            dots={false}
            dotStyle={{ width: 0, height: 0 }}
            activeDotStyle={{ width: 0, height: 0 }}
            defaultValue={bookingPercentValue}
            step={null}
            min={1}
            max={10}
            marks={{
              [feesObject?.bookingFeePctRange[0] || 0]: {
                style: {
                  color:
                    bookingPercentValue === 1
                      ? "var(--midBlue)"
                      : "var(--grey)",
                  fontSize: bookingPercentValue === 1 ? "20px" : "16px",
                },
                label: <strong>{`${feesObject?.bookingFeePctRange[0] || 0}%`}</strong>,
              },
              [2]: {
                label: <strong>{``}</strong>,
              },
              [3]: {
                label: <strong>{``}</strong>,
              },
              [4]: {
                label: <strong>{``}</strong>,
              },
              [feesObject?.bookingFeePctRange[1] || 0]: {
                style: {
                  color:
                    bookingPercentValue === 5
                      ? "var(--midBlue)"
                      : "var(--grey)",
                      left: '49%', // Center the mark
                  fontSize: bookingPercentValue === 5 ? "20px" : "16px",
                },
                label: <strong>{`${feesObject?.bookingFeePctRange[1] || 0}%`}</strong>,
              },
              [6]: {
                label: <strong>{``}</strong>,
              },
              [7]: {
                label: <strong>{``}</strong>,
              },
              [8]: {
                label: <strong>{``}</strong>,
              },
              [9]: {
                label: <strong>{``}</strong>,
              },
              [feesObject?.bookingFeePctRange[2] || 0]: {
                style: {
                  color:
                    bookingPercentValue === 10
                      ? "var(--midBlue)"
                      : "var(--grey)",
                  fontSize: bookingPercentValue === 10 ? "20px" : "16px",
                },
                label: <strong>{`${feesObject?.bookingFeePctRange[2] || 0}%`}</strong>,
              },
            }}
          />
        </div>
      </div>}

      <div style={{ marginTop: 40 }} className="generic-common-divider" />

      <Text marginTop={32} marginBottom={16} variant={"m400"}>
        Summary of fees
      </Text>

      {feeLineItemArray?.map((item, index) => {
    
        return (
          // index === 1 && addTenPercentageBooking === 0 ?
          // null
          // :
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingTop: 24,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 10,
              }}
            >
              <div
                style={{ marginBottom: 24, flex: "0 0 60%" }}
                className="generic-common-row-div"
              >
                <Text marginRight={4} variant={"r400"}>
                  {item.label1}
                </Text>
                <TooltipPopup
                  label={
                    item.label1 === "Platform fee"
                      ? "The small fee added to each ticket to cover our service costs."
                      : item.label1 === "TicketGuard™ fee"
                      ? "TicketGuard™ is an optional security feature, so it is only added if the event uses it."
                      : item.label1 === "Booking fee"
                      ? `The event organiser can add a booking fee per ticket of up to ${feesObject?.bookingFeePctRange[2] || 0}%, capped at ${currencySymbol}10.`
                      : ""
                  }
                />
              </div>

              <div
                style={{ alignItems: "flex-end" }}
                className="generic-common-column-div"
              >
                <Text
                  marginBottom={item?.label3 ? 8 : 0}
                  //marginRight={16}
                  textAlign={"right"}
                  variant={"m400"}
                >
                  {`${item.label2}`}
                </Text>
                <Text
                  color={"var(--grey)"}
                  marginBottom={24}
                  textAlign={"right"}
                  //marginRight={16}
                  variant={"r300"}
                >
                  {item?.label3 ? `${item.label3}` : ""}
                </Text>
              </div>
            </div>
            <div className="generic-common-divider" />
          </div>
        );
      })}

      <div className="CreateEventFeesBottomRadio" style={{ paddingTop: 40 }}>
        <RadioButton
          selectIndex={addFeesToTicketPrice}
          marginRight={24}
          // styles={{ marginBottom: 16, marginTop: 40 }}
          styles={{ marginBottom: 0 }}
          vertically={false}
          data={addFeesToTicketPriceOptions}
          onSelect={(item, index) => {
            setIsAddTenPercentageBooking(0);
            onAddFeesToTicketPriceChange(index);
          }}
        />
      </div>

      <Text
            marginTop={8}
            variant={"label"}
            alignSelf={"center"}
            color={"var(--red)"}
          >
            {toHaveAccessibleErrorMessage?.includes("addFeesToTicketPrice")
              ? "*Fees on ticket price is required"
              : ""}
          </Text>

      {shouldShowSubmitButton && (
        <div
          className="bottom-buttons-container-edit-view"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 40,
            justifyContent: "center",
          }}
        >
          <ButtonPrimary
            isSecondary={true}
            label={"Cancel"}
            onPress={() => onDiscardClicked()}
            width={328}
            style={{ marginRight: 24 }}
          />
          <ButtonPrimary
            label={"Save changes"}
            onPress={() => onSaveClicked()}
            width={328}
          />
        </div>
      )}
    </div>
  );
}
