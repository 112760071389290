import React from "react";
import "./NoData.css";
import Text from "../Text";
import { ReactComponent as CloseFilled } from "../../../assets/svgs/CloseFilled.svg";
import { ReactComponent as AddFilled } from "../../../assets/svgs/AddFilled.svg";
import HoverBGButton from "../Buttons/HoverBGButton/HoverBGButton";
import ButtonPrimary from "../Buttons/ButtonPrimary/ButtonPrimary";

export default function NoData({ title, description, onBtnClicked }) {
  return (
    <div className="noData-parent-container">
      <HoverBGButton icon={<CloseFilled height={24} width={24} />} size={48} />
      <Text marginTop={24} variant="m600">
        {title}
      </Text>
      <Text
        textAlign={"center"}
        width={692}
        marginTop={24}
        marginBottom={32}
        variant="r400"
      >
        {description}
      </Text>
      <ButtonPrimary
        showLeftIcon={true}
        icon={<AddFilled fill="var(--white)"/>}
        style={{ padding: 12 }}
        width={146}
        label="Create event"
        onPress={onBtnClicked}
      />
    </div>
  );
}
