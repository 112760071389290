import React from "react";
import "./CentralModal.css";
import Text from "../Text";
import ButtonPrimary from "../Buttons/ButtonPrimary/ButtonPrimary";
import InputField from "../InputFields/InputFieldPrimary/InputFieldPrimary";
import { ReactComponent as CloseFilled } from "../../../assets/svgs/CloseFilled.svg";

export default function CentralModalWithInput({
  title,
  inputTitle,
  inputPlaceholder,
  onChangeInputValue,
  inputValue,
  show,
  onClose,
  shouldShowButtons,
  onButton1Clicked,
  onButton2Clicked,
  button1Title,
  button2Title,
}) {
  if (!show) return null;

  return (
    <div className="central-modal-overlay">
      <div style={{ width: 600 }} className="central-modal">
        <div className="generic-common-row-div-space-between">
          <Text variant="m600">{title}</Text>
          <div className="central-modal-close" onClick={onClose}>
            <CloseFilled style={{cursor: 'pointer'}}/>
          </div>
        </div>
        <div className="central-modal-content">

        <Text marginTop={36} variant="r300">
        Enter the name of the ticket category. This will appear on the customer ticket selling site.
          </Text>

          <Text marginTop={36} variant="m300" marginBottom={8}>
            {inputTitle}
          </Text>

          <InputField
            capitalizeWords={true}
            inputValue={inputValue}
            verifyNotNeed={true}
            placeholder={inputPlaceholder}
            secureTextEntry={false}
            editable={true}
            type={"eventName"}
            withOutIcon={true}
            isError={false}
            onChangeValue={(text) => onChangeInputValue(text)}
            onBlur={(value) => {}}
            assistive={""}
          />

          {shouldShowButtons && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 40,
                marginBottom: 16,
              }}
            >
              <ButtonPrimary
                disabled={false}
                isSecondary={true}
                label={button1Title}
                onPress={() => onButton1Clicked()}
              />

              <ButtonPrimary
                disabled={false}
                label={button2Title}
                
                onPress={() => onButton2Clicked()}
                style={{ marginLeft: 32 }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
