import React, { useEffect, useState } from "react";
import "../EventDetails/EventDetails.css";
import { ReactComponent as BinFilled } from "../../../assets/svgs/BinFilled.svg";
import { ReactComponent as InfoFilled } from "../../../assets/svgs/InfoFilled.svg";
import InputField from "../../Common/InputFields/InputFieldPrimary/InputFieldPrimary";
import ButtonPrimary from "../../Common/Buttons/ButtonPrimary/ButtonPrimary";
import DropdownInput from "../../../Component/Common/DropdownInput/DropdownInput";
import Text from "../../Common/Text";
import { emailReg } from "../../../constants/regex";

export default function Guests({
  categoryList,
  onEmailChange,
  onTicketCategoryChange,
  onTicketQuantityChange,
  onDeleteClicked,
  onSendTicketClicked,
  status,
  ticketCategory,
  quantity,
  email,
  emailError,
  quantityError,
  index,
  shouldShowDeleteButton,
}) {

  const quantityOption = [];
  for (let i = 1; i <= 10; i += 1) {
    quantityOption.push({ name: String(i) });
  }

  return (
    <div className="event-about-div">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text marginRight={16} variant={"m500"}>
          {ticketCategory !== "Select an option" ? ticketCategory : "Ticket " + String(index + 1)}
        </Text>

        {/* {shouldShowDeleteButton && (
          <div style={{ cursor: "pointer" }}
            onClick={() => onDeleteClicked(index)}
            className="generic-common-row-div"
          >
            <Text
              cursor={'pointer'}
              color={"var(--red)"}
              marginRight={8}
              marginTop={3}
              variant="m300"
            >
              Remove
            </Text>
            <BinFilled
              fill={getComputedStyle(document.documentElement).getPropertyValue(
                "--red"
              )}
            />
          </div>
        )} */}
      </div>

      <div
        style={{ alignItems: "flex-start" }}
        className="generic-common-row-div-space-between guestList-ticket-block"
      >
        <div
          className="guestList-ticket-item"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 32,
            width: "33%",
            marginRight: 24,
          }}
        >
          <Text variant="m300" marginBottom={8}>
            Select ticket
          </Text>

          <DropdownInput
            eventCategory={ticketCategory}
            data={categoryList}
            onSelectedItem={(value) => onTicketCategoryChange(value)}
            editable={status !== "sent"}
          />
        </div>

        <div
          className="guestList-ticket-item"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 32,
            width: "10%",
            marginRight: 24,
          }}
        >
          <Text variant="m300" marginBottom={8}>
            Quantity
          </Text>

          <DropdownInput
            eventCategory={quantity}
            data={quantityOption}
            onSelectedItem={(value) => onTicketQuantityChange(value)}
            editable={status !== "sent"}
          />
        </div>

        <div
          className="guestList-ticket-item"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 32,
            width: "33%",
            marginRight: 24,
          }}
        >
          <Text variant="m300" marginBottom={8}>
            Email address
          </Text>

          <InputField
           // hasDebounce={emailReg?.test(email) === true ? true : false}
            isNumeric={false}
            inputValue={email}
            verifyNotNeed={true}
            placeholder={"Enter email address"}
            secureTextEntry={false}
            editable={status !== "sent"}
            type={"email"}
            withOutIcon={false}
            isError={false}
            onChangeValue={(text) => onEmailChange(text)}
            onBlur={(value) => {}}
            warningDescriptive={
              emailError === true
                ? "Entered email is not valid attendee email"
                : ""
            }
            assistive={
              email !== "" && emailReg.test(email) === false
                ? "Entered email is not valid"
                : ""
            }
          />
        </div>
        <div
          style={{ width: "23%" }}
          className="generic-common-row-div-space-between guestList-ticket-item"
        >
          {/* <div
            className="guestList-status"
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 32,
              marginRight: 24,
            }}
          >
            <Text variant="m300" marginBottom={8}>
              Status
            </Text>
            <ButtonPill
              type={
                status === "sent"
                  ? "completed"
                  : status === "notActive"
                  ? "expired"
                  : "generic"
              }
              varient={"small"}
              label={
                status === "sent"
                  ? "Sent"
                  : status === "notActive"
                  ? "Not active"
                  : "Created"
              }
            />
          </div> */}
          <ButtonPrimary showLeftIcon={status === "sent" ? true : false} icon={<BinFilled
              fill={
                "var(--midBlue)"}
            />}
            disabled={status === "notActive"}
            isSecondary={status === "sent" ? true : false}
            label={status === "sent" ? "Revoke ticket" : "Send ticket"}
            onPress={() => onSendTicketClicked()}
            width={180}
            style={{ marginTop: 62 }}
          />
        </div>
      </div>
      {quantityError && !emailError && ticketCategory !== 'Select an option' && emailReg.test(email) === true && quantity !== '-' ? 
      <div style={{marginTop: 8}} className="generic-common-row-div">
<InfoFilled fill="var(--red)" />
      <Text marginLeft={6}
          variant={"label"}
          color={
            "var(--red)"
          }
        >
          {'The quantity selected exceeds the available tickets for this event. Please adjust your selection to a valid number.'}
        </Text> 
        </div>
        : null}
    </div>
  );
}
