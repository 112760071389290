import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../EventDetails/EventDetails.css";
import DatePicker from "react-datepicker";
import Text from "../../../Component/Common/Text";
import TooltipPopup from "../../../Component/Common/TooltipPopup/TooltipPopup";
import DropdownInput from "../../../Component/Common/DropdownInput/DropdownInput";
import InputField from "../../Common/InputFields/InputFieldPrimary/InputFieldPrimary";
import RadioButton from "../../Common/Controls/RadioButton";
import { ReactComponent as BinFilled } from "../../../assets/svgs/BinFilled.svg";
import {ReactComponent as CheckBoxCheckedCircle} from "../../../assets/svgs/CheckBoxCheckedCircle.svg";
import {ReactComponent as CheckBoxUncheckCircle} from "../../../assets/svgs/CheckBoxUncheckCircle.svg";

import ButtonPill from "../../Common/Buttons/ButtonPill";

export default function StageReleases({
  eventStartDate,
  eventStartTime,
  data,
  index,
  isLastIndex,
  onDeleteStageReleaseClicked,
  onStageOfferNameChange,
  onStageQuantityChange,
  onStageReleaseDateChange,
  onStageReleaseTimeChange,
  onStageReleaseEndDateChange,
  onStageReleaseEndTimeChange,
  onStageCurrencyChange,
  onStagePriceChange,
  onChangeActivateNextRelease,
  onChangeAutoTriggerNextRelease,
  addAnotherStageRelease,
  isStageEditable,
  stageReleases,
  ticketName,
  ticketQuantity,
  maxQuantity,
  maxQuantityValue
}) {
  const currencyList = useSelector((state) => state.currencyData?.currencyData);
  const [selectedStageReleaseDateValue, setSelectedStageReleaseDateValue] =
    useState(data.stageReleaseDate);
  const [
    isDatePickerVisibleStageReleaseDate,
    setIsDatePickerVisibleStageReleaseDate,
  ] = useState(false);

  const [selectedStageOptionIndex, setSelectedStageOptionIndex] =
    useState(data.activateNextRelease);

  const [
    selectedStageReleaseEndDateValue,
    setSelectedStageReleaseEndDateValue,
  ] = useState(data.stageReleaseEndDate);
  const [
    isDatePickerVisibleStageReleaseEndDate,
    setIsDatePickerVisibleStageReleaseEndDate,
  ] = useState(false);

  const StageOptions = [
    {
      text: "By date",
      subDetails: "Release stages on their scheduled dates",
      value: 1,
    },
    {
      text: "By ticket sales",
      subDetails:
        "Release stages after all tickets from the previous stage are sold",
      value: 2,
    },
  ];

  const generateTimeArray = () => {
    const timeArray = [];
    for (let hours = 0; hours < 24; hours++) {
      for (let minutes = 0; minutes < 60; minutes += 15) {
        const formattedTime = `${String(hours).padStart(2, "0")}:${String(
          minutes
        ).padStart(2, "0")}`;
        timeArray.push({ name: formattedTime });
      }
    }
    return timeArray;
  };

  const extractCurrencySymbol = (str) => {
    const match = str.match(/\(([^)]+)\)/);
    return match ? match[1] : str;
  };

  // const generateCustomTimeArray = (startTime) => {
  //   const [startHour, startMinute] = startTime.split(":").map(Number);
  //   const timeArray = [];

  //   for (let hours = startHour; hours < 24; hours++) {
  //     for (
  //       let minutes = hours === startHour ? startMinute : 0;
  //       minutes < 60;
  //       minutes += 5
  //     ) {
  //       const formattedTime = `${String(hours).padStart(2, "0")}:${String(
  //         minutes
  //       ).padStart(2, "0")}`;
  //       timeArray.push({ name: formattedTime });
  //     }
  //   }

  //   for (let hours = 0; hours < startHour; hours++) {
  //     for (let minutes = 0; minutes < 60; minutes += 5) {
  //       const formattedTime = `${String(hours).padStart(2, "0")}:${String(
  //         minutes
  //       ).padStart(2, "0")}`;
  //       timeArray.push({ name: formattedTime });
  //     }
  //   }

  //   for (let minutes = 0; minutes < startMinute; minutes += 5) {
  //     const formattedTime = `${String(startHour).padStart(2, "0")}:${String(
  //       minutes
  //     ).padStart(2, "0")}`;
  //     timeArray.push({ name: formattedTime });
  //   }

  //   return timeArray;
  // };

  const generateCustomTimeArray = (startTime) => {
    console.log("startTime", startTime);
    const [startHour, startMinute] = startTime?.split(":").map(Number);
    const timeArray = [];

    // Generate times from start time to the end of the day
    for (let hours = startHour; hours < 24; hours++) {
      for (
        let minutes = hours === startHour ? startMinute : 0;
        minutes < 60;
        minutes += 15
      ) {
        const formattedTime = `${String(hours).padStart(2, "0")}:${String(
          minutes
        ).padStart(2, "0")}`;
        timeArray.push({ name: formattedTime });
      }
    }

    return timeArray;
  };

  useEffect(() => {
    setSelectedStageReleaseDateValue(data.stageReleaseDate);
  }, [data.stageReleaseDate]);

  useEffect(() => {
    setSelectedStageOptionIndex(data.activateNextRelease);
    console.log("data.activateNextRelease1234", data.activateNextRelease);
    
  } , [data.activateNextRelease]);

  useEffect(() => {
    onStageOfferNameChange(ticketName
      ? `${ticketName} - ${getOrdinal(index + 1)} Release`
      : `-`, index);
  }, [ticketName]);

  const getOrdinal = (n) => {
    const s = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  return (
    <div
      className="stage-release-parent-box"
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{ marginTop: index === 0 ? -8 : 32, marginBottom: 28 }}
        className="generic-common-divider"
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="generic-common-row-div">
          <Text variant={"m400"} >
            {ticketName
              ? `${ticketName} - ${getOrdinal(index + 1)} Release`
              : `Release ${getOrdinal(index + 1)}`}
          </Text> 
          {data.stageQuantity ? <ButtonPill style={{ marginLeft: 16 }}
                      type={"generic"}
                      varient={"small"}
                      label={`${parseInt(data.stageQuantity).toLocaleString()} tickets`}
                    /> : null}
          <TooltipPopup />
        </div>

        <div
          onClick={() => onDeleteStageReleaseClicked(index)}
          className="generic-common-row-div"
        >
          <Text
            color={"var(--red)"}
            marginRight={8}
            marginTop={3}
            variant="m300"
          >
            Remove
          </Text>
          <BinFilled
            fill={getComputedStyle(document.documentElement).getPropertyValue(
              "--red"
            )}
          />
        </div>
        {/* )} */}
      </div>

      <Text marginTop={32} variant="m300">Select a trigger for each stage release:</Text>

      <RadioButton
        warningLabelText={selectedStageOptionIndex === false ? 'Any unsold tickets will automatically be moved to next release.' : false}
        styles={{ marginTop: 20, marginBottom: 0 }}
        marginRight={32}
        vertically={false}
        data={StageOptions}
        selectIndex={selectedStageOptionIndex === null ? null : selectedStageOptionIndex === false ? 0 : 1}
        onSelect={(item, indexx) => {
          setSelectedStageOptionIndex(indexx === 0 ? false : true);
          onChangeActivateNextRelease(indexx === 0 ? false : true, index);
        }}
      />


      {!isLastIndex && selectedStageOptionIndex === false &&  (
        <div
          onClick={() => {
            onChangeAutoTriggerNextRelease(!data.autoTriggerNextRelease, index);
          }}
          style={{ height: 36, marginTop: 16 }}
          className="generic-common-row-div central-modal-contentPopup-check"
        >
          <div
            style={{
              width: 38,
              height: 38,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            {data.autoTriggerNextRelease === true ? (
              <CheckBoxCheckedCircle width={24} height={24} />
            ) : (
              <CheckBoxUncheckCircle width={24} height={24} />
            )}
          </div>
          <Text marginTop={-2} marginLeft={4} variant={"r200"}>
          Auto-trigger the next release if tickets sell out before the release date.
          </Text>
        </div>
      )}

      <div
        className="stage-release-name-currency-parent-box"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          className="stage-release-name-currency-child-box"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 32,
            flex: 1 / 2,
            paddingRight: 64,
          }}
        >
          <Text variant="m300" marginBottom={8}>
            Ticket name*
          </Text>

          <InputField
            inputValue={ticketName
              ? `${ticketName} - ${getOrdinal(index + 1)} Release`
              : `-`}
            verifyNotNeed={true}
            placeholder={"e.g. Early Bird, First Release"}
            secureTextEntry={false}
            editable={false}
            type={"eventName"}
            withOutIcon={true}
            isError={false}
            onChangeValue={(text) => {
              const prefix = `${ticketName} - ${getOrdinal(index + 1)} Release`;
              if (!text.startsWith(prefix)) {
                text = prefix;
              }
              const updatedText = text.replace(prefix, "");
              onStageOfferNameChange(updatedText, index);
            }}
            onBlur={(value) => {}}
            assistive={
              data?.toHaveAccessibleErrorMessageStageRelease?.includes(
                "stageOfferName"
              )
                ? "*Offer name is required"
                : ""
            }
          />
        </div>
      </div>

      <div
        style={{ alignItems: "flex-start" }}
        className="generic-common-row-div-space-between"
      >
        {/* <div
          className="currencyTicket-item"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 16,
            flex: 1 / 2,
            paddingRight: 32,
          }}
        >
          <Text variant="m300" marginBottom={8}>
            Currency
          </Text>

          <DropdownInput
            type={"currency"}
            eventCategory={data.stageCurrency}
            data={currencyList}
            onSelectedItem={(value) => onStageCurrencyChange(value, index)}
            editable={false}
          />
        </div> */}

        <div
          className="currencyTicket-item"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 16,
            flex: 1 / 2,
            paddingRight: 32,
          }}
        >
          <Text variant="m300" marginBottom={8}>
            Ticket allocation*
          </Text>

          <InputField
          maxValue={maxQuantity}
            greenRightEndText={ticketQuantity ? `${parseInt(maxQuantityValue).toLocaleString()} tickets available` : ''}
            isNumeric={true}
            inputValue={data.stageQuantity}
            verifyNotNeed={true}
            placeholder={"Enter allocation"}
            secureTextEntry={false}
            editable={isStageEditable}
            type={"eventName"}
            withOutIcon={true}
            isError={false}
            onChangeValue={(text) => {
              onStageQuantityChange(text, index)
            }}
            onBlur={(value) => {}}
            assistive={
              data?.toHaveAccessibleErrorMessageStageRelease?.includes(
                "stageQuantity"
              )
                ? "*Quantity is required"
                : ""
            }
            descriptive={maxQuantity ? `Please enter a ticket quantity within the available range (${parseInt(ticketQuantity).toLocaleString()} tickets).` : ''}
          />
        </div>
        <div
          style={{
            alignItems: "flex-start",
            marginTop: 0,
            flex: 1 / 2,
            paddingLeft: 0,
          }}
          className="generic-common-row-div-space-between ticket-price-parent-box"
        >
          <div
            className="currencyTicket-item"
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Text variant="m300" marginBottom={8}>
              Ticket price*
            </Text>

            <InputField 
             prefix={data.stageCurrency !== '-' && data.stagePrice ? data.stageCurrency : ''}
              isNumeric={true}
              inputValue={data.stagePrice}
              verifyNotNeed={true}
              placeholder={"Enter price"}
              secureTextEntry={false}
              editable={isStageEditable}
              type={"eventName"}
              withOutIcon={true}
              isError={false}
              onChangeValue={(text) => onStagePriceChange(text, index)}
              onBlur={(value) => {}}
              assistive={
                data?.toHaveAccessibleErrorMessageStageRelease?.includes(
                  "stageTicketPrice"
                )
                  ? "*Ticket price is required"
                  : ""
              }
            />
          </div>
          {/* <div
            className="currencyTicket-item"
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: 4,
            }}
          >
            <DropdownInput
              width={132}
              style={{ marginTop: 28 }}
              type={"currency"}
              eventCategory={data.stageCurrency}
              data={currencyList}
              onSelectedItem={(value) => onStageCurrencyChange(value, index)}
              editable={false}
            />
          </div> */}
        </div>
      </div>

      {/* <div
        style={{ marginTop: "32px", marginBottom: "32px" }}
        className="generic-common-divider"
      /> */}

      

{selectedStageOptionIndex === false && <div className="generic-common-row-div-space-between stage-release-date-parent-box">
        <div
          className="CommonFormFieldRow stage-release-date-child-box"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            marginTop: 16,
            width: "50%",
            marginRight: "64px",
          }}
        >
          <div
            className="CommonFormGroup"
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Text variant="m300" marginBottom={8}>
              <span className="date-time-tittle">Start release date*</span>
            </Text>
            <DropdownInput
              resetRequired={true}
              showDropdown={isDatePickerVisibleStageReleaseDate}
              onToggleDropdown={() =>
                setIsDatePickerVisibleStageReleaseDate(
                  !isDatePickerVisibleStageReleaseDate
                )
              }
              eventCategory={selectedStageReleaseDateValue}
              data={[]}
              editable={
                !isStageEditable || eventStartDate === "DD/MM/YYYY"
                    ? false
                    : true
                // (index === 0
                //   ? eventStartDate === "DD/MM/YYYY"
                //     ? false
                //     : true
                //   : stageReleases[index - 1].stageReleaseDate !==
                //       "DD/MM/YYYY" &&
                //     stageReleases[index - 1].stageReleaseEndDate !==
                //       "DD/MM/YYYY"
                //     )
                //   ? true
                //   : false
              }
              resetBtnClicked={() => {
                setSelectedStageReleaseDateValue("DD/MM/YYYY");
                onStageReleaseDateChange("DD/MM/YYYY", index);
              }}
              customRender={() => {
                return (
                  <div style={{ marginBottom: -10 }}>
                    <DatePicker
                      shouldCloseOnSelect={true}
                      onChange={(update) => {
                        setSelectedStageReleaseDateValue(
                          new Date(update).toLocaleDateString("en-GB")
                        );
                        onStageReleaseDateChange(
                          new Date(update).toLocaleDateString("en-GB"),
                          index
                        );
                        setIsDatePickerVisibleStageReleaseDate(
                          !isDatePickerVisibleStageReleaseDate
                        );
                      }}
                      startDate={new Date()}
                      inline
                      minDate={
                        index !== 0
                          ? new Date(
                              stageReleases[
                                index - 1
                              ].stageReleaseEndDate.split("/")[2],
                              stageReleases[
                                index - 1
                              ].stageReleaseEndDate.split("/")[1] - 1,
                              stageReleases[
                                index - 1
                              ].stageReleaseEndDate.split("/")[0]
                            )
                          // : eventStartDate
                          // ? new Date(
                          //     eventStartDate.split("/")[2],
                          //     eventStartDate.split("/")[1] - 1,
                          //     eventStartDate.split("/")[0]
                          //   )
                          : null
                      }
                    />
                  </div>
                );
              }}
            />
            <Text
              marginTop={8}
              variant={"label"}
              alignSelf={"flex-start"}
              color={"var(--red)"}
            >
              {data?.toHaveAccessibleErrorMessageStageRelease?.includes(
                "releaseDate"
              )
                ? "*Start release date is required"
                : ""}
            </Text>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              marginLeft: 24,
            }}
          >
            <Text variant="m300" marginBottom={8}>
              <span className="date-time-tittle">Time*</span>
            </Text>
            <DropdownInput
              eventCategory={data.stageReleaseTime}
              data={
                index === 0
                  ? data.stageReleaseDate === eventStartDate
                    ? generateCustomTimeArray(eventStartTime)
                    : generateTimeArray()
                  : data.stageReleaseDate ===
                    stageReleases[index - 1].stageReleaseEndDate
                  ? generateCustomTimeArray(
                      stageReleases[index - 1].stageReleaseEndTime
                    )
                  : generateTimeArray()
              }
              onSelectedItem={(value) => {
                onStageReleaseTimeChange(value, index);
              }}
              editable={isStageEditable && selectedStageReleaseDateValue !== "DD/MM/YYYY"}
            />
            <Text
              marginTop={8}
              variant={"label"}
              alignSelf={"flex-start"}
              color={"var(--red)"}
            >
              {data?.toHaveAccessibleErrorMessageStageRelease?.includes(
                "releaseTime"
              )
                ? "*Start release time is required"
                : ""}
            </Text>
          </div>
        </div>

        <div
          className="CommonFormFieldRow stage-release-date-child-box"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginTop: 16,
            width: "50%",
          }}
        >
          <div
            className="CommonFormGroup"
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <Text variant="m300" marginBottom={8}>
              <span className="date-time-tittle">End release date*</span>
            </Text>

            <DropdownInput
              resetRequired={true}
              showDropdown={isDatePickerVisibleStageReleaseEndDate}
              onToggleDropdown={() =>
                setIsDatePickerVisibleStageReleaseEndDate(
                  !isDatePickerVisibleStageReleaseEndDate
                )
              }
              eventCategory={selectedStageReleaseEndDateValue}
              data={[]}
              editable={
                data.stageReleaseDate === "DD/MM/YYYY" || !isStageEditable
                  ? false
                  : true
              }
              resetBtnClicked={() => {
                setSelectedStageReleaseEndDateValue("DD/MM/YYYY");
                onStageReleaseEndDateChange("DD/MM/YYYY", index);
              }}
              customRender={() => {
                return (
                  <div style={{ marginBottom: -10 }}>
                    <DatePicker
                      shouldCloseOnSelect={true}
                      onChange={(update) => {
                        setSelectedStageReleaseEndDateValue(
                          new Date(update).toLocaleDateString("en-GB")
                        );
                        onStageReleaseEndDateChange(
                          new Date(update).toLocaleDateString("en-GB"),
                          index
                        );
                        setIsDatePickerVisibleStageReleaseEndDate(
                          !isDatePickerVisibleStageReleaseEndDate
                        );
                      }}
                      startDate={new Date()}
                      inline
                      minDate={
                        new Date(
                          data.stageReleaseDate.split("/")[2],
                          data.stageReleaseDate.split("/")[1] - 1,
                          data.stageReleaseDate.split("/")[0]
                        )
                      }
                      // minDate={new Date()}
                    />
                  </div>
                );
              }}
            />

            <Text
              marginTop={8}
              variant={"label"}
              alignSelf={"flex-start"}
              color={"var(--red)"}
            >
              {data?.toHaveAccessibleErrorMessageStageRelease?.includes(
                "releaseEndDate"
              )
                ? "*End release date is required"
                : ""}
            </Text>
          </div>
          <div
            className="CommonFormGroup"
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              marginLeft: 24,
            }}
          >
            <Text variant="m300" marginBottom={8}>
              <span className="date-time-tittle">Time*</span>
            </Text>

            <DropdownInput
              eventCategory={data.stageReleaseEndTime}
              data={
                data.stageReleaseDate === data.stageReleaseEndDate
                  ? generateCustomTimeArray(data.stageReleaseTime)
                  : generateTimeArray()
              }
              onSelectedItem={(value) => {
                onStageReleaseEndTimeChange(value, index);
              }}
              editable={isStageEditable && selectedStageReleaseEndDateValue !== "DD/MM/YYYY"}
            />
            <Text
              marginTop={8}
              variant={"label"}
              alignSelf={"flex-start"}
              color={"var(--red)"}
            >
              {data?.toHaveAccessibleErrorMessageStageRelease?.includes(
                "releaseEndTime"
              )
                ? "*End release time is required"
                : ""}
            </Text>
          </div>
        </div>
      </div> }


      {/* {isLastIndex && (
        <div className="generic-common-row-centerd-div">
          <ButtonPrimary disabled={!isStageEditable}
            isSecondary={true}
            label={"Add another release"}
            onPress={() => addAnotherStageRelease(index)}
            width={328}
            style={{ marginTop: 32 }}
          />
        </div>
      )} */}
    </div>
  );
}
