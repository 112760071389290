import React, { useEffect, useState } from "react";
import "./TicketAddOn.css";
import { useSelector } from "react-redux";
import "../EventDetails/EventDetails.css";
import { ReactComponent as BinFilled } from "../../../assets/svgs/BinFilled.svg";
import TooltipPopup from "../../../Component/Common/TooltipPopup/TooltipPopup";
import InputField from "../../Common/InputFields/InputFieldPrimary/InputFieldPrimary";
import ButtonPrimary from "../../Common/Buttons/ButtonPrimary/ButtonPrimary";
import DropdownInput from "../../../Component/Common/DropdownInput/DropdownInput";
import Text from "../../Common/Text";

export default function TicketAddOn({
  onNameChange,
  onQuantityChange,
  onDescriptionChange,
  onCurrencyChange,
  onPriceChange,
  onAddedToChange,
  addAnotherAddOn,
  shouldShowSubmitButton,
  toHaveAccessibleErrorMessageTicketAddOn,
  onSaveClicked,
  onDiscardClicked,
  onDeleteClicked,
  addOnName,
  addOnQuantity,
  addOnDescription,
  addOnCurrency,
  addOnPrice,
  addOnAddedTo,
  index,
  currencyList,
  addOnCategoryList,
  showAddAnotherAddOn,
  onAddAnotherAddOnClicked,
  onRemoveAddOnClicked,
}) {
  // const currencyList = useSelector((state) => state.currencyData?.currencyData);
  const remainingCharCount = addOnDescription?.length;

  useEffect(() => {
    console.log("showAddAnotherAddOn::::::", showAddAnotherAddOn);
  }, [showAddAnotherAddOn]);

  return (
    <div className="event-about-div">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div className="generic-common-row-div">
          <Text marginRight={13} variant={"m500"}>
            {"Add On " + String(index + 1)}
          </Text>
          <TooltipPopup />
        </div>

        <div
          onClick={() => onDeleteClicked(index)}
          className="generic-common-row-div"
        >
          <Text
            color={"var(--red)"}
            marginRight={8}
            marginTop={3}
            variant="m300"
          >
            Remove
          </Text>
          <BinFilled
            fill={getComputedStyle(document.documentElement).getPropertyValue(
              "--red"
            )}
          />
        </div>
      </div>

      <div
        style={{ marginTop: 40, marginBottom: 40 }}
        className="generic-common-divider"
      />

      <div
        className="CommonFormFieldRow addOn-field-block"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          class="CommonFormGroup"
          style={{
            display: "flex",
            flexDirection: "column",
            //marginTop: 32,
            flex: 1 / 2,
            paddingRight: 32,
          }}
        >
          <Text variant="m300" marginBottom={8}>
            Add On name*
          </Text>

          <InputField
            inputValue={addOnName}
            verifyNotNeed={true}
            placeholder={"Enter Add On name"}
            secureTextEntry={false}
            editable={true}
            type={"eventName"}
            withOutIcon={true}
            isError={false}
            onChangeValue={(text) => onNameChange(text)}
            onBlur={(value) => {}}
            assistive={
              toHaveAccessibleErrorMessageTicketAddOn?.includes("name")
                ? "*Name is required"
                : ""
            }
          />
        </div>

        <div
          class="CommonFormGroup"
          style={{
            display: "flex",
            flexDirection: "column",
            //marginTop: 32,
            flex: 1 / 2,
            paddingLeft: 32,
          }}
        >
          <Text variant="m300" marginBottom={8}>
            Quantity*
          </Text>

          <InputField
            isNumeric={true}
            inputValue={addOnQuantity}
            verifyNotNeed={true}
            placeholder={"Enter Add On quantity"}
            secureTextEntry={false}
            editable={true}
            type={"eventName"}
            withOutIcon={true}
            isError={false}
            onChangeValue={(text) => onQuantityChange(text)}
            onBlur={(value) => {}}
            assistive={toHaveAccessibleErrorMessageTicketAddOn?.includes("quantity")
              ? "*Quantity is required"
              : ""}
          />
        </div>
      </div>

      <div
        style={{ alignItems: "flex-start" }}
        className="generic-common-row-div-space-between CommonFormFieldRow"
      >
        <div
          class="CommonFormGroup full-width-col"
          style={{
            display: "flex",
            flexDirection: "column",
            //marginTop: 32,
            flex: 1 / 2,
            paddingRight: 32,
          }}
        >
          <Text variant="m300" marginBottom={8}>
            {"Add On description*"}
          </Text>
          <div className="ticket-input-container">
            <textarea
              className="ticket-top-aligned-input"
              style={{
                display: "flex",
                flex: 1,
                border: "none",
                borderRadius: 4,
                outline: "none",
                paddingLeft: 16,
                paddingTop: 12,
                verticalAlign: "top",
                color: 'var(--midnightBlue)',
              }}
              maxLength={200}
              type={"text"}
              placeholder={"Enter Add On description"}
              aria-multiline="true"
              autoCapitalize={"words"}
              value={addOnDescription}
              onChange={(event) => onDescriptionChange(event.target.value)}
              rows={5}
            />
          </div>
          <Text
            marginTop={2}
            variant={"label"}
            alignSelf={"flex-start"}
            color={"var(--red)"}
          >
            {toHaveAccessibleErrorMessageTicketAddOn?.includes("description")
              ? "*Description is required"
              : ""}
          </Text>
          <Text
            color={getComputedStyle(document.documentElement).getPropertyValue(
              "--midBlue"
            )}
            alignSelf={"flex-end"}
            variant={"label"}
          >
            {remainingCharCount}/200 characters
          </Text>
        </div>
        <div className="full-width-col"
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: 32,
            flex: 1 / 2,
            paddingLeft: 32,
          }}
        />
      </div>

      <div
        style={{ alignItems: "flex-start" }}
        className="generic-common-row-div-space-between CommonFormFieldRow"
      >
        <div
          class="CommonFormGroup"
          style={{
            display: "flex",
            flexDirection: "column",
            //marginTop: 32,
            flex: 1 / 2,
            paddingRight: 32,
          }}
        >
          <Text variant="m300" marginBottom={8}>
            Currency
          </Text>

          <DropdownInput
            type={"currency"}
            eventCategory={addOnCurrency}
            data={currencyList}
            onSelectedItem={(value) => onCurrencyChange(value)}
            editable={false}
          />
        </div>
        <div
          class="CommonFormGroup"
          style={{
            display: "flex",
            flexDirection: "column",
            //marginTop: 32,
            flex: 1 / 2,
            paddingLeft: 32,
          }}
        >
          <Text variant="m300" marginBottom={8}>
            Add On price*
          </Text>

          <InputField
            isNumeric={true}
            inputValue={addOnPrice}
            verifyNotNeed={true}
            placeholder={"Enter Add On price"}
            secureTextEntry={false}
            editable={true}
            type={"eventName"}
            withOutIcon={true}
            isError={false}
            onChangeValue={(text) => onPriceChange(text)}
            onBlur={(value) => {}}
            assistive={
              toHaveAccessibleErrorMessageTicketAddOn?.includes("price")
                ? "*Price is required"
                : ""
            }
          />
        </div>
      </div>

      {addOnAddedTo?.length > 0 ? (
        <div>
          {addOnAddedTo.map((item, ind) => {
            return (
              <div
                style={{ width: "100%" }}
                className="generic-common-column-div"
              >
                <div
                  style={{ width: "100%" }}
                  className="generic-common-row-div CommonFormFieldRow"
                >
                  <div
                    class="CommonFormGroup"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      //marginTop: 32,
                      width: "50%",
                      paddingRight: 32,
                    }}
                  >
                    <Text variant="m300" marginBottom={8}>
                      Can be added to*
                    </Text>

                    <DropdownInput
                      isMultiSelect={false}
                      type={"currency1"}
                      eventCategory={item}
                      data={addOnCategoryList}
                      onSelectedItem={(value, index) =>
                        onAddedToChange(value, index, ind)
                      }
                      editable={addOnCategoryList?.length > 0 ? true : false}
                    />

<Text
            marginTop={8}
            variant={"label"}
            alignSelf={"flex-start"}
            color={"var(--red)"}
          >
            {toHaveAccessibleErrorMessageTicketAddOn?.includes("addedTo")
              ? "*Can be added to is required"
              : ""}
          </Text>
                  </div>

                  {item !== "Select an option" && (
                    <ButtonPrimary
                      type={"error"}
                      isSecondary={true}
                      label={"Remove from ticket"}
                      onPress={() => onRemoveAddOnClicked(ind)}
                      width={328}
                      style={{ marginRight: 24, marginTop: 12 }}
                    />
                  )}
                </div>
                {showAddAnotherAddOn &&
                  ind === addOnAddedTo.length - 1 &&
                  addOnCategoryList.length > 0 && (
                    <ButtonPrimary
                      isSecondary={true}
                      label={"Add to another ticket"}
                      onPress={() => onAddAnotherAddOnClicked()}
                      width={328}
                      style={{ marginRight: 24, marginTop: 12 }}
                    />
                  )}
              </div>
            );
          })}
        </div>
      ) : addOnCategoryList.length > 0 ? (
        <div>
          {" "}
          <ButtonPrimary
            isSecondary={true}
            label={"Add to another ticket"}
            onPress={() => onAddAnotherAddOnClicked()}
            width={328}
            style={{ marginRight: 24, marginTop: 12 }}
          />
          <Text
            marginTop={11}
            variant={"label"}
            alignSelf={"flex-start"}
            color={"var(--red)"}
          >
            {toHaveAccessibleErrorMessageTicketAddOn?.includes("addedTo")
              ? "*Needs to select at least one ticket to add on"
              : ""}
          </Text>
        </div>
      ) : null}

      {shouldShowSubmitButton && (
        <div
          className="bottom-buttons-container-edit-view"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 40,
            justifyContent: "center",
          }}
        >
          <ButtonPrimary
            isSecondary={true}
            label={"Cancel"}
            onPress={() => onDiscardClicked()}
            width={328}
            style={{ marginRight: 24 }}
          />
          <ButtonPrimary
            label={"Save changes"}
            onPress={() => onSaveClicked()}
            width={328}
          />
        </div>
      )}

      {/* {addAnotherAddOn && (
        <ButtonPrimary
          style={{ marginTop: 40 }}
          isSecondary={true}
          label={"Create another Add On"}
          onPress={() => addAnotherAddOn()}
          width={328}
        />
      )} */}
    </div>
  );
}
