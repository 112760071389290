import React from "react";
import "../SummaryLabeledView/SummaryLabeledView.css";
import ButtonPill from "../../Common/Buttons/ButtonPill";
import Text from "../../Common/Text";
import { ReactComponent as EditFilled } from "../../../assets/svgs/EditFilled.svg";
import { ReactComponent as BinFilled } from "../../../assets/svgs/BinFilled.svg";
import { ReactComponent as BusinessPayments } from "../../../assets/svgs/BusinessPayments.svg";

export default function Discount({
  heading,
  discountAmount,
  eligibleCustomer,
  maxDiscountLimit,
  startDate,
  endDate,
  onEditClicked,
  deleteOption,
  onRemoveClicked,
  numberOfUses,
  status,
}) {
  return (
    <div
      style={{
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 0,
        padding: "20px",
      }}
      className="summary-labeled-div CommonBoxCont"
    >
      <div
        className="summary-header-inner-container summary-header-discount"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "32px",
        }}
      >
        <div className="generic-common-row-div">
          <BusinessPayments />
          <Text
            marginLeft={8}
            marginRight={8}
            color={"var(--midBlue)"}
            variant={"b300"}
          >
            {heading}
          </Text>
          <ButtonPill
            type={
              status === "Inactive"
                ? "expired"
                : status === null
                ? "error"
                : "completed"
            }
            varient={"small"}
            label={
              status === "Inactive"
                ? "Inactive"
                : status === null
                ? "Expired"
                : "Active"
            }
          />
        </div>
        <div
          className="discount-edit-save-container"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
            onClick={() => onEditClicked()}
          >
            <Text
              color={getComputedStyle(
                document.documentElement
              ).getPropertyValue("--midBlue")}
              marginRight={8}
              variant={"m300"}
            >
              Edit
            </Text>
            <EditFilled height={24} width={24} />
          </div>

          {deleteOption && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
              onClick={() => onRemoveClicked()}
            >
              <Text
                color={getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--red")}
                marginRight={8}
                marginLeft={24}
                variant={"m300"}
              >
                Remove
              </Text>
              <BinFilled
                fill={getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--red")}
                height={24}
                width={24}
              />
            </div>
          )}
        </div>
      </div>

      <div className="generic-common-column-div">
        <Text marginBottom={16} variant={"b200"}>
          {"Details"}
        </Text>

        <Text showBullet={true} marginBottom={8} variant={"m200"}>
          {/* {`${discountAmount} discount on total order`} */}
          {`${discountAmount} off per ticket`}

        </Text>
        <Text showBullet={true} marginBottom={8} variant={"m200"}>
          {eligibleCustomer}
        </Text>
        <Text showBullet={true} marginBottom={8} variant={"m200"}>
          {maxDiscountLimit}
        </Text>
        {startDate !== "DD/MM/YYYY" && endDate !== "DD/MM/YYYY" && (
          <Text showBullet={true} marginBottom={24} variant={"m200"}>
            {`Active from ${startDate} until ${endDate}`}
          </Text>
        )}

        {numberOfUses && (
          <Text marginBottom={8} marginTop={16} variant={"b200"}>
            {"Performance"}
          </Text>
        )}

        {numberOfUses && (
          <div className="generic-common-row-div">
            <Text showBullet={true} variant={"r200"}>
              {`Discount code has been applied to`}
            </Text>
            <Text color={"var(--midBlue)"} marginLeft={4} variant={"b200"}>
              {`${8} purchases.`}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
}
