import React, { useState } from "react";
import Text from "../Text";
import { ReactComponent as HeartFilled } from "../../../assets/svgs/HeartFilled.svg";
import { ReactComponent as CloseFilled } from "../../../assets/svgs/CloseFilled.svg";

export default function Tags({
  label,
  selected,
  onChangeSelection,
  withHeart,
  withClose,
  disabled,
  SourceIcon,
  paddingVertical,
  borderRadius,
  paddingHorizontal,
  backgroundColor,
  notBorder,
  hasError,
  style,
  className,
  textVairant,
}) {
  // constants and values
  const [pressed, setPressed] = useState(false);

  const tagsContainerInnerStyles = {
    paddingTop: !label
      ? paddingVertical + 3
      : paddingVertical
      ? paddingVertical
      : 5,
    paddingBottom: !label
      ? paddingVertical + 3
      : paddingVertical
      ? paddingVertical
      : 7,
    backgroundColor: backgroundColor
      ? backgroundColor
      : hasError
      ? getComputedStyle(document.documentElement).getPropertyValue("--red")
      : disabled
      ? getComputedStyle(document.documentElement).getPropertyValue(
          "--lightGrey"
        )
      : pressed
      ? getComputedStyle(document.documentElement).getPropertyValue(
          "--darkBlue"
        )
      : selected
      ? getComputedStyle(document.documentElement).getPropertyValue("--midBlue")
      : getComputedStyle(document.documentElement).getPropertyValue("--white"),
    paddingLeft: paddingHorizontal ? paddingHorizontal : 12,
    paddingRight: paddingHorizontal ? paddingHorizontal : 12,
    borderRadius: borderRadius ? borderRadius : 8,
    borderColor: disabled
      ? getComputedStyle(document.documentElement).getPropertyValue(
          "--lightGrey"
        )
      : hasError
      ? getComputedStyle(document.documentElement).getPropertyValue("--red")
      : pressed || selected
      ? getComputedStyle(document.documentElement).getPropertyValue("--midBlue")
      : getComputedStyle(document.documentElement).getPropertyValue(
          "--lightGrey"
        ),
    borderWidth: notBorder ? 0 : 1,
    borderStyle: "solid",
    alignItems: "center",
    flexDirection: "row",
    alignItems: "center",
    display: "flex",
    ...style,
  };

  return (
    <div className={className}>
      <div
        className="tags-inner-container create-event-btnCustom"
        style={{ ...tagsContainerInnerStyles }}
        // onMouseEnter={() => setPressed(true)}
        // onMouseLeave={() => setPressed(false)}
        onClick={() => onChangeSelection()}
        aria-disabled={disabled}
      >
        {withHeart && (
          <HeartFilled
            height={16}
            width={16}
            fill={
              disabled
                ? getComputedStyle(document.documentElement).getPropertyValue(
                    "--veryDarkGrey"
                  )
                : pressed || selected
                ? getComputedStyle(document.documentElement).getPropertyValue(
                    "--white"
                  )
                : getComputedStyle(document.documentElement).getPropertyValue(
                    "--midBlue"
                  )
            }
          />
        )}
        {SourceIcon && (
          <SourceIcon
            style={{ paddingTop: 1 }}
            height={16}
            width={16}
            stroke={
              hasError
                ? getComputedStyle(document.documentElement).getPropertyValue(
                    "--red"
                  )
                : null
            }
            fill={
              disabled
                ? getComputedStyle(document.documentElement).getPropertyValue(
                    "--veryDarkGrey"
                  )
                : pressed || selected
                ? getComputedStyle(document.documentElement).getPropertyValue(
                    "--white"
                  )
                : getComputedStyle(document.documentElement).getPropertyValue(
                    "--midBlue"
                  )
            }
          />
        )}
        {label && (
          <Text
            textWrap={"noWrap"}
            variant={textVairant ? textVairant : "m300"}
            color={
              disabled
                ? getComputedStyle(document.documentElement).getPropertyValue(
                    "--veryDarkGrey"
                  )
                : pressed || selected
                ? getComputedStyle(document.documentElement).getPropertyValue(
                    "--white"
                  )
                : getComputedStyle(document.documentElement).getPropertyValue(
                    "--midBlue"
                  )
            }
            marginLeft={SourceIcon || withHeart ? 8 : 0}
            marginRight={SourceIcon || withHeart ? 8 : 0}
          >
            {label}
          </Text>
        )}
        {withClose && (
          <CloseFilled
            style={label && { marginLeft: 4 }}
            fill={
              disabled
                ? getComputedStyle(document.documentElement).getPropertyValue(
                    "--veryDarkGrey"
                  )
                : pressed || selected
                ? getComputedStyle(document.documentElement).getPropertyValue(
                    "--white"
                  )
                : getComputedStyle(document.documentElement).getPropertyValue(
                    "--darkBlue"
                  )
            }
            height={16}
            width={16}
          />
        )}
      </div>
    </div>
  );
}
