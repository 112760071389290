import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AddFilled } from "../../assets/svgs/AddFilled.svg";
import ButtonPrimary from "../Common/Buttons/ButtonPrimary/ButtonPrimary";
import Text from "../Common/Text";
import styles from "./Header.module.css";

const Header = ({ headingText, hideCreateEventButton }) => {
  const navigate = useNavigate();

  return (
    <nav className={styles.nav}>
      <div className={styles.divHeader}>
        <div className="topCreate-eventHeader">
          <span class="PageTitle">
            <Text textclass="Heading-div" variant={"m600"}>
              {headingText ? headingText : "Your events"}
            </Text>
          </span>
          <div
            class="inner-div"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
          {!hideCreateEventButton && <ButtonPrimary
              showLeftIcon={true}
              icon={<AddFilled height={24} width={24} />}
              isSecondary={true}
              label={"Create event"}
              onPress={() => navigate("/CreateEvent")}
            />}
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Header;
