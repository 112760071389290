import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./SignUp.css";
import Api from "../../utils/Api";
import Footer from "../../Component/FooterNew/FooterNew";
import Text from "../../Component/Common/Text";
import ButtonPrimary from "../../Component/Common/Buttons/ButtonPrimary/ButtonPrimary";
import InputField from "../../Component/Common/InputFields/InputFieldPrimary/InputFieldPrimary";
import useNameVerification from "../../hooks/common/useNameVerification";
import useEmailVerification from "../../hooks/common/useEmailverification";
import usePasswordVerification from "../../hooks/common/usePasswordVerification";
import { ReactComponent as Icon } from "../../assets/svgs/Icon.svg";
import Banner from "../../Component/Common/Banner/Banner";
import { register, login } from "../../services/api";
import {
  SHOW_ACTIVITY_INDICATOR,
  BANNER_CONTENT,
  AUTH_TOKEN,
} from "../../actions";

function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showBanner, setShowBanner] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const { isVerified, verifyEmail } = useEmailVerification();
  const { verifyPassword } = usePasswordVerification();
  const [isVerifiedPassword, setIsVerifiedPassword] = useState(true);
  const [passwordVerification, setPasswordVerification] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const passwordCriteria = ["Length", "Upper", "Lower", "Number", "Symbol"];

  useEffect(() => {
    if (password !== "") {
      const validationArray = verifyPassword(password);
      setIsVerifiedPassword(validationArray.length > 0 ? false : true);
      setPasswordVerification(validationArray);
    }
  }, [password]);

  const ErrorCheck = (type, value, onSubmit) => {
    const nameVerification = useNameVerification(firstName);

    if (type === "fName") {
      if ((nameVerification === false && value !== "") || onSubmit == true) {
        setFirstNameError("First Name entered is not valid.");
      } else {
        setFirstNameError("");
      }
    }

    if (type === "lName") {
      if ((nameVerification === false && value !== "") || onSubmit == true) {
        setLastNameError("Last Name entered is not valid.");
      } else {
        setLastNameError("");
      }
    }

    if (type === "email") {
      if ((!isVerified && value !== "") || onSubmit == true) {
        setEmailError("Email address entered is not valid.");
      } else {
        setEmailError("");
      }
    }
    if (type === "password") {
      const validationArray = verifyPassword(password);
      if ((validationArray?.length !== 0 && value !== "" && !isVerifiedPassword) || onSubmit == true) {
        setPasswordError("Password entered does not meet criteria.");
      } else {
        setPasswordError("");
      }
    }

    if (type === "confirmPassword") {
      if ((password !== value && value !== "") || onSubmit == true) {
        setConfirmPasswordError("Confirm Password did not match.");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  const onSubmitLogin = async () => {
    const params = {
      emailAddress: email.trimEnd(),
      password: password,
      tokenExpiryDays: 30,
    };
    const result = await login(params);
    if (result?.status) {
      localStorage.setItem("authToken", JSON.stringify(result.response));
      dispatch({ type: AUTH_TOKEN, payload: result.response });
      Api.defaultHeader({
        authorization: `Bearer ${result.response}`,
      });
      navigate("/BusinessSignUp");
    } else {
      dispatch({
        type: BANNER_CONTENT,
        payload: {
          text: "Error signing up. Please try again.",
          headingText: "Sign up failed",
          showBanner: true,
          type: "Error",
          withClose: true,
          withIcon: true,
          position: "top",
          width: 391,
        },
      });
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  const onSubmitSignUp = async () => {
    if (
      firstName.trim() !== "" &&
      lastName.trim() !== "" &&
      email.trim() !== "" &&
      password.trim() !== "" &&
      confirmPassword.trim() !== "" &&
      firstNameError === "" &&
      lastNameError === "" &&
      emailError === "" &&
      passwordError === "" &&
      confirmPasswordError === ""
    ) {
      const params = {
        emailAddress: email.trimEnd(),
        password: password,
        firstName: firstName,
        lastName: lastName,
      };
      dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: true });
      const result = await register(params);
      if (result?.status) {
        localStorage.setItem("emailAddress", email.trimEnd());
        dispatch({
          type: BANNER_CONTENT,
          payload: {
            text: "Successfully signed up. Please sign up for business to continue.",
            headingText: "Sign up successful",
            showBanner: true,
            type: "Success",
            withClose: true,
            withIcon: true,
            position: "top",
            paddingLeft: "8px",
            parentContainerStyle: {
              top: "8px",
              zIndex: 15,
            },
          },
        });
        // navigate("/BusinessSignUp");
        navigate("/VerifyEmail");
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        // onSubmitLogin();
      } else {
        setShowBanner(true);
      }
    } else {
      if (firstName.trim() === "") {
        setFirstNameError("First name address required.");
      }
      if (lastName.trim() === "") {
        setLastNameError("Last name required.");
      }
      if (email.trim() === "") {
        setEmailError("Email address required.");
      }
      if (password.trim() === "") {
        setPasswordError("Password required.");
      }
      if (confirmPassword.trim() === "") {
        setConfirmPasswordError("Confirm password required.");
      }
    }
    dispatch({ type: SHOW_ACTIVITY_INDICATOR, payload: false });
  };

  return (
    <div className="page-wrapper-su">
      <div className="login-box-container-su">
        <Banner
          text={"There's an issue with your email address or password."}
          headingText={"Sign up failed"}
          showBanner={showBanner}
          type={"Error"}
          withIcon={true}
          withClose={true}
          onClose={() => setShowBanner(false)}
          position={"top"}
        />
        <div className="login-inner-box-container-su">
        <div className="generic-common-row-centerd-div">
          <Icon height={64} width={64}/>
          <Text variant="b500" marginLeft={8}>
          Box Office
              </Text>
          </div>

          <div className="topLogin-text">
            <div className="topLogin-tittle-text">
              <Text variant="m700" marginTop={16} marginBottom={24}>
                Create an account
              </Text>
            </div>
            <div className="bottomLogin-prg-text">
              <Text variant="r300" marginBottom={32}>
                Please enter your details to create an account.
              </Text>
            </div>
          </div>

          <div className="innerForm-account">
            <label>
              <Text
                alignSelf={"start"}
                labelclass="login-form-label"
                variant="m300"
                marginBottom={8}
              >
                First name
              </Text>
            </label>

            <InputField
              capitalizeWords={true}
              inputValue={firstName}
              placeholder={"Enter first name"}
              secureTextEntry={false}
              editable={true}
              type={"general"}
              onChangeValue={(text) => (
                setFirstNameError(""), setFirstName(text)
              )}
              onBlur={(value) => ErrorCheck("fName", value)}
              assistive={firstNameError}
            />

            <label>
              <Text
                alignSelf={"start"}
                labelclass="login-form-label"
                variant="m300"
                marginTop={24}
                marginBottom={8}
              >
                Last name
              </Text>
            </label>

            <InputField
              capitalizeWords={true}
              inputValue={lastName}
              placeholder={"Enter last name"}
              secureTextEntry={false}
              editable={true}
              type={"general"}
              onChangeValue={(text) => (
                setLastNameError(""), setLastName(text)
              )}
              onBlur={(value) => ErrorCheck("lName", value)}
              assistive={lastNameError}
            />

            <label>
              <Text
                alignSelf={"start"}
                labelclass="login-form-label"
                variant="m300"
                marginTop={24}
                marginBottom={8}
              >
                Email address
              </Text>
            </label>

            <InputField
              inputValue={email}
              placeholder={"Enter email address"}
              secureTextEntry={false}
              editable={true}
              type={"email"}
              onChangeValue={(text) => (
                setEmailError(""), setEmail(text), verifyEmail(text)
              )}
              onBlur={(value) => ErrorCheck("email", value)}
              assistive={emailError}
            />

            <label>
              <Text
                alignSelf={"start"}
                labelclass="login-form-label"
                variant="m300"
                marginTop={24}
                marginBottom={8}
              >
                Password
              </Text>
            </label>

            <InputField
              inputValue={password}
              placeholder={"Enter password"}
              secureTextEntry={true}
              editable={true}
              onChangeValue={(text) => (
                setPasswordError(""), setPassword(text), verifyPassword(text)
              )}
              onFocus={() => setIsFocused(true)}
              onBlur={(value) => {ErrorCheck("password", value); setIsFocused(false);}}
              assistive={passwordError}
            />
            {isFocused && (
            <div
              style={{ alignSelf: "start", flexWrap: "wrap"}}
              className="generic-common-row-div"
            >
              {passwordCriteria.map((item, index) => {
                return (
                  <div key={index} style={{marginRight: 3}} className="generic-common-row-div">
                    <Text marginRight={3} variant={"r100"} marginTop={8}>
                      {`${item}: `}
                    </Text>
                    <Text
                      variant={"r100"}
                      color={
                        passwordVerification.includes(item) ? "red" : "green"
                      }
                      marginTop={8}
                    >
                      {`${passwordVerification.includes(item) ? "No" : "Yes"}${index !== 4 ? ",  " : ""}  `}
                    </Text>
                  </div>
                );
              })}
            </div>
          )}

            <label>
              <Text
                labelclass="login-form-label"
                variant="m300"
                marginTop={24}
                marginBottom={8}
                alignSelf={"start"}
              >
                Confirm password
              </Text>
            </label>

            <InputField
              inputValue={confirmPassword}
              placeholder={"Confirm your password"}
              secureTextEntry={true}
              editable={true}
              onChangeValue={(text) => (
                setConfirmPasswordError(""), setConfirmPassword(text)
              )}
              onBlur={(value) => ErrorCheck("confirmPassword", value)}
              assistive={confirmPasswordError}
            />

            <div className="login-sign-in-up-container">
              <div className="bottomLogin-btn">
                <ButtonPrimary
                  width={360}
                  label={"Sign up"}
                  onPress={() => onSubmitSignUp()}
                />
              </div>

              <div className="already-account">
                <Text
                  cursor={"pointer"}
                  onClick={() => navigate("/Login")}
                  color={"var(--midBlue)"}
                  variant="m300"
                  marginTop={30}
                >
                  Already have an account? Sign in
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default SignUp;
