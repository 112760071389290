import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as User } from "../../../../assets/svgs/User.svg";
import { debounce } from "lodash";
import { ReactComponent as CloseFilled } from "../../../../assets/svgs/CloseFilled.svg";
import { ReactComponent as SuccessFilled } from "../../../../assets/svgs/SuccessFilled.svg";
import { ReactComponent as UserFilled } from "../../../../assets/svgs/UserFilled.svg";
import { ReactComponent as ErrorFilled } from "../../../../assets/svgs/ErrorFilled.svg";
import { ReactComponent as HideFilled } from "../../../../assets/svgs/HideFilled.svg";
import { ReactComponent as ViewFilled } from "../../../../assets/svgs/ViewFilled.svg";
import { ReactComponent as LockFilled } from "../../../../assets/svgs/LockFilled.svg";
import { ReactComponent as MailFilled } from "../../../../assets/svgs/MailFilled.svg";
import Text from "../../Text";
import "./InputFieldPrimary.css";

export default function InputField({
  placeholder,
  secureTextEntry,
  onChangeValue,
  onBlur = () => {},
  onFocus,
  inputValue,
  editable,
  withOutIcon,
  assistive,
  descriptive,
  warningDescriptive,
  type,
  passwordVisible,
  isError,
  onSubmitEditing,
  verifyNotNeed,
  isNumeric,
  style,
  shouldShowErrorOnAssistive,
  maxLength,
  hasDebounce,
  shouldShowSuceessIcon,
  endSymbol,
  prefix = "",
  greenRightEndText,
  maxValue,
  inputRef, capitalizeWords,
}) {
  // constants and values
  const inputReference = useRef(null);
    const [value, setValue] = useState(inputValue ? inputValue : "");
  const [isFocused, setIsFocused] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(
    passwordVisible || false
  );
  const [fieldFocused, setFieldFocused] = useState(false);
  useEffect(() => {
    setValue(inputValue ? inputValue : "");
  }, [inputValue]);

  useEffect(() => {
    if (value === "") {
      setFieldFocused(false);
    }
  }, [value]);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleFocus = () => {
    setIsFocused(true);
    passwordVisible && setFieldFocused(false);
    onFocus && onFocus();
  };

  const handleBlur = () => {
    setIsFocused(false);
    setFieldFocused(true);
    onBlur(value);
  };

  const capitalizeFirstWord = (text) => {
    return text
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");;
  };

  const onChangeText = (event) => {
    let numericValue;
    if (isNumeric === true) {
      numericValue = event?.target?.value.replace(/[^\d.]/g, "");
      // if (maxValue && numericValue > maxValue) {
      //   numericValue = maxValue; // Set to max value if it exceeds
      // }
      setValue(numericValue ? numericValue : "");
      onChangeValue(numericValue ? numericValue : "");
    }else if(capitalizeWords){
      const text = capitalizeFirstWord(event ? event.target.value : "");
      setValue(text);
      onChangeValue(text);
    }
     else {
      setValue(event ? event.target.value : "");
      onChangeValue(event ? event.target.value : "");
    }
  };

  const onChangeTextWithDebounce = debounce((text) => {
    setValue(text);
    onChangeValue(text);
  }, 100);

  const delimiter = "/";

  const correctInput = (event) => {
    const { target, key } = event;
    const { value } = target;

    if (key === "Backspace") {
      // Handle backspace
      if (value.length === 3 && value[2] === delimiter) {
        target.value = value.slice(0, 2);
        event.preventDefault();
        return;
      }
      if (value.length === 2 && value[1] === delimiter) {
        target.value = value.slice(0, 1);
        event.preventDefault();
        return;
      }
    }

    let typeKey;

    const monthMin = "01";
    const monthMax = "12";
    const yearMin = new Date().getFullYear() - 2000;
    const yearMax = new Date().getFullYear() - 2000 + 25;

    if (/(\d|ArrowLeft|ArrowRight|Backspace|Delete|Tab)/.test(key)) {
      if (/(\d)/.test(key)) {
        typeKey = "number";
      } else {
        typeKey = "specSymbol";
      }

      if (value.length === 0 && key > 1) {
        target.value = "0" + key + delimiter;
        event.preventDefault();
      }

      if (value.length === 1 && value[0] !== "0" && key > 2) {
        target.value = monthMax + delimiter;
        event.preventDefault();
        return;
      }

      if (value.length === 1 && typeKey !== "specSymbol") {
        target.value = value + key + delimiter;
        event.preventDefault();
      }

      if (value.length === 2 && typeKey !== "specSymbol") {
        target.value = value + delimiter + key;
        event.preventDefault();
        return;
      }

      if (value === "0" && key === "0") {
        target.value = monthMin + delimiter;
        event.preventDefault();
        return;
      }

      if (target.value.length + 1 === maxLength) {
        const arr = target.value.split(delimiter);

        if (arr[0].length === 2 && parseInt(arr[1] + key, 10) < yearMin) {
          target.value = arr[0] + delimiter + yearMin;
          event.preventDefault();
          return;
        }

        if (arr[0].length === 2 && parseInt(arr[1] + key, 10) > yearMax) {
          target.value = arr[0] + delimiter + yearMax;
          event.preventDefault();
          return;
        }
      }
    } else {
      event.preventDefault();
      return;
    }
  };

  const containerInputStyles = {
    backgroundColor:
      verifyNotNeed && editable
        ? getComputedStyle(document.documentElement).getPropertyValue("--white")
        : isFocused
        ? getComputedStyle(document.documentElement).getPropertyValue(
            "--lightStateBlue"
          )
        : editable === false
        ? getComputedStyle(document.documentElement).getPropertyValue(
            "--veryLightGrey"
          )
        : getComputedStyle(document.documentElement).getPropertyValue(
            "--white"
          ),

    borderColor: verifyNotNeed
      ? getComputedStyle(document.documentElement).getPropertyValue(
          "--lightGrey"
        )
      : isFocused
      ? getComputedStyle(document.documentElement).getPropertyValue("--midBlue")
      : getComputedStyle(document.documentElement).getPropertyValue(
          "--lightGrey"
        ),
    ...style,
  };
  const onClickOutsideListener = () => {
     handleBlur();
    document.removeEventListener("click", onClickOutsideListener);
  };
  return (
    <div
      style={{ width: "100%" }}
      onMouseLeave={() => {
        document.addEventListener("click", onClickOutsideListener);
      }}
    >
      <div className="containerInput" style={{ ...containerInputStyles }}>
        {withOutIcon !== true && (
          <div className="iconContainerInput">
            {type === "email" ? (
              <MailFilled height={24} width={24} />
            ) : type?.includes("verify") ? (
              <SuccessFilled
                fill={
                  editable
                    ? getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--midBlue")
                    : getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--midGrey")
                }
                height={24}
                width={24}
              />
            ) : secureTextEntry || type === "password" ? (
              <LockFilled
                fill={
                  editable
                    ? getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--midBlue")
                    : getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--midGrey")
                }
                height={24}
                width={24}
              />
            ) : (
              <UserFilled
                stroke={
                  editable
                    ? getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--midBlue")
                    : getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--midGrey")
                }
                height={24}
                width={24}
              />
            )}
          </div>
        )}
        
        <input
          ref={inputRef ? inputRef : inputReference}
          onKeyDown={type === "expiryDate" ? correctInput : null}
          maxLength={maxLength ? maxLength : null}
          disabled={editable ? false : true}
          style={{
            border: 0,
            borderWidth: 0,
            borderColor: "transparent",
            color: editable ? "var(--midnightBlue)" : "var(--grey)",
          }}
          type={secureTextEntry && !isPasswordVisible ? "password" : "text"}
          className="inputFieldPrimary"
          placeholder={placeholder}
          autoCapitalize={
            type?.includes("email") || secureTextEntry ? "none" : "words"
          }
          onFocus={handleFocus}
          // onBlur={handleBlur}
          value={value}
          onChange={(event) => {
            const text = event.target.value;
            hasDebounce ? onChangeTextWithDebounce(text) : onChangeText(event);
          }}
        />
        {prefix && (
          <Text color={'var(--newTextBody)'} textWrap={'noWrap'} variant={"r200"} style={{ marginLeft: 10, marginTop: 0 }}>
            {prefix}
          </Text>
        )}
        {greenRightEndText && (
          <Text
            textWrap={"noWrap"}
            variant={"r100"}
            color={maxValue ? "var(--red)" : "var(--green)"}
            style={{ marginLeft: 10, marginTop: 0 }}
          >
            {maxValue ? "Ticket allocation limit exceeded" : greenRightEndText}
          </Text>
        )}
        {/* to check Password visibility   */}
        {secureTextEntry && value != "" && (
          <div
            disabled={editable ? false : true}
            onClick={togglePasswordVisibility}
          >
            <div className="iconContainerInput">
              {isPasswordVisible ? (
                <HideFilled
                  fill={
                    editable
                      ? getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--midBlue")
                      : getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--midGrey")
                  }
                  height={24}
                  width={24}
                />
              ) : (
                <ViewFilled
                  fill={
                    editable
                      ? getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--midBlue")
                      : getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--midGrey")
                  }
                  height={24}
                  width={24}
                />
              )}
            </div>
          </div>
        )}
        {((editable && !verifyNotNeed) ||
          shouldShowErrorOnAssistive ||
          endSymbol) && (
          <div
            className="iconContainerInput"
            onClick={() => (setValue(""), onChangeValue(""), inputReference?.current?.focus())}
            disabled={isFocused && value != "" ? false : true}
          >
            {endSymbol ? (
              endSymbol
            ) : isFocused && value != "" && !shouldShowErrorOnAssistive ? (
              <CloseFilled
                height={24}
                width={24}
                fill={getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--veryDarkBlue")}
              />
            ) : value != "" &&
              !assistive &&
              type?.includes("genericInput") === false &&
              !shouldShowErrorOnAssistive ? (
              <SuccessFilled height={24} width={24} />
            ) : value != "" &&
              !assistive &&
              type?.includes("genericInput") ? null : editable &&
              !isFocused &&
              assistive &&
              // fieldFocused &&
              !isError ? (
              <ErrorFilled height={24} width={24} />
            ) : isError ? (
              <ErrorFilled height={24} width={24} />
            ) : shouldShowSuceessIcon && isFocused && value != "" ? (
              <CloseFilled
                height={24}
                width={24}
                fill={getComputedStyle(
                  document.documentElement
                ).getPropertyValue("--veryDarkBlue")}
              />
            ) : shouldShowSuceessIcon &&
              !isFocused &&
              !assistive &&
              value != "" ? (
              <SuccessFilled height={24} width={24} />
            ) : null}
          </div>
        )}
        {/* {editable && !isFocused && assistive && fieldFocused && !isError && (
          <ErrorFilled height={24} width={24} />
        )}
        {isError && <ErrorFilled height={24} width={24} />} */}
      </div>
      {!isFocused && assistive ? (
        <Text
          marginTop={8}
          variant={"r100"}
          color={
            editable
              ? getComputedStyle(document.documentElement).getPropertyValue(
                  "--red"
                )
              : getComputedStyle(document.documentElement).getPropertyValue(
                  "--midnightBlue"
                )
          }
        >
          {assistive}
        </Text>
      ) : null}
      {(descriptive || warningDescriptive) && !assistive ? (
        <Text
          marginTop={8}
          variant={"r100"}
          color={warningDescriptive ? "var(--red)" : "var(--grey)"}
        >
          {warningDescriptive ? warningDescriptive : descriptive}
        </Text>
      ) : null}
    </div>
  );
}
