import { DRAFT_DATA } from '../actions';
export default function draftData(state = [], action) {
  switch (action.type) {
    case DRAFT_DATA: {
      return {
        ...state,
        draftData: action.payload,
      };
    }

    default:
      return state;
  }
}