import React, { useEffect, useState } from "react";
import "../EventDetails/EventDetails.css";
import InputField from "../../../Component/Common/InputFields/InputFieldPrimary/InputFieldPrimary";
import ButtonPrimary from "../../../Component/Common/Buttons/ButtonPrimary/ButtonPrimary";
import { ReactComponent as EditFilled } from "../../../assets/svgs/EditFilled.svg";
import Text from "../../../Component/Common/Text";

export default function TotalTickets({
  onTicketQuantityChange,
  shouldShowSubmitButton,
  onSaveClicked,
  onDiscardClicked,
  ticketQuantity,
  shouldShowSummary,
  onEditClicked,
}) {
  const [ticketQuantityVal, setTicketQuantityVal] = useState(ticketQuantity);

  useEffect(() => {
    setTicketQuantityVal(ticketQuantity);
  }, [ticketQuantity]);

  return (
    <div className="event-about-div">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="generic-common-row-div-space-between" style={{textAlign: "left"}}>
          {/* <Text marginRight={16} variant={"m500"}> */}
          <Text width={"100%"} variant={"m500"}>
            Total amount of tickets
          </Text>
          {shouldShowSummary && (
            <div
              className="generic-common-row-div"
              onClick={() => onEditClicked()}
            >
              <Text color={"var(--midBlue)"} marginRight={8} variant={"m300"}>
                Edit
              </Text>
              <EditFilled height={24} width={24} />
            </div>
          )}
        </div>

        <Text marginTop={16} variant={"r300"}>
        Set a total number of tickets to be sold instead of individual ticket quantities.
        </Text>
      </div>

      <div className="CreateEventMaxNoOfTickets"
        style={{
          marginTop: 40,
          display: "flex",
          width: "50%",
          paddingRight: 32,
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Text
          color={shouldShowSummary ? "var(--grey)" : "var(--midnightBlue)"}
          marginBottom={8}
          variant={"m300"}
        >
          Max No. Of Tickets (optional)
        </Text>

        <InputField
          isNumeric={true}
          inputValue={ticketQuantity}
          verifyNotNeed={true}
          placeholder={"Enter ticket quantity"}
          secureTextEntry={false}
          editable={!shouldShowSummary}
          type={"eventName"}
          withOutIcon={true}
          isError={false}
          onChangeValue={(text) => {
            setTicketQuantityVal(text);
            onTicketQuantityChange(text);
          }}
          onBlur={(value) => {}}
          assistive={""}
        />

        <Text color={"var(--grey)"} marginTop={8} variant={"r100"}>
          This will override the individual tickets quantities for sale
        </Text>
      </div>

      {shouldShowSubmitButton && (
        <div
          className="bottom-buttons-container-edit-view"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 40,
            justifyContent: "center",
          }}
        >
          <ButtonPrimary
            isSecondary={true}
            label={"Cancel"}
            onPress={() => onDiscardClicked()}
            width={328}
            style={{ marginRight: 24 }}
          />
          <ButtonPrimary
            label={"Save changes"}
            onPress={() => onSaveClicked()}
            width={328}
          />
        </div>
      )}
    </div>
  );
}
