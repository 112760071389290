import React, { useState } from "react";
import "./Controls.css";
import { ReactComponent as UnSelectedRadioButton } from "../../../assets/svgs/UnSelectedRadioButton.svg";
import { ReactComponent as RadioButtonSelected } from "../../../assets/svgs/RadioButtonSelected.svg";
import { ReactComponent as RadioButtonDisabled } from "../../../assets/svgs/RadioButtonDisabled.svg";
import Text from "../Text";

const RadioButton = ({
  data,
  onSelect,
  selectIndex,
  type,
  vertically,
  twoColumn,
  styles,
  marginRight,
  warningLabelText,
}) => {
  // constants and values
  const [selectedIndex, setSelectedIndex] = useState(selectIndex);

  // function to toggle the selection
  const handlePress = (item, index) => {
    
    setSelectedIndex(index);
    onSelect && onSelect(item, index);
  };

  const twoColumnStyle = twoColumn && {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: 24,
    rowGap: 0,
  };

  return (
    <div style={{flex: '1 1 0%'}} className="generic-common-column-div">
    <div className="RadioRow"
      style={{
        flex: 1,
        display: "flex",
        flexDirection: vertically ? "column" : "row",
        width: "100%",
        ...twoColumnStyle,
      }}
    >
      {data.map((item, index) => (
        <div
          className="option-container"
          aria-disabled={type === "disabled" ? true : false}
          key={item.value}
          style={{
            marginBottom: twoColumn ? 16 : 24,
            marginRight:
              data.length - 1 === index ? 0 : marginRight ? marginRight : 64,
            backgroundColor:
              type === "disabled"
                ? "transparent"
                : getComputedStyle(document.documentElement).getPropertyValue(
                    "--white"
                  ),
            borderColor:
              selectedIndex === index
                ? getComputedStyle(document.documentElement).getPropertyValue(
                    "--green"
                  )
                : getComputedStyle(document.documentElement).getPropertyValue(
                    "--lightGrey"
                  ),
            boxShadow:
              type === "disabled"
                ? null
                : "1px 1px 1px 1px rgba(0, 0, 0, 0.03)",
            ...styles,
          }}
          onClick={() => handlePress(item, index)}
          >
          {selectedIndex === index ? (
            <RadioButtonSelected />
          ) : type === "disabled" ? (
            <RadioButtonDisabled />
          ) : (
            <UnSelectedRadioButton />
          )}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Text
              variant={"m300"}
              color={
                type === "disabled"
                  ? getComputedStyle(document.documentElement).getPropertyValue(
                      "--midGrey"
                    )
                  : getComputedStyle(document.documentElement).getPropertyValue(
                      "--midnightBlue"
                    )
              }
              marginLeft={20}
            >
              {item.text}
            </Text>
            {item.subDetails && (
              <Text
                variant={"r200"}
                color={
                  type === "disabled"
                    ? getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--midGrey")
                    : getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--midnightBlue")
                }
                marginTop={8}
                marginLeft={20}
              >
                {item.subDetails}
              </Text>
            )}
          </div>
        </div>
      ))}
    </div>
    {warningLabelText && <Text marginTop={8} variant="r100">{warningLabelText}</Text>}
    </div>
  );
};

export default RadioButton;
