import React, { useState } from "react";
import Text from "../../Text";
import ChevronRight from "../../../../assets/svgs/ChevronRight.svg";
import "./ButtonPrimary.css";

export default function ButtonPrimary({
  label,
  showLeftIcon,
  onPress,
  disabled,
  icon,
  style,
  width,
  isSecondary,
  type,
  isClickingEffectEnabled,
  textWrap
}) {
  // constants and values
  const [pressed, setPressed] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const divStyles = {
    cursor: !disabled ? "pointer" : 'not-allowed',
    width: width + 20 || "100%",
    backgroundColor:
      isHovered && !isSecondary && !disabled
        ? "#3169DF"
        : pressed && isClickingEffectEnabled && !disabled
        ? "#1F509E"
        : disabled
        ? getComputedStyle(document.documentElement).getPropertyValue(
            "--lightGrey"
          )
        : isSecondary
        ? getComputedStyle(document.documentElement).getPropertyValue("--white")
        : getComputedStyle(document.documentElement).getPropertyValue(
            "--midBlue"
          ),
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: disabled
      ? getComputedStyle(document.documentElement).getPropertyValue(
          "--lightGrey"
        )
      : type === "error"
      ? getComputedStyle(document.documentElement).getPropertyValue("--red")
      : getComputedStyle(document.documentElement).getPropertyValue(
          "--midBlue"
        ),
    ...style,
  };

  return (
    <div
      className="Box1"
      id="box1"
      style={{ ...divStyles }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onMouseDown={() => setPressed(true)}
      onMouseUp={() => setPressed(false)}
      onClick={() => {
        !disabled && onPress();
      }}
      aria-disabled={disabled}
    >
      {/* check showLeftIcon flag for show/hide the Chevron right icon */}
      {showLeftIcon &&
        (icon ? (
          icon
        ) : (
          <ChevronRight
            width={24}
            height={24}
            // stroke={disabled ? veryDarkGrey : white}
          />
        ))}
      <Text textWrap={textWrap ? textWrap : 'nowrap'}
        style={{ cursor: !disabled && "pointer" }}
        marginLeft={showLeftIcon ? 8 : 0}
        variant={"m300"}
        color={disabled ? "var(--grey)" :
          pressed && isClickingEffectEnabled && !disabled
            ? "var(--white)"
            : type === "error"
            ? getComputedStyle(document.documentElement).getPropertyValue(
                "--red"
              )
            : isSecondary
            ? getComputedStyle(document.documentElement).getPropertyValue(
                "--midBlue"
              )
            : disabled
            ? getComputedStyle(document.documentElement).getPropertyValue(
                "--veryDarkGrey"
              )
            : getComputedStyle(document.documentElement).getPropertyValue(
                "--white"
              )
        }
      >
        {label}
      </Text>
    </div>
  );
}
