import React, { useState } from "react";
import "./Controls.css";
import { ReactComponent as UnSelectedRadioButton } from "../../../assets/svgs/UnSelectedRadioButton.svg";
import { ReactComponent as RadioButtonSelected } from "../../../assets/svgs/RadioButtonSelected.svg";
import { ReactComponent as RadioButtonDisabled } from "../../../assets/svgs/RadioButtonDisabled.svg";
import Text from "../Text";
import ButtonPill from "../Buttons/ButtonPill";

const CardRadioButton = ({
  data,
  onSelect,
  selectIndex,
  type,
  vertically,
  twoColumn,
  styles,
  marginRight,
}) => {
  // constants and values
  const [selectedIndex, setSelectedIndex] = useState(selectIndex);

  // function to toggle the selection
  const handlePress = (item, index) => {
    
    setSelectedIndex(index);
    onSelect && onSelect(item, index);
  };

  const twoColumnStyle = twoColumn && {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: 24,
    rowGap: 0,
  };

  return (
    <div className="slelectPay-now-items-container"
      style={{
        flex: 1,
        display: "flex",
        flexDirection: vertically ? "column" : "row",
        ...twoColumnStyle,
      }}
    >
      {data.map((item, index) => (
        <div
          className="option-container slelectPay-now-item"
          aria-disabled={type === "disabled" ? true : false}
          key={item.value}
          style={{
            justifyContent: "space-between",
            marginBottom: twoColumn ? 16 : 24,
            marginRight:
              data.length - 1 === index ? 0 : marginRight ? marginRight : 64,
            backgroundColor: "var(--white)",
            borderColor: "var(--lightGrey)",
            boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.03)",
            ...styles,
          }}
          onClick={() => handlePress(item, index)}
        >
          <div className="generic-common-row-div">

                <div className="img-payCard">
                  <img style={{ height: 26, width: 46 }} src={`data:image/jpeg;base64,${item?.brandLogoBase64}`} alt="Base64" />      
              
                    <Text marginLeft={16} marginRight={20} variant={"m300"}>
                    {`${item?.brand} • ${item?.last4}`}
                    </Text>
                </div>
                
                {item?.isDefault && <div className="default-tag">
                    <ButtonPill
                      type={"generic"}
                      varient={"small"}
                      label={"Default"}
                    />
                </div> }
              
    
            </div>
          <div>
            {selectedIndex === index ? (
              <RadioButtonSelected />
            ) : type === "disabled" ? (
              <RadioButtonDisabled />
            ) : (
              <UnSelectedRadioButton />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardRadioButton;
