export const EventCreationAccordion1 = [
  {
    heading: `Is all information required when creating an event?`,
    label: `No – the only required fields are marked with an asterisk (*).`,
  },
  {
    heading: `How can I save the event details to publish later?`,
    label: `Once you have provided all required fields on the event creation page, the option to save the event will appear at the bottom of the page.`,
  },
  {
    heading: `What are Entrances & Scanning points?`,
    label: `An Entrance is any entry point in the venue that you want to scan tickets using our Venue app.  If you have multiple entrances then you can create a scanning point for each to give you a clear picture of where people are entering your event.\n\nIf you only have one entrance then just add Main Entrance. You can also come back and add additional entrances closer to the event.\n\n A scanning point is anywhere else in the venue that you want to scan tickets or add-ons, for example, you want to scan the merch add-on at the merch shop or the car park before they reach an entrance.
`,
  },
  {
    heading: `Why do I need to create a Venue App login?`,
    label: `Our Venue app allows event staff to scan tickets and add-ons. You need to create a login and password that your event staff will use to login to the Venue app before they are able to scan tickets.`,
  },
  {
    heading: `What is the difference between Platform Fee and Booking Fee?`,
    label: `The Platform Fee is the fee we charge per ticket, it can be added on to the ticket price or absorbed by you. 

The Booking Fee is an optional fee that you can add, bookings fees are split 20/80 with Twotixx, you receive 80% and we receive 20%.
`,
  },
  {
    heading: `How many ticket types can I add?`,
    label: `You can add as many ticket types as you like.`,
  },
  {
    heading: `What is a Staged Release?`,
    label: `A staged ticket release is a strategy where event tickets are sold in phases, allowing organisers to manage demand and adjust prices over time. Each stage may offer different prices, usually release 1 would be the lowest price and there would be a price increase at each release.`,
  },
  {
    heading: `Can I add additional images or a video?`,
    label: `Currently Twotixx doesn’t support additional images or video.`,
  },
];

export const EventCreationAccordion2 = [
  {
    heading: `What is an Add On?`,
    label: `A ticket add-on is an additional purchase option for events, offering extras like car parking, merchandise, or food and beverage packages. Add ons are a great incremental revenue opportunity.`,
  },
  {
    heading: `Can I sell more than one Add On per ticket type?`,
    label: `Yes, you sure can. You can sell as many as you like, you can also choose which add ons can be sold with what ticket type. For instance a VIP bar package could only be sold with a VIP ticket.`,
  },
  {
    heading: `How are Add Ons delivered to the buyer?`,
    label: `Add Ons show alongside the buyers ticket in the Twotixx App, on event day you can scan the Add On just as you would a ticket.`,
  },
  {
    heading: `Why do I need to verify my profile?`,
    label: `We verify the identities of event organisers to protect attendees and prevent misconduct.`,
  },
  {
    heading: `How do I verify my profile to publish my event?`,
    label: `Verifying your identity is easy, simply follow the instructions, photograph a valid ID and pass a likeness test using your phone camera.`,
  },
  {
    heading: `How do I set up a Stripe Merchant account?`,
    label: `Follow the link through Twotixx to create a new stripe account, once created you’ll be able to collect your ticket payments direct to your account.`,
  },
  {
    heading: `How are the Tickets delivered to buyers?`,
    label: `When a customer buys tickets, they provide their mobile number, which we use to either create a new account or identify an existing one. The tickets are delivered to this account and can be accessed only through the Twotixx app.`,
  },
  {
    heading: `Can you help guide me through the event creation?`,
    label: `Yes, we’d be happy to help you. Please contact us to arrange an onboarding call.`,
  },
];

export const KYCAccordion1 = [
  {
    heading: `What is TicketGuard?`,
    label: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris auctor dui in sem euismod, ac accumsan velit tempus. Duis vel ex id augue congue mollis. Mauris tincidunt at urna venenatis mollis.`,
  },
  {
    heading: `What benefit can TicketGuard add to my event?`,
    label: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris auctor dui in sem euismod, ac accumsan velit tempus. Duis vel ex id augue congue mollis. Mauris tincidunt at urna venenatis mollis.`,
  },
  {
    heading: `Do I need additional hardware for TicketGuard’s events?`,
    label: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris auctor dui in sem euismod, ac accumsan velit tempus. Duis vel ex id augue congue mollis. Mauris tincidunt at urna venenatis mollis.`,
  },
];

export const ManagingEventAccordion1 = [
  {
    heading: `Can I edit my ticket quantities?`,
    label: `Yes, to update ticket quantities in Twotixx Box Office, open your event, and click the 'Ticket' tab, from here you can manage your tickets and quantities available for sale.`,
  },
  {
    heading: `Can I pause Ticket Sales?`,
    label: `Yes, to pause ticket sales, go to your event overview in Twotixx Box Office, scroll down to the stats section, and click the 'Pause Ticket Sales' button.`,
  },
  {
    heading: `Can I reschedule my event?`,
    label: `Yes, to reschedule your event in Twotixx Box Office, open your event, and click on the 'Event Details' tab.

If tickets have been sold, we will automatically notify ticket holders. We also recommend you inform ticket holders of the change through social media and email.
`,
  },
];

export const ManagingEventAccordion2 = [
  {
    heading: `Can I cancel my event?`,
    label: `Yes, you can cancel your event. If tickets have been sold, we will automatically notify ticket holders.  You must issue full refunds to the buyers. Failing to do so may lead ticket buyers to initiate a dispute with their bank, resulting in additional fees and administrative tasks for you.`,
  },
  {
    heading: `How do I issue refunds to ticket buyers?`,
    label: `If you need to issue a refund, you can do so directly in the Twotixx Box Office. Navigate to your event overview, scroll down to the stats section, and click on 'View Order History.' From there, you can manage orders, cancellations, and refunds.

You can also refund and manage any transactions with your customer directly in Stripe. However, refunds made in Stripe will not update your tickets, orders or revenue in Box Office. Please note that if refunds are managed directly in strip then the ticket would need cancelling in Box Office.
`,
  },
  {
    heading: `Can I turn off Ticket Transfer permissions?`,
    label: `No, the ticket transfer feature cannot be disabled. We have implemented measures to prevent misuse. For example, a warning message is displayed, and Twotixx is notified if an account exhibits signs of system abuse, such as scalping. This helps prevent unauthorised financial transactions outside of Twotixx.

The ticket transfer process is streamlined: it only requires a phone number to identify and verify an account, eliminating the risk of email typos.`,
  },
];

export const Marketing1 = [
  {
    heading: `Can I send an event Update to my event attendees?`,
    label: `Yes, you can. Visit your event in the Box Office and click on Communication. This tool will allow you to send a message to all ticket holders. 

You are expected to maintain a high standard of communication, free from offensive language, threats, harassment, or any form of scamming activity.`,
  },
  {
    heading: `Can I notify previous event attendees about my new event?`,
    label: `Yes, you sure can. The advised method is to connect a Mailchimp account and send a marketing Email.`,
  },
];

export const Marketing2 = [
  {
    heading: `How can I connect my Mailchimp account?`,
    label: `To connect your Mailchimp account, go to Settings, then Connections, and select "Connect to Mailchimp." Sign in to your Mailchimp account and follow the prompts.`,
  },
  {
    heading: `How can I export attendee email addresses and phone numbers?`,
    label: `To export a list of your attendees with their email and phone number, select your event from the "Box Office" menu on the left. Then, go to the Event Overview and scroll down to the "View Attendees" section. From here, you can choose to export the user data.`,
  },
];

export const TicketGuard1 = [
  {
    heading: `What is Ticketguard?`,
    label: `TicketGuard is a Twotixx solution that enhances event security through identity-verified accounts and attendee traceability. Attendees at a TicketGuarded event must verify their identities before the event by submitting a photo of their driving licence or passport, which is then checked against a government database. At the event entrance, their verification selfie is displayed on the Venue App, allowing event staff to confirm the identity of each attendee, ensuring that only verified individuals gain entry.

Even if a ticket is transferred, numerous times the new ticket holder will be required to complete verification`,
  },
  {
    heading: `What benefit can TicketGuard add to my event?`,
    label: `TicketGuard significantly enhances the safety and peace of mind for attendees at events. By ensuring that all attendees are identity-verified, individuals can confidently attend your event, knowing they are among verified attendees. 

This system not only elevates attendee security but also provides event organisers with clear and precise information about who is attending. With TicketGuard, you will have a reliable method to identify every attendee, offering an extra layer of security and enabling a more controlled and safe event environment. This clarity and enhanced safety protocol allow both attendees and organisers to focus on the event itself without concerns over security threats or unverified entries.`,
  },
];

export const TicketGuard2 = [
  {
    heading: `Do I need additional Hardware for a TicketGuarded event?`,
    label: `No, everything is built into our apps, which you can use on mobile phones, tablets, or handheld Android scanners.`,
  },
];

export const Billing1 = [
  {
    heading: `How are the fees charged?`,
    label: `All fees are charged to your billing card.`,
  },
  {
    heading: `Can I change my billing card?`,
    label: `You can update your payment method at any time by going to your Box Office account, selecting 'Settings' from the left-hand menu, then 'Billing', where you can manage your payment methods.`,
  },
  {
    heading: `When are payments collected?`,
    label: `Payments are collected weekly or when you reach a £500 threshold, whichever comes sooner.`,
  },
];

export const Billing2 = [
  {
    heading: `My payment failed, what happens now?`,
    label: `If a payment for event organiser fees via Twotixx fails, the following steps will occur:

1. Notification: Twotixx will notify you through email or your account dashboard that the payment was unsuccessful. This notification may include the reason for the failure, such as credit card issues or bank problems.

2. Pause on Ticket Sales: Your ability to sell tickets may be paused if the payment issue is not resolved within 24 hours of the notification.

3. Account Review: You will be prompted to review and update the payment methods associated with your account.

4. Payment Retry: Twotixx might automatically retry processing the payment after a short period.

5. Resolution Requirement: To resume ticket sales and clear any holds, you must resolve the payment issue by updating your payment information or ensuring funds are available.

6. Debt Accumulation: If the payment issue persists without resolution, your account may accumulate a debt, which could impact your future event postings and ticket sales on Twotixx.

It is crucial to address any payment failures promptly to avoid interruptions in your ticket sales and additional complications with your Twotixx account.`,
  },
  {
    heading: `I’ve refunded a Ticket, are platform fees refunded too?`,
    label: `Cancelling a ticket will not refund any Twotixx fees to your box office balance.`,
  },
];

export const VenueApp1 = [
  {
    heading: `What devices can the Venue App run on?`,
    label: `The Twotixx venue app is compatible with iOS and Android mobile devices, tablets, and handheld digital scanners.`,
  },
  {
    heading: `Where can I download the Venue App from?`,
    label: `The Twotixx venue app can be downloaded from the Apple Store and Google play store.`,
  },
  {
    heading: `How do I login to the Venue App?`,
    label: `To log in to the Venue App, use the credentials you set up in Box Office. If you need to review, edit, or create new user details, visit your event overview, click on the Venue tab, and click to edit the Entrances and Scanning Points section.`,
  },
  {
    heading: `Can I add additional event staff users to the Venue App for my event?`,
    label: `Currently, the Venue App supports a single parent login; however, you can create separate scanning points for individual staff members.`,
  },
  {
    heading: `How do I scan Tickets?`,
    label: `Scanning tickets is straightforward: once logged into the Venue App, select your event, click on the scanner icon to activate the camera, and hover it over the QR code on the attendee's device to scan the ticket. 

If using a handheld Android scanner, you can use either the top laser scanner or the rear camera.`,
  },
];

export const VenueApp2 = [
  {
    heading: `Can I manually check-in an attendee without scanning their ticket?`,
    label: `Yes, you can search for an attendee by name or email in the app. Once located, you can select and manually update their status to check them into the event.`,
  },
  {
    heading: `How can I search for attendees in the Venue App?`,
    label: `You can search for an attendee by their name or email to check or edit their status and view their booking details.`,
  },
  {
    heading: `Can I create and manage my event in the Venue App?`,
    label: `Currently, the Venue App only supports the ticket scanning process for entry. Future releases will include features to create and manage your event directly within the app.`,
  },
  {
    heading: `Can I sell Tickets on the door through the Venue app?`,
    label: `Currently, the only method for selling tickets at the door is to direct buyers to purchase them through the website. Once purchased, the tickets will be delivered to the Twotixx app for scanning, alternatively you can search the buyer details in the Venue App once tickets have been purchased to manually check them in.`,
  },
];