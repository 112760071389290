import React, { useEffect } from "react";
import "./Banner.css";
import Text from "../Text";
import { ReactComponent as SuccessFilled } from "../../../assets/svgs/SuccessFilled.svg";
import { ReactComponent as Close } from "../../../assets/svgs/CloseFilled.svg";
import { ReactComponent as InfoFilled } from "../../../assets/svgs/InfoFilled.svg";
import { ReactComponent as WarningFilled } from "../../../assets/svgs/WarningFilled.svg";
import { useDispatch } from "react-redux";
import { BANNER_CONTENT } from "../../../actions";

export default function Banner({
  text,
  headingText,
  showBanner,
  type,
  withIcon,
  withClose,
  onClose,
  width,
  position,
  style,
  parentContainerStyle,
  paddingLeft
}) {
  // constants and values
  const dispatch = useDispatch();

  useEffect(() => {
    if (showBanner === true && type !== "Error") {
      setTimeout(() => {
        crossPress();
      }, 5000);
    }
    return () => {
      clearTimeout();
    };
  }, [showBanner]);

  const crossPress = () => {
    onClose && onClose();
    dispatch({
      type: BANNER_CONTENT,
      payload: {
        showBanner: false,
        text,
        headingText,
        type,
        withIcon,
        withClose,
        width,
        position,
      },
    });
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div
      style={{
        width: width ? width : "93%",
        bottom: position === "bottom" ? 120 : null,
        top: position === "top" ? 17 : null,
        paddingLeft: paddingLeft ? paddingLeft : '364px',
        ...parentContainerStyle
      }}
      className="top-banner-container1 banner-outside-container"
    >
      <div
        className="top-banner-modal"
        style={{
          backgroundColor:
            type === "Accent"
              ? getComputedStyle(document.documentElement).getPropertyValue(
                  "--blue"
                )
              : type === "Success"
              ? getComputedStyle(document.documentElement).getPropertyValue(
                  "--green"
                )
              : type === "Warning"
              ? getComputedStyle(document.documentElement).getPropertyValue(
                  "--yellow"
                )
              : type === "Error"
              ? getComputedStyle(document.documentElement).getPropertyValue(
                  "--red"
                )
              : getComputedStyle(document.documentElement).getPropertyValue(
                  "--green"
                ),
          ...style,
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", flex: 1 }}>
          {withIcon && (
            <div style={{ alignSelf: "center", paddingRight: 16 }}>
              {withIcon && (type === "Accent" || type === "Error") && (
                <InfoFilled
                  height={16}
                  width={16}
                  fill={
                    type === "Warning"
                      ? getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--black")
                      : getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--white")
                  }
                />
              )}
              {withIcon && type === "Success" && (
                <SuccessFilled
                  height={16}
                  width={16}
                  fill={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--white")}
                />
              )}
              {withIcon && type === "Warning" && (
                <WarningFilled
                  height={16}
                  width={16}
                  fill={getComputedStyle(
                    document.documentElement
                  ).getPropertyValue("--black")}
                />
              )}
            </div>
          )}
          <div
            style={{ width: "90%", display: "flex", flexDirection: "column" }}
          >
            {headingText && (
              <Text textWrap={'noWrap'}
                style={{
                  color:
                    type === "Warning"
                      ? getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--midnightBlue")
                      : getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--white"),
                }}
                variant={"m200"}
                marginBottom={8}
              >
                {headingText}
              </Text>
            )}
            <Text
              style={{
                color:
                  type === "Warning"
                    ? getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--midnightBlue")
                    : getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--white"),
                width: 800,
              }}
              variant={"r200"}
            >
              {text}
            </Text>
          </div>
        </div>
        {withClose && (
          <div style={{ alignSelf: "center" }} onClick={() => crossPress()}>
            <Close
              height={16}
              width={16}
              fill={
                type === "Warning"
                  ? getComputedStyle(document.documentElement).getPropertyValue(
                      "--black"
                    )
                  : getComputedStyle(document.documentElement).getPropertyValue(
                      "--white"
                    )
              }
            />
          </div>
        )}
      </div>
    </div>
  );
}
