import React from "react";
import "./CentralModal.css";
import Text from "../Text";
import HoverBGButton from "../Buttons/HoverBGButton/HoverBGButton";
import ButtonPrimary from "../Buttons/ButtonPrimary/ButtonPrimary";

export default function CentralModalSuccessV2({
  icon,
  title,
  description,
  show,
  backgroundHoverBtn,
  shouldShowButtons,
  onButton1Clicked,
  onButton2Clicked,
  button1Title,
  button2Title,
}) {
  if (!show) return null;

  return (
    <div style={{background: 'rgba(13, 34, 68, 0.9)'}} className="central-modal-overlay">
      <div style={{ width: 692 }} className="central-modal">
        <div style={{alignItems: 'center'}} className="central-modal-content">
          {icon && (
            <HoverBGButton
              backgroundHover={
                backgroundHoverBtn ? backgroundHoverBtn : "var(--hoverGreen)"
              }
              icon={icon}
              size={48}
            />
          )}
          {title ? (
            <Text marginTop={24} variant="b500">
              {title}
            </Text>
          ) : null}
          <Text textAlign={'center'} style={{maxWidth: '472px'}} marginTop={16} marginBottom={16} variant={"r300"}>
            {description}
          </Text>
          {shouldShowButtons && (
            <div
              className="central-bottom-button-container"
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 24,
                marginBottom: 8,
                width: "100%",
              }}
            >
              <ButtonPrimary
                label={button1Title}
                onPress={() => onButton1Clicked()}
              />

              <ButtonPrimary
                isSecondary={true}
                label={button2Title}
                onPress={() => onButton2Clicked()}
                style={{ marginLeft: 24 }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
