import { LANGUAGE_LOCALE } from '../actions';

export default function languageLocale(state = null, action = {}) {
  switch (action.type) {
    case LANGUAGE_LOCALE: {
      return {
        ...state,
        languageLocale: action.payload,
      };
    }

    default:
      return state;
  }
}
